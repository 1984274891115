import React, { useState } from 'react';
import { Col, Grid, Row } from 'components/FlexBox/FlexBox';
import { useQuery, gql } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import CustomDataTable from "../../components/DataTable/CustomDataTable";

const LOW_STOCK_QUERY = gql`
query($query:QueryParams!){
    stockQuery{
      lowStocks(query:$query){
        productId
        productName
        productCode
        threshold
        measureUnit
        quantity
      }
    }
  }`;

export default function LowStockReport() {
    const [lowStockData, setLowStockData] = useState([]);

    const { loading } = useQuery(LOW_STOCK_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            "query": {

            }
        },
        onCompleted: (data: any) => {
            let items = data && data.stockQuery && data.stockQuery.lowStocks;
            setLowStockData(items);
        },
        onError: (e) => {
            console.log("Low stock get failed!", e.message);
        }
    });

    const columns = React.useMemo(
        () => [
            {
                Header: 'Product Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Threshold',
                accessor: 'threshold',
            },
            {
                Header: 'Current Quantity',
                accessor: 'qty',
            },
            {
                Header: 'Measure Unit',
                accessor: 'measureUnit',
            }
        ],

        []
    );

    const table = React.useMemo(
        () => {
            let products = []
            if (lowStockData.length > 0) {
                products = lowStockData.map(p => ({
                    name: p.productName,
                    threshold: p.threshold,
                    qty: p.quantity,
                    measureUnit: p.measureUnit
                }));
            }
            return <CustomDataTable columns={columns} data={products} loading={loading} title={"Low Stock Products"} canPrint={true}/>
        },
        [lowStockData]
    );


    return (
        <Grid fluid={true}>
            <Row>
                <Col md={12}>
                    {/* <Header
                        style={{
                            marginBottom: 40,
                            boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                        }}
                    >
                        <Col md={4} xs={12}>
                            <Heading>Low Stock Products</Heading>
                        </Col>
                    </Header> */}

                    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                        {table}
                    </Wrapper>
                </Col>
            </Row>
        </Grid>
    );
}
