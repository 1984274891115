import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from 'components/FlexBox/FlexBox';
import { useDrawerDispatch } from 'context/DrawerContext';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { Plus } from 'assets/icons/PlusMinus';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import CustomDataTable from "../../../components/DataTable/CustomDataTable";
import { Col, Row } from "../../Sales/SalesInvoice.style"
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { useHistory } from 'react-router-dom';
import {  ADD_ASSET, QUOTATION_ADD } from "../../../settings/constants";
import { ImPrinter } from 'react-icons/im';
import DrawerBox from "../../../components/DrawerBox/DrawerBox";


export default function AssetManagement() {
    const dispatch = useDrawerDispatch();
    const history = useHistory();
    const enqueue = useCustomSnackBar();

    const [assetData, setAssetData] = useState([{name:"asset1"}, {name:"asset2"}])


    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',// accessor is the "key" in the data
            },
            {
                Header: 'Purchase Date',
                accessor: 'purchaseDate',
            },
            {
                Header: 'Purchase From',
                accessor: 'purchaseFrom',
            },
            {
                Header: 'Buying Price (৳)',
                accessor: 'buyingPrice',
            },
            {
                Header: 'Current Price (৳)',
                accessor: 'currentPrice',
            },
            {
                Header: 'Note',
                accessor: 'note',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                disableSortBy: true,
                disableFilters: true
            }
        ],
        []
    );


    const table = React.useMemo(
        () => {
            let salesData = []
            // if (assetData && assetData.salesQuery && assetData.salesQuery.salesInvoices && assetData.salesQuery.salesInvoices.items) {
                salesData = assetData && assetData.map(s => {
                    return {
                        name: s.name,
                        purchaseDate: "",
                        purchaseFrom: "",
                        buyingPrice: "",
                        currentPrice: "",
                        note: "",
                        actions: <div style={{ fontSize: "large", justifyContent: 'center' }}>
                            <ImPrinter style={{ marginLeft: 8 }} title="print" onClick={(e) => console.log(e)} />
                        </div>

                    }
                });
            // }
            return <CustomDataTable columns={columns} data={salesData} loading={false} title={"Assets"} />
        },
        [assetData]
    );

    return (
        <Grid fluid={true}>
            <DrawerBox>
                <Row>
                    <Col md={12}>
                        <Header
                            style={{
                                marginBottom: 40,
                                boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                            }}
                        >
                            <Col md={4} xs={12}>
                                {/* <Heading>Assets</Heading> */}
                            </Col>

                            <Col md={8} xs={12}>
                                <Button
                                    onClick={() => history.push(ADD_ASSET)}
                                    startEnhancer={() => <Plus />}
                                    overrides={{
                                        BaseButton: {
                                            style: () => ({
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                float:'right'
                                            }),
                                        },
                                    }}
                                >
                                    Add Asset
                                </Button>
                            </Col>
                        </Header>
                        <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                            {table}
                        </Wrapper>
                    </Col>
                </Row>
            </DrawerBox>
        </Grid>
    );
}
