import React, { useState } from 'react';
import { withStyle } from 'baseui';
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import { SALES_INV_DETAILS } from './SalesInvoiceDetails';
import useCustomSnackBar from "../../hooks/useCustomSnackBar";
import {
    StyledTable,
    StyledHead,
    StyledHeadCell,
    StyledBody,
    StyledRow,
    StyledCell,
} from 'baseui/table';
import { useHistory } from 'react-router-dom';

import Input from 'components/Input/Input';
import { Button, SIZE } from 'baseui/button';
import { useLocation } from 'react-router-dom';
import { SALES_INVOICE } from 'settings/constants';
import { FaListAlt } from 'react-icons/fa';


const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',

        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));

const WARRANTY_MUTATION = gql`
mutation($warrantyDetails: [WarrantyRequestInputType]) {
    productWarranty {
        bulkAddOrUpdateWarranty(warrantyDetails: $warrantyDetails) {
        success
      }
    }
  }
`

export default function SalesInvoiceAdditionalInfo() {
    const [salesItems, setSalesItems] = useState([])
    const [tableData, setTableData] = useState([])
    const loc: any = useLocation()
    const enqueue = useCustomSnackBar();
    let history = useHistory();


    const { loading, refetch, data } = useQuery(SALES_INV_DETAILS,
        {
            variables: {
                query: {
                    "id": loc.state.id
                }
            },
            onCompleted: (dt) => {
                let items = dt && dt.salesQuery && dt.salesQuery.salesInvoice.invoiceItems;
                let formatItems = [];
                var count = 1;

                items && items.map((item: any) => {
                    let singleItem = [];
                    for (let i = 0; i < item.quantity; i++) {
                        let warranty = item.warranties[i]
                        singleItem.push({
                            id: warranty?.id || 0,
                            itemSerial: count++,
                            invoiceItemId: item.id,
                            title: item.product.title,
                            serialId: warranty?.serial1 || '',
                            warrantyMonths: item.product?.warrantyInMonths,
                            code: item.product.code,
                            measureUnit: item.product.measureUnit,
                            price: item.price,
                            productId: item.product?.id
                        })
                    }
                    formatItems.push(singleItem);
                })
                let finalItems = formatItems?.flatMap((item: any) => item);
                setSalesItems(finalItems);
            }
        });


    const [loadMutation, { loading: submitting }] = useMutation(WARRANTY_MUTATION, {
        onCompleted: (data) => {
            if (data && data.productWarranty && data.productWarranty.bulkAddOrUpdateWarranty && data.productWarranty.bulkAddOrUpdateWarranty.success) {
                enqueue("success", "Product Warranty Added Successfully Generated");
                // history.push(SALES_INVOICE)
                refetch()
            } else {
                enqueue("error", "Product Warranty couldn't be added. Please try again later.");
            }
        },
        onError: () => {
            enqueue("error", "Product Warranty couldn't be added. Please try again later.");
        }
    });


    const handleChange = (itemSerial: number, value: any) => {
        let items = [...salesItems];
        let targetItem = items.find((item) => item.itemSerial === itemSerial);
        let targetIndex = items.indexOf(targetItem);
        items[targetIndex].serialId = value;
        setSalesItems(items);
    }

    const handleChangeWarranty = (itemSerial: number, value: any) => {
        let items = [...salesItems];
        let targetItem = items.find((item) => item.itemSerial === itemSerial);
        let targetIndex = items.indexOf(targetItem);
        items[targetIndex].warrantyMonths = value;
        setSalesItems(items);
    }

    React.useMemo(
        () => {
            let invoice = null;
            let sItems = [];

            if (salesItems.length > 0) {
                sItems = salesItems.map(salesItem => ({
                    id: salesItem.id,
                    itemSerial: salesItem.itemSerial,
                    title: salesItem.title,
                    serialId: <div>
                        <Input
                            value={salesItem.serialId}
                            name="serialId"
                            onChange={(e: any) => handleChange(salesItem.itemSerial, e.target.value)}
                            size={SIZE.mini}
                        />
                    </div>,
                    warrantyMonths: <div>
                        <Input
                            value={salesItem.warrantyMonths}
                            name="warrentyId"
                            onChange={(e: any) => handleChangeWarranty(salesItem.itemSerial, e.target.value)}
                            size={SIZE.mini}
                            disabled={true}
                        />
                    </div>,
                    code: salesItem.code,
                    measureUnit: salesItem.measureUnit,
                    price: salesItem.price
                }));

                invoice = data.salesQuery.salesInvoice;
            }

            setTableData(sItems)
        },
        [salesItems]
    );

    const handleSubmit = () => {
        let mutateData = salesItems.map(a => {
            if (a.id === 0 && a.serialId === "") {
                return null;
            }
            else {
                return ({
                    productId: a.productId,
                    invoiceItemId: a.invoiceItemId,
                    warrantyInMonths: a.warrantyMonths,
                    serial1: a.serialId,
                    id: a.id
                })
            }
        }).filter(a => a !== null)


        loadMutation({
            variables: {
                "warrantyDetails": mutateData
            }
        })
    }

    return (
        <Grid fluid={true}>
            <Row>
                <Col md={12}>
                    <Header
                        style={{
                            marginBottom: 40,
                            boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                        }}
                    >
                        <Col md={4} xs={12}>
                            <Heading>Sales Invoice Additional Info</Heading>
                        </Col>
                        <Col md={6} xs={4}>

                        </Col>
                        <Col md={2} xs={12}>
                            <Button
                                type="button"
                                onClick={() => handleSubmit()}
                                isLoading={submitting}
                                disabled={submitting}
                                size={SIZE.default}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            width: '100%',
                                            borderTopLeftRadius: '2px',
                                            borderTopRightRadius: '2px',
                                            borderBottomRightRadius: '2px',
                                            borderBottomLeftRadius: '2px',
                                        }),
                                    },
                                }}
                            >
                                Save
                            </Button>
                        </Col>
                    </Header>
                    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                        <StyledTable>
                            <StyledHead $width="1550px">
                                <StyledHeadCell>Title</StyledHeadCell>
                                <StyledHeadCell>Serial Number</StyledHeadCell>
                                <StyledHeadCell>Warrenty (Months)</StyledHeadCell>
                            </StyledHead>
                        </StyledTable>

                        <StyledBody $width="1550px">
                            {
                                tableData.map((row, index) => (
                                    <StyledRow key={index}>
                                        <StyledCell>{row.title}</StyledCell>
                                        <StyledCell>{row.serialId}</StyledCell>
                                        <StyledCell>{row.warrantyMonths}</StyledCell>
                                    </StyledRow>
                                ))}
                        </StyledBody>
                    </Wrapper>
                </Col>
            </Row>
        </Grid>
    );
}
