// dependencies
import React, { useEffect, useState } from 'react';
import Input from 'components/Input/Input';
import { Wrapper } from 'components/Wrapper.style';
import CustomDataTable from "../../../components/DataTable/CustomDataTable";
import { Grid, Col } from 'components/FlexBox/FlexBox';

const data = [
   {
      productName: 'Item 1',
      quantity: 10,
      unitPrice: 50,
      delivered: 5,
      returned: 2,
   },
   {
      productName: 'Item 2',
      quantity: 20,
      unitPrice: 60,
      delivered: 6,
      returned: 3,
   },
   {
      productName: 'Item 3',
      quantity: 40,
      unitPrice: 70,
      delivered: 30,
      returned: 20,
   }
]


const ViewInvoiceClosing = () => {
   const [table, setTable] = React.useState([]);
   const [listSubTotal, setListSubTotal] = useState(0)
   const [invoiceClosingData, setInvoiceClosingData] = useState<any>(data);
   const [deliveryCost, setDeliveryCost] = useState(0)
   const [cod, setCod] = useState(0)
   const [bottomSubTotal, setBottomSubTotal] = useState(0)
   const [total, setTotal] = useState(0)

   const columns = React.useMemo(
      () => [
         {
            Header: 'Product',
            accessor: 'productName',
            Filter: false,
         },
         {
            Header: 'Quantity',
            accessor: 'quantity',
            Filter: false

         },
         {
            Header: 'Unit Price',
            accessor: 'unitPrice',
            Filter: false

         },
         {
            Header: 'Delivered',
            accessor: 'delivered',
            Filter: false

         },
         {
            Header: 'Returned',
            accessor: 'returned',
            Filter: false
         },
         {
            Header: 'Total',
            accessor: 'total',
            Filter: false
         }
      ],
      []
   );

   const handleChangeInput = (e, itemName) => {
      const { name, value } = e.target

      const parsedValue = parseInt(value)

      if (isNaN(parsedValue)) {
         return
      }

      const newInvoiceClosingData = invoiceClosingData.map((item) => {
         if (item.productName === itemName) {
            return {
               ...item,
               [name]: value
            }
         }
         return item
      })

      setInvoiceClosingData(newInvoiceClosingData)
   }

   const calcListSubTotal = () => {
      const subTotal = invoiceClosingData.reduce((acc, curr) => {
         return acc = curr.delivered * curr.unitPrice
      }, 0)

      setListSubTotal(subTotal)
      setTotal(subTotal + bottomSubTotal)
   }

   const quantityErrorHandler = (e, itemName) => {
      const { name, value } = e.target

      const parsedValue = parseInt(value)

      if (isNaN(parsedValue)) {
         return
      }

      const newInvoiceClosingData = invoiceClosingData.map((item) => {
         if (item.productName === itemName) {
            const qtySum = name === 'delivered' ? parsedValue + item.returned : parsedValue + item.delivered

            return {
               ...item,
               [name]: parsedValue,
               error: {
                  name,
                  message: qtySum !== item.quantity ? 'Delivered and Returned amount should match the quantity' : ''
               }
            }
         }
         return item
      })

      setInvoiceClosingData(newInvoiceClosingData)
   }

   useEffect(() => {
      let StocksList = []
      if (invoiceClosingData) {
         StocksList = invoiceClosingData.map((item) => {
            return {
               ...item,
               total: item.delivered * item.unitPrice,
               delivered: <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input error={item?.error?.message ? true : false} name="delivered" onChange={(e) =>
                     quantityErrorHandler(e, item.productName)
                  } defaultValue={item.delivered} />
                  {
                     item?.error?.name === 'delivered' && item?.error?.message &&
                     <span style={{ color: 'red', fontSize: '12px' }}>
                        {item.error.message}
                     </span>
                  }
               </div>,
               returned: <div>
                  <Input error={item?.error?.message ? true : false} name="returned" onChange={(e) =>
                     quantityErrorHandler(e, item.productName)
                  } defaultValue={item.returned} />
                  {
                     item?.error?.name === 'returned' && item?.error?.message && <span style={{ color: 'red', fontSize: '12px' }}>
                        {item.error.message}
                     </span>
                  }
               </div>,
               error: {
                  name: '',
                  message: ''
               }
            }
         })
      }
      StocksList = [<CustomDataTable showPagination={false} columns={columns} data={StocksList} loading={false} title={"Invoice Closing List"} />]
      setTable(StocksList)
   }, [invoiceClosingData])

   useEffect(() => {
      calcListSubTotal()
   }, [invoiceClosingData])

   useEffect(() => {
      const parsedDeliveryCost = parseInt(deliveryCost as any)
      const parsedCod = parseInt(cod as any)

      setBottomSubTotal(parsedCod + parsedDeliveryCost)
      setTotal(parsedCod + parsedDeliveryCost + listSubTotal)
   }, [deliveryCost, cod])

   return (
      <Grid fluid={true}>
         <Col md={12}>
            <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', paddingTop: 30 }}>
               {table && table}
            </Wrapper>
            <div style={{ textAlign: "right", padding: '10px 20px' }}>
               <h3>Sub Total: {listSubTotal && listSubTotal}</h3>
            </div>
            <div style={{ width: '20%', background: '#fff', padding: '10px 20px' }}>
               <p style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ flex: 2 }}>Delivery Cost</span>
                  <span style={{ flex: 0.2 }}>:</span>
                  <span style={{ flex: 4 }}>
                     <Input onChange={(e) => setDeliveryCost(e.target.value)} />
                  </span>
               </p>

               <p style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ flex: 2 }}>COD</span>
                  <span style={{ flex: 0.2 }}>:</span>
                  <span style={{ flex: 4 }}>
                     <Input onChange={(e) => setCod(e.target.value)} />
                  </span>
               </p>
               <div style={{ borderBottom: '1px solid #000' }}></div>
               <p style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ flex: 2 }}>Sub Total</span>
                  <span style={{ flex: 0.2 }}>:</span>
                  <span style={{ flex: 4 }}>
                     <Input disabled value={bottomSubTotal && bottomSubTotal} />
                  </span>
               </p>

               <p style={{ display: 'flex', alignItems: 'center' }}>
                  <h3 style={{ flex: 2 }}>Total</h3>
                  <span style={{ flex: 0.2 }}>:</span>
                  <span style={{ flex: 4 }}>
                     <Input disabled value={total && total} />
                  </span>
               </p>
            </div>
         </Col>
      </Grid>
   );
};

export default ViewInvoiceClosing;