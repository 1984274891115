import React, { useState } from "react";
import { Card } from "../../components/Widgets/StickerCard/StickerCard.style";
import { FormLabel } from "../../components/FormFields/FormFields";
import { DatePicker } from "baseui/datepicker";
import Button from "../../components/Button/Button";
import { Select } from "baseui/select";
import { Grid, Row, Col } from 'components/FlexBox/FlexBox';
import { AddDays } from "../../settings/common";
import { gql, useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { ACCOUNTING_DETAILS } from "../../settings/constants";
import CustomDataTable from "../../components/DataTable/CustomDataTable";
import { Wrapper } from "../../components/Wrapper.style";

const today = new Date();

const QUERY = gql`query($query: QueryParams!){
    reportQuery{
      productionAndSalesReport(query: $query){
        productId
        productCode
        productTitle
        productCategory
        categoryId
        salesPrice
        saleQty
        stockQty
        productionQty
        companyProfit
      }
    }
  }`;

export default function ClientSalesReport() {
    const [period, setPeriod] = useState({ toDate: today, fromDate: AddDays(today, -7) })

    const [loadReport, { loading, data }] = useLazyQuery(QUERY, {
        fetchPolicy: "network-only"
    });

    const columns = React.useMemo(
        () => [
            {
                Header: 'Product',
                accessor: 'product'
            },
            {
                Header: 'Code',
                accessor: 'code',

            },
            {
                Header: 'Product Category',
                accessor: 'category'
            },
            {
                Header: 'Sales Price',
                accessor: 'salesPrice'
            },
            {
                Header: 'Sales Qty',
                accessor: 'saleQty'
            },
            {
                Header: 'StockQty',
                accessor: 'stockQty'
            },
            {
                Header: 'ProductionQtyt',
                accessor: 'productionQty'
            },
            {
                Header: 'CompanyProfit',
                accessor: 'companyProfit'
            }
            // {
            //     Header: 'Actions',
            //     accessor: 'actions',
            //     disableSortBy: true,
            //     disableFilters: true
            // }
        ],
        []
    );
    const table = React.useMemo(
        () => {
            let invData = []
            if (data && data.reportQuery && data.reportQuery.productionAndSalesReport) {
                invData = data.reportQuery.productionAndSalesReport.map(p => ({
                    product: p.productTitle,
                    code: p.productCode,
                    category: p.productCategory,
                    salesPrice: p.salesPrice,
                    saleQty: p.saleQty,
                    stockQty: p.stockQty,
                    productionQty: p.productionQty,
                    companyProfit: p.companyProfit,
                    //accessor: ""
                }));
            }
            return <CustomDataTable columns={columns} data={invData} loading={loading} title={"Client Sales Report"} canPrint={true}/>
        },
        [data]
    );

    function generateReport() {
        loadReport({
            variables: {
                query: {
                    params: [
                        {
                            key: "stockReport", "value": "false"
                        },
                        {
                            key: "salesReport", "value": "true"
                        },
                        {
                            key: "productionReport", "value": "false"
                        },
                        {
                            key: "startDate",
                            value: JSON.stringify(period.fromDate)
                        },

                        { 
                            key: "endDate", 
                            value: JSON.stringify(period.toDate)
                        }
                    ]
                }
            }
        });
    }

    // function generateReport() {
    //     loadReport({
    //         variables: {
    //             query: {
    //                 params: [
    //                     {
    //                         key: "fromDate",
    //                         value: JSON.stringify(period.fromDate)
    //                     },
    //                     {
    //                         key: "toDate",
    //                         value: JSON.stringify(period.toDate)
    //                     }
    //                 ]
    //             }
    //         }
    //     });
    // }

    console.log("FromData", period.fromDate);
    console.log("ToData", period.toDate)

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <Row>
                            <Col md={3}>
                                <FormLabel>From</FormLabel>
                                <DatePicker
                                    mask={null}
                                    formatString="dd-MMM-yyy"
                                    value={[period.fromDate]}
                                    onChange={({ date }) =>
                                        setPeriod({ ...period, fromDate: Array.isArray(date) ? date[0] : date })
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <FormLabel>To</FormLabel>
                                <DatePicker
                                    mask={null}
                                    formatString="dd-MMM-yyy"
                                    value={[period.toDate]}
                                    onChange={({ date }) =>
                                        setPeriod({ ...period, toDate: Array.isArray(date) ? date[0] : date })
                                    }
                                />
                            </Col>
                            <Col md={1} style={{ paddingTop: 19 }}>
                                <Button
                                    type="button"
                                    isLoading={loading}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }),
                                        },
                                    }}
                                    onClick={() => {
                                        generateReport();
                                    }}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                        {table}
                    </Wrapper>
                </Col>
            </Row>
        </>
    )
}