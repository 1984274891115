import { styled, withStyle } from 'baseui';
import {
    StyledBody as BaseStyledBody, StyledCell as BaseStyledCell,
    StyledHead as BaseStyledHead,
    StyledHeadCell as BaseStyledHeadCell
} from "baseui/table";

export const TableWrapper = styled('div', () => ({
    width: '100%',
    //height: '510px',
    padding: '0 25px 25px',
}));

export const StyledHead = withStyle(BaseStyledHead, () => ({
    width: '100%',
    '@media only screen and (max-width: 767px)': {
        width: '1000px',
    },
}));

export const StyledBody = withStyle(BaseStyledBody, () => ({
    width: '100%',

    '@media only screen and (max-width: 767px)': {
        width: '1000px',
    },
}));

export const StyledHeadCell = withStyle(BaseStyledHeadCell, () => ({
    fontFamily: "'Lato', sans-serif",
    fontWeight: 700,
    color: '#161F6A !important',
}));

export const SmallHeadCell = withStyle(StyledHeadCell, () => ({
    //maxWidth: '70px',
}));

export const StyledCell = withStyle(BaseStyledCell, () => ({
    fontFamily: "'Lato', sans-serif",
    fontWeight: 400,
    color: '#161F6A !important',
}));

export const SmallCell = withStyle(StyledCell, () => ({
    //maxWidth: '70px',
}));
