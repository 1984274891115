export const AuditType = {
    Inventory : 'INVENTORY',
    // Financial : 'FINANCIAL'
  }
  
  export const AuditStatus = {
    Started : 'STARTED',
    Running : 'RUNNING',
    Hold : 'HOLD',
    Completed : 'COMPLETED',
    PartiallyCompleted : 'PARTIALLY_COMPLETED',
    Canceled : 'CANCELED',
  }

  