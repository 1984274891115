import SettingsCard from 'components/SettingsCard/SettingsCard';
import { withStyle } from 'baseui';
import { Grid, Row, Col as Column } from 'components/FlexBox/FlexBox';
import { useHistory } from 'react-router-dom';
import { FaListAlt } from 'react-icons/fa';
import { ADD_BRANCH } from 'settings/constants';
import isAuthenticated from 'settings/isAuthenticated';


const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',

        ':last-child': {
            marginBottom: 0,
        },
    },
}));

export default function Branch() {
    let history = useHistory();

    return (
        <Grid fluid={true}>
            <Row>
                {
                    isAuthenticated(ADD_BRANCH) &&
                    <Col md={4}>
                        <SettingsCard
                            icon={<FaListAlt size={65} />}
                            title="Branch"
                            subtitle="Add your Branch from here"
                            onClick={() => history.push(ADD_BRANCH)}
                        />
                    </Col>
                }
            </Row>
        </Grid>
    );
}
