import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars';
import Input from 'components/Input/Input';
//import Button, {KIND} from 'components/Button/Button';
import { Button, SHAPE, SIZE } from 'baseui/button';
import DrawerBox from 'components/DrawerBox/DrawerBox';
import { Row, Col } from 'components/FlexBox/FlexBox';
import { Form } from '../../DrawerItems/DrawerItems.style';
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { Select } from "baseui/select";
import { CURRENCY, VIEW_UPCOMING_STOCKS } from "../../../settings/constants";
import { CgClose } from "react-icons/cg";
import { Heading } from "../../../components/Wrapper.style";
import { useHistory } from 'react-router-dom';
// import MultipleProductSelectModal from "./MultipleProductSelectModal";
import Checkbox from 'components/CheckBox/CheckBox';
import MultipleProductSelectModal from '../Purchase/MultipleProductSelectModal';
import { GetProductVariationId } from "../../../utils/inventory"

type Props = any;
const PRODUCT_QUERY = gql`query($query: QueryParams!){
    productQuery{
        products(query: $query){
            items{
                id
                title
                code
                measureUnit
            }
        }
    }
}`;

const PRODUCT_QUERY_WITH_VARIATION = gql`
query($query: QueryParams!) {
  productQuery {
    pocketXProducts(query: $query) {
      items {
        id
        title
        code
        measureUnit
        productVariations {
          productId
          variationId
          variationValueId
          permutationId
        }
        variations {
          id
          name
          variationValues {
            id
            variationId
            value
          }
        }
      }
    }
  }
}`

const MUTATION = gql`mutation($upcomingStocks: PurchaseInvoiceRequestDTO!){
    purchase{
      addUpcomingStocks(upcomingStocks : $upcomingStocks){
        success
      }
    }
  }`;


const AddUpcomingStocks: React.FC<Props> = () => {
    const { register, handleSubmit, getValues, setValue } = useForm();
    const enqueue = useCustomSnackBar();
    const history = useHistory();

    const [productOptions, setProductOptions] = useState([]);
    const [purchaseItems, setPurchaseItems] = useState([]);
    const [isOpenMultipleModal, setIsOpenMultipleModal] = useState(false);
    const [product, setProduct] = useState({ label: "", value: 0 });
    const [isMultiple, setIsMultiple] = useState(false);
    const [count, setCount] = useState(0);

    const [loadMutation, { loading: submitting }] = useMutation(MUTATION, {
        onCompleted: (data) => {
            if (data && data.purchase && data.purchase.addUpcomingStocks && data.purchase.addUpcomingStocks.success) {
                enqueue("success", "Upcoming Stocks Successfully Added");
                history.push(VIEW_UPCOMING_STOCKS);
            } else {
                enqueue("error", "Upcoming Stocks couldn't be added. Please try again later.");
            }
        },
        onError: (e) => {
            console.log(e.message)
            enqueue("error", "Upcoming Stocks couldn't be added. Please try again later.");
        }
    });


    // const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY, {
    //     onCompleted: (data) => {
    //         if (data && data.productQuery && data.productQuery.products && data.productQuery.products.items) {
    //             const opts = data.productQuery.products && data.productQuery.products.items.map(a => ({
    //                 label: a.title + (a.code ? ` (${a.code})` : ""),
    //                 value: a.id
    //             }))
    //             if (opts.length > 0) setProductOptions(opts)
    //         }
    //     }
    // });

    const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY_WITH_VARIATION, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (data?.productQuery?.pocketXProducts?.items) {
                const opts = data?.productQuery?.pocketXProducts?.items.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))
                if (opts.length > 0) setProductOptions(opts)
            }
        }
    });


    function handleProductAdd(value) {
        const targetProduct = value[0];
        if (!targetProduct) {
            setProduct({ label: "", value: 0 })
            return;
        }
        else {
            setProduct(targetProduct);
        }

        let pItems = products && products.productQuery && products.productQuery.pocketXProducts && products.productQuery.pocketXProducts.items || [];

        const measureUnit = pItems.find(a => a.id === targetProduct.value).measureUnit || "";
        const productVariations = pItems.find(a => a.id === targetProduct.value).productVariations || [];
        const variations = pItems.find(a => a.id === targetProduct.value).variations || [];

        const currentCount = count + 1
        setCount(currentCount);

        let items = [...purchaseItems];
        let item = {
            sl: currentCount,
            productId: targetProduct.value,
            name: targetProduct.label,
            measureUnit: measureUnit,
            productVariations: productVariations,
            variations: variations,
            selectedVariations: [],
            permutationId: 0
        }
        // items.unshift(item);
        items.push(item);

        setPurchaseItems(items);
    }

    function handleMultipleProductAdd(items = []) {
        let resultItems = []
        items.forEach(a => {
            const product = a;
            if (!purchaseItems.find(a => a.productId === product.value)) {
                const measureUnit = products && products.productQuery && products.productQuery.products && products.productQuery.products.items ?
                    products.productQuery.products.items.find(a => a.id === product.value).measureUnit : "";
                resultItems.push({
                    productId: product.value,
                    name: product.label,
                    measureUnit: measureUnit
                })
            }
        })

        let targetItems = [...purchaseItems];
        targetItems.unshift(...resultItems);

        setPurchaseItems(targetItems)
    }

    function handleRemoveItem(sl) {
        let pItems = [...purchaseItems];
        const items = pItems.filter((item) => item.sl !== sl);
        setPurchaseItems(items);
    }

    function handleAutoComplete(e) {
        const val = e.target.value.toLowerCase();
        if (val.length < 2) return;
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 100,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val
                }
            }
        });
    }

    function getTotalPrice() {
        const items = getValues().purchaseItems || [];
        return items.map(a => {
            const item = a;
            return (parseFloat(item ? item.costPrice || 0 : 0)) * (parseFloat(item ? item.quantity || 0 : 0));
        }).reduce((c, d) => c + d, 0)
    }

    const onSubmit = (data) => {
        if (!isValid()) {
            alert("Please add purchase items");
            return;
        }

        let inv = {
            discount: parseFloat(data.totalDiscount) || 0,
            total: getTotalPrice(),
            invoiceItems: purchaseItems.map((a, i) => ({
                price: parseFloat(data.purchaseItems[i + 1].costPrice),
                quantity: parseFloat(data.purchaseItems[i + 1].quantity),
                productId: a.productId,
                permutationId: a.permutationId === 0 ? null : a.permutationId,
                productVariations: a.selectedVariations.map((s, si) => {
                    return {
                        permutationId: s.permutationId === 0 ? null :s.permutationId,
                        costPrice: parseFloat(data.purchaseItems[i + 1].costPrice),
                        quantity: parseFloat(data.purchaseItems[i + 1].quantity)
                    }
                })

            }))
        }

        loadMutation({
            variables: {
                upcomingStocks: inv
            }
        });
    };



    function adjustprice() {
        const sum = getTotalPrice();
        const discount = parseFloat(getValues("totalDiscount")) || 0;
        setValue("totalPrice", getTotalPrice().toMoney(false));
        setValue("netPrice", (sum - discount).toMoney(false));
    }

    // Reduce total price if invoice item is reduced
    useEffect(() => {
        //setValue("totalPrice", getTotalPrice().toMoney(false));
        adjustprice();
    }, [purchaseItems.length])

    const handleChangeVariation = (sl: number, value: any, variationId: number) => {
        let items = [...purchaseItems]
        let item = items.find(a => a.sl == sl);
        if (!item) return;

        let selectedVariations = item.selectedVariations || [];
        let isExistVariationValue = selectedVariations.some(a => a.variationId == variationId);

        if (isExistVariationValue) {
            selectedVariations = selectedVariations.filter(a => a.variationId != variationId);
        }

        if (value.length > 0) {
            let permutationId = item.productVariations.find(a => a.variationId === variationId && a.variationValueId === value[0].value)?.permutationId || 0;
            selectedVariations.push(
                {
                    variationId: variationId,
                    variationValueId: value[0].value,
                    variationValue: value[0].label,
                    permutationId: permutationId
                })
            //item.permutationId = permutationId
        }
        if(item.selectedVariations.length === item.variations.length){
            let vId = GetProductVariationId(item.productVariations, item.selectedVariations)
            item.permutationId = vId;
            selectedVariations = selectedVariations.map(a => {
                return{
                    ...a,
                    permutationId:vId
                }
            })
        }

        item.selectedVariations = selectedVariations;

        let ind = items.findIndex(a => a.sl == sl);
        items[ind] = item;
        setPurchaseItems(items);
    }

    function isValid() {
        const dataItems = getValues().purchaseItems;

        //let isSelectedSupplier = supplier.length > 0
        let hasInvalidPirce = dataItems.some(a => Number(a.costPrice) <= 0)
        let hasInvalidQty = dataItems.some(a => Number(a.quantity) <= 0)

        let hasVariationErr = purchaseItems.reduce((a, c) => {
            let cn = c.selectedVariations.length === c.variations.length
            if (cn) {
                return a + 0
            } else {
                return a + 1
            }
        }, 0) > 0

        return !hasInvalidPirce && !hasInvalidQty && !hasVariationErr;
    }


    return (
        <Scrollbars
            autoHide
            renderView={(props) => (
                <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
            )}
            renderTrackHorizontal={(props) => (
                <div
                    {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal"
                />
            )}
        >
            <Row>
                <Col md={4} xs={12}>
                    <Heading> Add Upcoming Product</Heading>
                </Col>
            </Row>

            <Form style={{ height: '100%' }}>
                <DrawerBox>
                    <Row>
                        <Col md={5}>
                            <FormFields>
                                <FormLabel>Product Name/Code</FormLabel>
                                <Select
                                    options={productOptions || []}
                                    labelKey="label"
                                    valueKey="value"
                                    value={[product]}
                                    searchable={true}
                                    onChange={({ value }) => handleProductAdd(value)}
                                    onInputChange={handleAutoComplete}
                                    size={SIZE.default}
                                    closeOnSelect={isMultiple ? false : true}
                                />
                            </FormFields>
                        </Col>
                        <Col md={1}>
                            <div style={{ marginTop: "20%" }}>
                                <FormFields>
                                    <FormLabel>Is Multiple?</FormLabel>
                                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                        <Checkbox
                                            checked={isMultiple}
                                            onChange={(e: any) => setIsMultiple(e.target.checked)}
                                        />
                                    </div>
                                </FormFields>
                            </div>
                        </Col>

                    </Row>
                    {purchaseItems.length > 0 &&
                        <>
                            <Row style={{ justifyContent: 'center' }}>
                                <h1 style={{ marginTop: 20, color: '#161F6A' }}>Upcoming Items</h1>
                            </Row>
                            <Row style={{ marginBottom: 0 }}>
                                <Col md={2}><b>Product Name</b></Col>
                                <Col md={1}><b>Measure Unit</b></Col>
                                <Col md={2}><b>Variation</b></Col>
                                <Col md={2}><b>Quantity</b></Col>
                                <Col md={2}><b>Unit Cost Price({CURRENCY})</b></Col>
                                <Col md={3}>Total({CURRENCY})</Col>
                            </Row>
                            <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                            {purchaseItems.map((item, i) =>
                                <Row key={item.productId}>
                                    <Col md={2} style={{ alignSelf: 'center' }}>{item.name}</Col>
                                    <Col md={1} style={{ alignSelf: 'center' }}>{item.measureUnit}</Col>
                                    {
                                        item.variations.length > 0 ?
                                            item.variations.map((a) => {
                                                return (
                                                    <Col md={1} style={{ alignSelf: 'center' }}>
                                                        <Select
                                                            options={a.variationValues.map(v => { return { label: v.value, value: v.id } }) || []}
                                                            placeholder={`${a.name}`}
                                                            labelKey="label"
                                                            valueKey="value"
                                                            value={item.selectedVariations.filter(s => s.variationId === a.id).map(v => { return { label: v.variationValue, value: v.variationValueId } }) || []}
                                                            searchable={true}
                                                            onChange={({ value }) => handleChangeVariation(item.sl, value, a.id)}
                                                            size='compact'
                                                        />
                                                    </Col>
                                                )
                                            })
                                            : <Col md={2}><b>No Variation</b></Col>
                                    }
                                    <Col md={2}>
                                        <Input
                                            inputRef={register()}
                                            type="number"
                                            name={`purchaseItems.[${item.sl}].quantity`}
                                            onChange={(e) => {
                                                const val = parseFloat(e.target.value) || 0
                                                if (val > item.rem) {
                                                    setValue(`purchaseItems.[${item.sl}].quantity`, item.rem)
                                                }
                                                const rowPrice = getValues(`purchaseItems.[${item.sl}].costPrice`);
                                                setValue(`purchaseItems.[${item.sl}].rowTotal`, val * rowPrice);
                                                adjustprice();
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Input
                                            inputRef={register()}
                                            type="number"
                                            name={`purchaseItems.[${item.sl}].costPrice`}
                                            onChange={(e) => {
                                                const val = parseFloat(e.target.value) || 0
                                                const rowQty = getValues(`purchaseItems.[${item.sl}].quantity`);
                                                setValue(`purchaseItems.[${item.sl}].rowTotal`, val * rowQty);
                                                adjustprice();
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Input
                                            inputRef={register()}
                                            type="number"
                                            name={`purchaseItems.[${item.sl}].rowTotal`}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={1} style={{ alignSelf: 'center' }}><Button
                                        size={SIZE.mini}
                                        shape={SHAPE.circle}
                                        type="button"
                                        onClick={() => handleRemoveItem(item.sl)}

                                    >
                                        <CgClose style={{ fontSize: 'larger' }} />
                                    </Button></Col>
                                </Row>)}

                            <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />


                            <Row style={{ marginBottom: 0 }}>
                                <Col md={1}></Col>
                                <Col md={8}><h3 style={{ float: 'right' }}><strong>Total({CURRENCY})</strong></h3></Col>
                                <Col md={2}><Input
                                    disabled={true}
                                    inputRef={register()}
                                    name="netPrice"
                                /></Col>
                            </Row>


                            <Row>
                                <Col md={2}>
                                    <Button
                                        type="button"
                                        isLoading={submitting}
                                        overrides={{
                                            BaseButton: {
                                                style: ({ $theme }) => ({
                                                    width: '100%',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                    borderBottomLeftRadius: '3px',
                                                }),
                                            },
                                        }}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Save
                                    </Button>
                                </Col>

                            </Row>
                        </>}
                </DrawerBox>
            </Form>

            <MultipleProductSelectModal
                onClose={() => setIsOpenMultipleModal(false)}
                closeable
                handleAutoComplete={handleAutoComplete}
                productOptions={productOptions}
                isOpen={isOpenMultipleModal}
                purchaseItems={purchaseItems}
                handleMultipleProductAdd={(v) => handleMultipleProductAdd(v)}
                animate
                autoFocus
            />
        </Scrollbars>
    );
};

export default AddUpcomingStocks;

