import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'baseui/button'
import { DatePicker } from 'baseui/datepicker'
import { Select } from 'baseui/select';
import { Col, Row } from 'components/FlexBox/FlexBox';
import { FormLabel } from 'components/FormFields/FormFields';
import { Card } from 'components/Widgets/StickerCard/StickerCard.style';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import moment from 'moment';

const QUERY = gql`query($query: QueryParams!){
    journalQuery{
        journal(query: $query){
            baseAccountType
            journalViewModel{
                account{
                    id
                    name
                    createDate
                }
                openingBalance{
                    credit
                    debit
                }
                journals{
                    amount
                    type
                    voucherDate
                    voucherNo
                    accountId
                    comments
                    debit
                    credit
                    isOpeningBalance
                    account{
                        name
                    }
                    salesInvoice{
                        invoiceCode
                    }
                    account{
                        action
                        name
                        openingBalanceJournal{
                            debit
                            credit
                        }
                    }
                }
                isDetail
                isSpecial
            }
        }
    }
}`;
const ACCOUNT_QUERY = gql`query($query: QueryParams!){
    accountQuery{
        accounts(query: $query){
            id
            willHaveChild
            name
        }
    }
}`;

//Today calculation
const fromDate = new Date();
fromDate.setHours(0);
fromDate.setMinutes(0);
fromDate.setSeconds(0);
const toDate = new Date();
toDate.setHours(23);
toDate.setMinutes(59);
toDate.setSeconds(59);
//Yesterday calculation
const yesterdayToDate = new Date();
yesterdayToDate.setHours(23);
yesterdayToDate.setMinutes(59);
yesterdayToDate.setSeconds(59);
const yesterdayFromDate = new Date();
yesterdayFromDate.setHours(0);
yesterdayFromDate.setMinutes(0);
yesterdayFromDate.setSeconds(0);
const yesterdayFrom = yesterdayFromDate.setDate(yesterdayFromDate.getDate() - 1);
const yesterdayTo = yesterdayToDate.setDate(yesterdayToDate.getDate() - 1);
//This month calculation
const thisMonth = moment().clone().startOf('month').format('YYYY-MM-DD hh:mm');
const thisMonthFrom = new Date(new Date(thisMonth).setHours(0));
//This week calculation
const thisWeek = moment().clone().startOf('week').format('YYYY-MM-DD hh:mm');
const thisWeekFrom1 = new Date(new Date(thisWeek).setHours(0));
const thisWeekFrom = thisWeekFrom1.setDate(thisWeekFrom1.getDate() - 1);
//Last week calculation
const lastWeek = moment().clone().startOf('week').format('YYYY-MM-DD hh:mm');
const lastWeekFrom1 = new Date(new Date(lastWeek).setHours(0));
const lastWeekFrom2 = new Date(new Date(lastWeek).setHours(23));
lastWeekFrom2.setMinutes(59)
lastWeekFrom2.setSeconds(59)
const lastWeekFrom = lastWeekFrom1.setDate(lastWeekFrom1.getDate() - 8);
const lastWeekTo = lastWeekFrom2.setDate(lastWeekFrom2.getDate()-2);

//Previous month calculation
const fDate = new Date();
fDate.setHours(0);
fDate.setMinutes(0);
fDate.setSeconds(0);
fDate.setMonth(fDate.getMonth() -1);
let previousMonthFrom = fDate.setDate(1);
let previousMonthTo = new Date().setDate(0);


const dayEnum = {
    today:'TODAY',
    yesterday:'YESTERDAY',
    thisWeek:'THIS_WEEK',
    lastWeek:'LAST_WEEK',
    thisMonth:'THIS_MONTH',
    lastMonth:'LAST_MONTH',
    custom:'CUSTOM'
}

const AccountDetailsHeader = ({ searchJournals, setFromDateInitial }) => {
    const [period, setPeriod] = useState({ toDate: toDate, fromDate: fromDate });
    const [openCustom, setOpenCustom] = useState(false);
    const [selectedBtn, setSelectedButton] = useState("");

    const checkSelected = (slot:string) => {
        if(slot===dayEnum.today)
        {
            setSelectedButton(dayEnum.today);
        }
        else if(slot===dayEnum.yesterday)
        {
            setSelectedButton(dayEnum.yesterday);
        }
        else if(slot===dayEnum.thisWeek)
        {
            setSelectedButton(dayEnum.thisWeek);
        }
        else if(slot===dayEnum.lastWeek)
        {
            setSelectedButton(dayEnum.lastWeek);
        }
        else if(slot===dayEnum.thisMonth)
        {
            setSelectedButton(dayEnum.thisMonth);
        }
        else if(slot===dayEnum.lastMonth)
        {
            setSelectedButton(dayEnum.lastMonth);
        }
        else if(slot===dayEnum.custom)
        {
            setSelectedButton(dayEnum.custom);
        }
    }

    function showJournals(fromDate:any, toDate:any, slot:string) {
        checkSelected(slot);
        setFromDateInitial(fromDate)
        searchJournals(fromDate,toDate);
    }
    const handleOpenCustom = () => {
        checkSelected(dayEnum.custom);
        setOpenCustom(true);
    }

    useEffect(()=>{showJournals(new Date(yesterdayFrom),toDate,dayEnum.today)},[]);

    return (
        <div style={{ marginBottom: 15 }}>
            <Row style={{ marginLeft: 25 }}>
                <Col>
                    <Button kind={selectedBtn === dayEnum.today ? 'primary' : 'secondary'} onClick={() => 
                        { 
                                showJournals(new Date(yesterdayFrom),toDate,dayEnum.today)
                            setOpenCustom(false);
                        }}>Today</Button>
                </Col>
                <Col>
                    <Button kind={selectedBtn === dayEnum.yesterday ? 'primary' : 'secondary'} onClick={() => 
                        { 
                                showJournals(new Date(yesterdayFrom),new Date(yesterdayTo),dayEnum.yesterday);
                            setOpenCustom(false);
                        }}>Yesterday</Button>
                </Col>
                <Col>
                    <Button kind={selectedBtn === dayEnum.thisWeek ? 'primary' : 'secondary'} onClick={() => 
                        { 
                                showJournals(new Date(thisWeekFrom),new Date(toDate),dayEnum.thisWeek);
                            setOpenCustom(false);
                        }}>This Week</Button>
                </Col>
                <Col>
                    <Button kind={selectedBtn === dayEnum.lastWeek ? 'primary' : 'secondary'} onClick={() => 
                        { 
                                showJournals(new Date(lastWeekFrom),new Date(lastWeekTo),dayEnum.lastWeek);
                            setOpenCustom(false);
                        }}>Last Week</Button>
                </Col>
                <Col>
                    <Button kind={selectedBtn === dayEnum.thisMonth ? 'primary' : 'secondary'} onClick={() => 
                        { 
                                showJournals(thisMonthFrom,new Date(toDate),dayEnum.thisMonth);
                            setOpenCustom(false);
                        }}>This Month</Button>
                </Col>
                <Col>
                    <Button kind={selectedBtn === dayEnum.lastMonth ? 'primary' : 'secondary'} onClick={() => 
                        { 
                                showJournals(new Date(previousMonthFrom),new Date(previousMonthTo),dayEnum.lastMonth);
                            setOpenCustom(false);
                        }}>Last Month</Button>
                </Col>
                <Col>
                    <Button kind={selectedBtn === dayEnum.custom ? 'primary' : 'secondary'} onClick={handleOpenCustom}>Custom</Button>
                </Col>
            </Row>
            <Row style={{ marginLeft: 5 }}>
                {
                    openCustom &&
                    <Col md={12}>
                        <Row>
                            <Col md={3} style={{ marginLeft: 25 }}>
                                <FormLabel>From</FormLabel>
                                <DatePicker
                                    mask={null}
                                    formatString="dd-MMM-yyy"
                                    value={[period.fromDate]}
                                    onChange={({ date }) =>
                                        setPeriod({ ...period, fromDate: Array.isArray(date) ? date[0] : date })
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <FormLabel>To</FormLabel>
                                <DatePicker
                                    mask={null}
                                    formatString="dd-MMM-yyy"
                                    value={[period.toDate]}
                                    onChange={({ date }) =>
                                        setPeriod({ ...period, toDate: Array.isArray(date) ? date[0] : date })
                                    }
                                />
                            </Col>
                            <Col md={1} style={{ paddingTop: 19 }}>
                                <Button
                                    type="button"
                                    // isLoading={loading}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }),
                                        },
                                    }}
                                    onClick={() => {
                                        searchJournals(period.fromDate,period.toDate)
                                    }}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
        </div>
    )
}

export default AccountDetailsHeader;