import { Col, Row } from 'components/FlexBox/FlexBox';
import { useEffect, useState } from 'react'
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { Card } from "baseui/card";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Input from 'components/Input/Input';
import Delete from 'baseui/icon/delete'
import { Button, SIZE } from 'baseui/button';
import { AuditStatus } from '../../../containers/Inventory/Audit/Model'
import { ADD_AUDIT_PRODUCT, BRANCH_QUERY, CANCEL_INVENTORY_AUDIT, GET_ASSIGNED_PRODUCTS, GET_BRANCH, GET_CATEGORIES, GET_INVENTORY_AUDITS, PRODUCT_QUERY, PRODUCT_QUERY_WITH_VARIATION } from './AuditQuery';
import { Modal, ModalHeader, ModalFooter } from "baseui/modal";
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import { Select } from 'baseui/select';
import DeleteAlt from 'baseui/icon/delete-alt'
import CompleteAudit from './CompleteAudit';
import { useParams } from "react-router-dom";
import { CURRENCY } from "../../../settings/constants";
import { GetProductVariationId } from 'utils/inventory';

const InventoryAuditExecute = () => {
  const [product, setProduct] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [auditProducts, setAuditProducts] = useState([]);
  const [incompleteProducts, setIncompleteProducts] = useState([]);
  const [openComplete, setOpenComplete] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [quantity, setQuantity] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [branchOptions, setBranchOptions] = useState([]);
  const [store, setStore] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [variations, setVariations] = useState([]);
  const [productVariataions, setProductVariations] = useState([]);
  const [selectedVariations, setSeletedVariations] = useState([]);
  const [variationId, setVariationId] = useState(null);
  const params: any = useParams();

  const auditId = atob(params.id)
  const enqueue = useCustomSnackBar();

  const handleReset = () => {
    setProductOptions([]);
    setProduct(null);
    setAuditProducts([]);
    setQuantity('');
    setCostPrice('');
    setVariations([])
    setSeletedVariations([])
    setVariationId(null)
  }
  const handleClose = () => {
    handleReset();
  };

  const handleBranchChange = (value) => {
    if (value.length === 0) {
      setStore([])
      setStoreId(null)
    } else {
      const st = value[0]
      setStore(st)
      setStoreId(st.value)
    }
  }


  useQuery(BRANCH_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.branchQuery && data.branchQuery.transferBranches && data.branchQuery.transferBranches.toBranches) {
        const branches = data.branchQuery.transferBranches.toBranches.map((item) => ({ label: item.name, value: item.id }))
        setBranchOptions(branches)
      }
    },
    onError: (err) => {
      console.log(err)
    }
  });

  const handleChangeProduct = (e) => {
    const targetProduct = e.value[0];
    if (e.option === null) {
      setProduct(null)
    }
    else {
      if (auditProducts.length > 0 && auditProducts.some(a => a.id === e.option.value)) {
        enqueue("error", "Product already selected!");
        return;
      }
      else if (incompleteProducts.length > 0 && incompleteProducts.some(a => a.productId === e.option.value)) {
        enqueue("error", "Product already selected!");
        return;
      }
      setProduct(e.value);
      let item = { id: e.value, title: e.option.label, quantity: "" };
      let dt = [...auditProducts];
      dt.push(item);
      setAuditProducts(dt);

      let pItems = products && products.productQuery && products.productQuery.pocketXProducts && products.productQuery.pocketXProducts.items || [];
      const productVariations = pItems.find(a => a.id === targetProduct.value).productVariations || [];
      setProductVariations(productVariations)
      const variations = pItems.find(a => a.id === targetProduct.value).variations || [];
      setVariations(variations)
    }

  }


  //   function handleChangeProduct(value) {
  //     const targetProduct = value[0];
  //     if (!targetProduct) {
  //         setProduct({ label: "", value: 0 })
  //         return;
  //     }
  //     else {
  //         setProduct(targetProduct);
  //     }

  //     let pItems = products && products.productQuery && products.productQuery.pocketXProducts && products.productQuery.pocketXProducts.items || [];

  //     const productVariations = pItems.find(a => a.id === targetProduct.value).productVariations || [];
  //     setProductVariations(productVariations)
  //     const variations = pItems.find(a => a.id === targetProduct.value).variations || [];
  //     setVariations(variations)
  // }



  // const [getProducts, { loading: loadingProducts }] = useLazyQuery(PRODUCT_QUERY, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (data) => {
  //     let res = data && data.productQuery && data.productQuery.productsByAttribute;
  //     let prod = res && res.map((item) => ({
  //       value: item.id,
  //       label: item.title,
  //     }));
  //     setProductOptions(prod);
  //   },
  //   onError: () => {
  //     console.log("Product get failed!")
  //   },
  // });

  const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY_WITH_VARIATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.productQuery?.pocketXProducts?.items) {
        const opts = data?.productQuery?.pocketXProducts?.items.map(a => ({
          label: a.title + (a.code ? ` (${a.code})` : ""),
          value: a.id
        }))
        if (opts.length > 0) setProductOptions(opts)
      }
    }
  });

  const [saveAudit, { loading: submitting }] = useMutation(ADD_AUDIT_PRODUCT, {
    onCompleted: (dt) => {
      if (dt && dt.mutate && dt.mutate.result && dt.mutate.result.success) {
        getInvAudit(
          {
            variables: {
              query: {
                params: [
                  {
                    key: "auditId",
                    value: auditId.toString()
                  },
                  {
                    key: "showVariation",
                    value: "true"
                  }
                ]
              }
            }
          }
        );
        enqueue("success", "Successfully added");
        handleReset();
      }
      else {
        enqueue("error", "Something went wrong");
      }
    },
    onError: (e) => {
      enqueue("error", "Couldn't add");
    }
  });

  const [getInvAudit] = useLazyQuery(GET_INVENTORY_AUDITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let res = data && data.query && data.query.result;
      if (res) {
        if (res && res.length > 0) {
          setIncompleteProducts(res.filter((a) => [AuditStatus.Running, AuditStatus.Started].includes(a.status)).map((audit) => ({
            id: audit.id,
            status: audit.status,
            auditId: audit.auditId,
            inventoryAmount: audit.inventoryAmount,
            productId: audit.productId,
            productName: audit.productName,
            storeId: audit.storeId,
            price: audit.price,
            variationName: audit.product.alias
          })))
        }
      }
    },
    onError: () => {
      console.log("Get inv audits failed!");
    },
  });

  const [cancelInvAudit, { loading: cancelling }] = useLazyQuery(CANCEL_INVENTORY_AUDIT, {
    onCompleted: (data) => {
      let res = data && data.query && data.query.result;
      res && getInvAudit({
        variables: {
          query: {
            params: [
              {
                key: "auditId",
                value: auditId.toString()
              },
              {
                key: "showVariation",
                value: "true"
              }
            ]
          }
        }
      });
      setOpenDelete(false);
    },
    onError: (e) => {
      console.log(e.message);
    },
  });

  useEffect(() => {
    getInvAudit({
      variables: {
        query: {
          params: [
            {
              key: "auditId",
              value: auditId.toString()
            },
            {
              key: "showVariation",
              value: "true"
            }
          ]
        }
      }
    })
  }, [])

  function handleAutoComplete(filter) {
    const val = filter.toLowerCase();
    loadProducts({
      variables: {
        query: {
          "page": 1,
          "pageSize": 100,
          "assending": true,
          "orderKey": "Title",
          "filter": val
        }
      }
    });
  }

  const handleSaveProduct = (e) => {
    let model = {
      id: 0,
      status: AuditStatus.Started,
      auditId: +auditId,
      productId: Number(product[0].value),
      inventoryAmount: +quantity,
      storeId: storeId,
      price: parseFloat(costPrice),
      variationId: variationId
    }
    saveAudit({
      variables: {
        data: model
      }
    })
  }

  const handleChangeVariation = (value: any, variationId: number) => {
    var sVariations = [...selectedVariations];
    let isExistVariationValue = sVariations.some(a => a.variationId == variationId);

    if (isExistVariationValue) {
      sVariations = sVariations.filter(a => a.variationId != variationId);
    }

    if (value.length > 0) {
      let permutationId = productVariataions.find(a => a.variationId === variationId && a.variationValueId === value[0].value)?.permutationId || 0;
      sVariations.push(
        {
          variationId: variationId,
          variationValueId: value[0].value,
          variationValue: value[0].label,
          permutationId: permutationId
        })
    }
    setSeletedVariations(sVariations);
    if (sVariations.length === variations.length) {
      let vId = GetProductVariationId(productVariataions, sVariations)
      setVariationId(vId)
    }
  }


  const cancelItem = (id) => {
    cancelInvAudit({
      variables: {
        query: {
          params: [
            {
              key: "inventoryAuditId",
              value: id.toString()
            }
          ]
        }
      }
    })
  }

  return (
    <Row>
      <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
        <Card overrides={{
          Root: {
            style: {
              width: "70%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "60px",
              borderRadius: "6px",
              backgroundColor: "#ffffff",
            }
          }
        }} >
          <h4 style={{ fontSize: 25, marginBottom: 50, color: "#454545" }}> Inventory Audit </h4>
          <Row style={{ marginBottom: 0 }}>
            <Col md={3}><b>Branch</b></Col>
            <Col md={3}><b>Product Name</b></Col>
            {/* <Col md={5}><b>Variations</b></Col> */}
            {variations.length > 0 && <Col md={5}><b>Variations</b></Col>}
          </Row>

          <Row>
            <Col md={3} style={{ marginTop: 13 }}>
              <FormFields>
                {/* <FormLabel>Branch</FormLabel> */}
                <Select
                  options={branchOptions || []}
                  labelKey="label"
                  valueKey="value"
                  value={[store]}
                  searchable={true}
                  onChange={({ value }) => handleBranchChange(value)}
                />
              </FormFields>
            </Col>

            <Col md={3} style={{ marginTop: 13, paddingBottom: 35 }}>
              <FormFields>
                <Select
                  options={productOptions || []}
                  labelKey="label"
                  valueKey="value"
                  searchable={true}
                  value={product}
                  onInputChange={(e: any) => {
                    const text = e.target.value;
                    if (text.length > 1) handleAutoComplete(text)
                  }}
                  onChange={(e) => handleChangeProduct(e)}
                  size={SIZE.default}
                />
              </FormFields>
            </Col>

            {variations.length > 0 &&
              <> {

                variations.length > 0 ?
                  variations.map((a) => {
                    return (
                      <Col md={2} style={{ alignSelf: 'center', marginTop: -25 }}>
                        <Select
                          options={a.variationValues.map(v => { return { label: v.value, value: v.id } }) || []}
                          placeholder={`${a.name}`}
                          labelKey="label"
                          valueKey="value"
                          value={selectedVariations.filter(s => s.variationId === a.id).map(v => { return { label: v.variationValue, value: v.variationValueId } }) || []}
                          searchable={true}
                          onChange={({ value }) => handleChangeVariation(value, a.id)}
                        />
                      </Col>
                    )
                  })
                  : <Col md={4}><b>No Variation</b></Col>
              }
              </>
            }

            <Col md={1} style={{ marginTop: 20 }}>
              <Delete onClick={handleReset} title='Clear Fields' style={{ cursor: 'pointer' }} fontSize='large' color='red' size={30} />
            </Col>
          </Row>

          <Row>
            <Col md={5} style={{ marginTop: 20, marginLeft: 4 }}>
              <FormLabel>Current Quantity</FormLabel>
              <FormFields>
                <Input
                  type="number"
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}

                />
              </FormFields>
            </Col>

            <Col md={4} style={{ marginTop: 20 }}>
              <FormLabel>Unit Cost Price</FormLabel>
              <FormFields>
                <Input
                  fullWidth
                  autofocus
                  type="number"
                  value={costPrice}
                  onChange={(e) => setCostPrice(e.target.value)}
                />
              </FormFields>
            </Col>

            <Col md={2} style={{ marginTop: 62 }}>
              <Button disabled={quantity.length <= 0} onClick={(e) => handleSaveProduct(e)}>Save</Button>
            </Col>
          </Row>

          <Row>
            <Col md={10} style={{ marginTop: 10 }}>
              <div>
                {
                  incompleteProducts?.length > 0 && incompleteProducts?.map((p, index) => {
                    return (
                      <span>
                        <Col style={{ backgroundColor: '#F0EEED' }}>
                          <Row>
                            <Col md={10} style={{ marginTop: 0 }}>
                              {/* <h5 style={{ color: 'green', fontSize: 18, fontWeight: 700 }}>{p.productName + " "+ p.variationName}</h5> */}
                              <h5 style={{ color: 'green', fontSize: 18, fontWeight: 700 }}>{p.productName}  <span>{p?.variationName?.length > 0 && `(${p.variationName})`}</span> </h5>
                            </Col>

                            <Col md={2} style={{ marginTop: 10 }}>
                              <DeleteAlt size={30} color="red" style={{ float: 'right', color: 'red', cursor: 'pointer' }} onClick={() => { setOpenDelete(true); setSelectedProductId(p.id) }} fontSize='medium' />
                            </Col>
                          </Row>

                          <Row>
                            <Col md={10} style={{ marginTop: -60 }}>
                              <h5 style={{ color: "#454545", fontSize: 15 }}>Unit Price: {CURRENCY} {p.price}</h5>
                            </Col>
                          </Row>

                          <Row>
                            <h5 style={{ fontSize: 15, paddingLeft: 16, marginTop: -30, color: "#454545" }}>Current Quantity: {p.inventoryAmount}</h5>
                          </Row>

                        </Col>
                        <br />
                      </span>
                    )
                  })
                }
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 25 }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Row>
                <Col md={4.5} style={{ marginLeft: 60 }}>
                  <Button onClick={handleClose}>Cancel</Button>
                </Col>

                <Col md={8} style={{ marginLeft: -50 }}>
                  <Button onClick={() => setOpenComplete(true)}>Complete Audit</Button>
                </Col>
              </Row>
            </Col>

          </Row>

        </Card>
      </Col>
      {/* <CompleteAudit open={openComplete} setOpen={setOpenComplete} auditId={auditId} refetchAuditList={refetchAuditList} handleCloseInventory={handleClose} setOpenSuccess={setOpenSuccess} setOpenError={setOpenError} /> */}
      <CompleteAudit open={openComplete} setOpen={setOpenComplete} auditId={auditId} handleCloseInventory={handleClose} />
      <Modal
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHeader id="alert-dialog-title" style={{ paddingRight: 30 }}>
          {"Are you sure?"}
        </ModalHeader>
        <ModalFooter>
          <Row style={{ justifyContent: 'end' }}>
            <Col>
              <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
            </Col>

            <Col>
              <Button onClick={() => cancelItem(selectedProductId)} disabled={cancelling} >
                <span style={{ color: 'White' }}>{cancelling ? "Deleting.." : "Yes"}</span>
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </Row>
  )
}

export default InventoryAuditExecute
