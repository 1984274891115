import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from 'baseui/modal';
import { Checkbox } from "baseui/checkbox";
import { Col, Row } from 'components/FlexBox/FlexBox';
import Input from 'components/Input/Input';

export default function MultipleProductModal(props) {
    const { isOpen, closeCallback, cancelCallBack, submitCallback, allProducts, setAllProducts, previousProducts } = props;

    const handleSearch = (value: string) => {
        // 
        let products = [...previousProducts];
        if (value.length > 0) {
            setAllProducts(products.filter(a => a.title.toLowerCase().includes(value.toLowerCase())))
        }
        else {
            setAllProducts(products);
        }
    }

    const handleSelectItem = (value: boolean, id: number) => {
        let products = [...allProducts];
        let targetProduct = products.find(a => a.productId === id);
        targetProduct.isSelected = value;
        setAllProducts(products);
    }

    return (
        <Modal {...props}
            // overrides={{
            //     Dialog: {
            //         style: {
            //             width: '60vw',
            //             height: '100%',
            //             display: 'flex',
            //             flexDirection: 'column',
            //         },
            //     },
            // }}
            size={SIZE.full}
            role={ROLE.dialog}
            onClose={closeCallback} isOpen={isOpen}>
            <ModalHeader>Products</ModalHeader>
            <ModalBody>
                <Row style={{ padding: 10 }}>
                    <Col md={4}>
                        <h4>Search</h4>
                        <Input
                            type="text"
                            autoFocus
                            placeHolder="Search"
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Col>
                    <Col md={2} style={{ marginTop: "3%" }}>
                        <ModalButton onClick={submitCallback}>OK</ModalButton>
                    </Col>
                </Row>
                <Row style={{ padding: 15 }}>
                    {
                        allProducts.length > 0 && allProducts.map((item) => (
                            <Col md={6} style={{ backgroundColor: "#F1F6F9", margin: 5, borderRadius: 5 }}>
                                <Row>
                                    <Col md={1} style={{ marginTop: '1.5%' }}>
                                        <Checkbox
                                            checked={item.isSelected}
                                            onChange={(e: any) => handleSelectItem(e.target.checked, item.productId)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <h4 style={{ color: 'black' }}>{item.title}</h4>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </ModalBody>
            {/* <ModalFooter>
                <ModalButton kind="secondary" onClick={cancelCallBack}>
                    Cancel
                </ModalButton>
                <ModalButton onClick={submitCallback}>OK</ModalButton>
            </ModalFooter> */}
        </Modal>
    )
}