import React from "react";

const Inventory = ({
                             color = 'currentColor',
                             width = '18px',
                             height = '18px',
                         }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width={width} height={height} viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill={color} stroke="none">
                <path d="M1360 4190 c-646 -424 -1181 -778 -1187 -787 -10 -13 -13 -350 -13
-1605 l0 -1589 25 -24 24 -25 351 0 351 0 24 25 25 24 0 1056 0 1055 1600 0
1600 0 0 -1055 0 -1056 25 -24 24 -25 351 0 351 0 24 25 25 24 0 1589 c0 1500
-1 1591 -17 1610 -34 39 -2347 1546 -2378 1549 -25 3 -211 -115 -1205 -767z
m2332 -147 l1108 -726 0 -139 0 -138 -480 0 -480 0 0 -80 0 -80 480 0 480 0 0
-1280 0 -1280 -240 0 -240 0 0 1055 0 1056 -25 24 -24 25 -1711 0 -1711 0 -24
-25 -25 -24 0 -1056 0 -1055 -240 0 -240 0 0 1280 0 1280 480 0 480 0 0 80 0
80 -480 0 -480 0 0 138 0 139 1117 731 c677 444 1123 730 1132 727 8 -3 514
-333 1123 -732z"/>
                <path d="M2345 4375 c-22 -21 -25 -33 -25 -100 l0 -76 -36 -15 -36 -15 -59 57
c-84 79 -90 77 -252 -84 -70 -70 -130 -137 -134 -150 -8 -32 9 -64 61 -120 43
-46 44 -48 31 -80 l-14 -32 -76 0 c-67 0 -79 -3 -100 -25 -25 -24 -25 -26 -25
-215 0 -189 0 -191 25 -215 21 -22 33 -25 100 -25 l76 0 15 -36 15 -36 -55
-58 c-82 -86 -82 -87 76 -247 73 -74 143 -136 156 -140 32 -8 64 9 120 61 46
43 48 44 80 31 l32 -14 0 -76 c0 -67 3 -79 25 -100 24 -25 26 -25 215 -25 189
0 191 0 215 25 22 21 25 33 25 100 l0 76 36 15 36 15 59 -57 c84 -79 90 -77
252 84 70 70 130 137 134 150 8 32 -9 64 -61 120 -43 46 -44 48 -31 80 l14 32
76 0 c67 0 79 3 100 25 25 24 25 26 25 215 0 189 0 191 -25 215 -21 22 -33 25
-100 25 l-76 0 -15 36 -15 36 55 58 c82 86 82 87 -76 247 -73 74 -143 136
-156 140 -31 8 -57 -6 -117 -60 -50 -44 -51 -45 -83 -31 l-32 13 0 76 c0 67
-3 79 -25 100 -24 25 -26 25 -215 25 -189 0 -191 0 -215 -25z m295 -170 c0
-80 41 -131 138 -170 92 -37 148 -31 205 23 l28 26 56 -57 57 -56 -26 -28
c-54 -57 -60 -113 -23 -205 39 -97 90 -138 170 -138 l35 0 0 -80 0 -80 -35 0
c-80 0 -131 -41 -170 -138 -37 -92 -31 -148 23 -205 l26 -28 -57 -56 -56 -57
-28 26 c-57 54 -113 60 -205 23 -97 -39 -138 -90 -138 -170 l0 -35 -80 0 -80
0 0 35 c0 80 -41 131 -138 170 -92 37 -148 31 -205 -23 l-28 -26 -56 57 -57
56 26 28 c54 57 60 113 23 205 -39 97 -90 138 -170 138 l-35 0 0 80 0 80 35 0
c80 0 131 41 170 138 37 92 31 148 -23 205 l-26 28 57 56 56 57 28 -26 c57
-54 113 -60 205 -23 97 39 138 90 138 170 l0 35 80 0 80 0 0 -35z"/>
                <path d="M2466 3910 c-63 -16 -153 -70 -197 -117 -22 -24 -55 -74 -72 -111
-29 -61 -32 -76 -32 -163 0 -90 2 -99 37 -171 45 -91 103 -147 196 -191 61
-29 76 -32 162 -32 86 0 101 3 162 32 93 44 151 100 196 191 35 72 37 81 37
172 0 91 -2 100 -37 172 -68 136 -188 217 -336 224 -42 2 -94 -1 -116 -6z
m168 -162 c86 -26 166 -136 166 -228 0 -124 -116 -240 -240 -240 -124 0 -240
116 -240 240 0 63 23 114 75 165 70 71 145 90 239 63z"/>
                <path d="M1440 2960 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M3520 2960 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M2105 1895 l-25 -24 0 -376 0 -375 -375 0 -376 0 -24 -25 -25 -24 0
-431 0 -431 25 -24 24 -25 1231 0 1231 0 24 25 25 24 0 831 0 831 -25 24 -24
25 -831 0 -831 0 -24 -25z m375 -255 l0 -120 80 0 80 0 0 120 0 120 120 0 120
0 0 -320 0 -320 -320 0 -320 0 0 320 0 320 120 0 120 0 0 -120z m800 0 l0
-120 80 0 80 0 0 120 0 120 120 0 120 0 0 -320 0 -320 -320 0 -320 0 0 320 0
320 120 0 120 0 0 -120z m-1600 -800 l0 -120 80 0 80 0 0 120 0 120 120 0 120
0 0 -320 0 -320 -320 0 -320 0 0 320 0 320 120 0 120 0 0 -120z m800 0 l0
-120 80 0 80 0 0 120 0 120 120 0 120 0 0 -320 0 -320 -320 0 -320 0 0 320 0
320 120 0 120 0 0 -120z m800 0 l0 -120 80 0 80 0 0 120 0 120 120 0 120 0 0
-320 0 -320 -320 0 -320 0 0 320 0 320 120 0 120 0 0 -120z"/>
            </g>
        </svg>
    )
}

export default Inventory;