import React, { useEffect, useMemo, useState } from 'react';
import { withStyle } from 'baseui';
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox';
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import Input from 'components/Input/Input';
import TransactionTable from "../../../components/DataTable/TransactionTable";
import Button from 'components/Button/Button';
import { Header, Heading, Wrapper } from 'components/Wrapper.style';
import { Form } from 'containers/DrawerItems/DrawerItems.style';
import { from, gql, useLazyQuery, useQuery } from '@apollo/client';
import useCustomSnackBar from 'hooks/useCustomSnackBar';
import DrawerBox from "../../../components/DrawerBox/DrawerBox";
import { Link, useParams, useLocation } from "react-router-dom";
import { StyledSpinnerNext as Spinner } from "baseui/spinner";
import { Card } from "../../../components/Widgets/StickerCard/StickerCard.style";
import { StyledHeadCell, StyledTable } from "baseui/table-grid";
import { Table } from "baseui/table-semantic";
import { AddDays } from "../../../settings/common";
import {
    Bank,
    Cash,
    Clients,
    Expense,
    ManufacturingOverhead,
    Sales,
    SalesDiscount,
    Suppliers,
    VatOutput,
    WorkInProcess
} from "../../../settings/accountType";
import JournalType from "../../../settings/journalType";
import { SHAPE, SIZE } from "baseui/button";
import { DatePicker } from "baseui/datepicker";
import { Select } from "baseui/select";
import CustomDataTable from 'components/DataTable/CustomDataTable';

const QUERY = gql`query($query: QueryParams!){
    journalQuery{
        journal(query: $query){
            baseAccountType
            journalViewModel{
                account{
                    id
                    name
                    createDate
                }
                openingBalance{
                    credit
                    debit
                }
                journals{
                    amount
                    type
                    voucherDate
                    voucherNo
                    accountId
                    comments
                    debit
                    credit
                    isOpeningBalance
                    account{
                        name
                    }
                    salesInvoice{
                        invoiceCode
                    }
                    account{
                        action
                        name
                        openingBalanceJournal{
                            debit
                            credit
                        }
                    }
                }
                isDetail
                isSpecial
            }
        }
    }
}`;

const ACCOUNTS_BY_BRANCH = gql`
query($accountIds:[Int]!){
    accountQuery{
      accountsByBranch(accountIds: $accountIds){
        id
        name
      }
    }
  }`

const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',
        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));

const fromDate = new Date();
fromDate.setHours(0);
fromDate.setMinutes(0);
fromDate.setSeconds(0);

const toDate = new Date();
toDate.setHours(23);
toDate.setMinutes(59);
toDate.setSeconds(59);

const convt1 = new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" });
const convt2 = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" });

type Props = any;
const AccountReportDetails: React.FC<Props> = () => {
    const params: any = useParams();
    const [period, setPeriod] = useState({ toDate: toDate, fromDate: fromDate })
    const [accountId, setAccountId] = useState(null);
    let header = [], rows = [];

    let journalModel = null;

    const [loadJournals, { loading, data: journals }] = useLazyQuery(QUERY,
        {
            fetchPolicy: "network-only"
        });

    const _getAmountForCell = (a, type) => {
        return a.isOpeningBalance
            ? (a.type === type
                ? a.amount.toMoney()
                : "---")
            : a.type === type
                ? "---"
                : a.amount.toMoney();
    }

    const _generateRow = (a, convt1, convt2, initBalance, endingBalance, isSpecial) => {
        const voucherDt = a.voucherDate && new Date(a.voucherDate);
        const minDate = new Date(1, 1, 1);
        return [
            voucherDt && voucherDt.getTime() > minDate.getTime() ? convt1.format(voucherDt) : "",
            a.isOpeningBalance
                ? "(Opening Balance)"
                : <Link
                    title="Click to see details"
                    to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(btoa(a.voucherNo))}` : `/accounting/report/details/${a.account.name.toSeoUrl()}-${btoa(a.accountId)}`}>
                    {a.account.name}
                </Link>,
            _getAmountForCell(a, JournalType.Debit),
            _getAmountForCell(a, JournalType.Credit),
            Math.abs(endingBalance).toMoney() + " " + (endingBalance >= 0 ? "Dr" : "Cr"),
        ];
    }

    const generateRows = (journalModel) => {
        journalModel = { ...journalModel };
        if (journalModel.journals) {
            journalModel.journals = [...journalModel.journals];
        }

        let lastBalance, manufecturings,
            endingBalance = journalModel.openingBalance ? journalModel.openingBalance.debit - journalModel.openingBalance.credit : 0;

        if (journalModel.isDetail && journalModel.account && journalModel.account.id === WorkInProcess) {
            manufecturings = journalModel.journals.filter(j => j.account.parentId === ManufacturingOverhead);
            journalModel.journals = journalModel.journals.filter(j => j.account.parentId !== ManufacturingOverhead);
        }

        const rows = (journalModel.journals || []).map((a, i) => {
            const voucherDt = a.voucherDate && new Date(a.voucherDate);

            let initBalance = endingBalance;

            endingBalance += a.isOpeningBalance
                ? a.type === JournalType.Debit
                    ? a.amount
                    : -1 * a.amount
                : a.type === JournalType.Credit
                    ? a.amount
                    : -1 * a.amount;
            return _generateRow(a, convt1, convt2, initBalance, endingBalance, journalModel.isSpecial);
        });

        if (rows.length === 0) {
            rows.push(["---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---"]);
        }

        let openingBalance = journalModel.openingBalance;
        if (journalModel.isDetail && openingBalance) {
            rows.splice(0, 0, {
                style: { backgroundColor: "#d9edf7" },
                data: [
                    {
                        style: { textAlign: "right", fontWeight: "bold" },
                        data: "Initial Balance"
                    },
                    "",
                    `From ${convt1.format(AddDays(period.fromDate, -1))}`,
                    "",
                    "",
                    Math.abs(openingBalance.debit - openingBalance.credit).toMoney() + " " +
                    ((openingBalance.debit - openingBalance.credit) >= 0 ? "Dr" : "Cr"), "", ""
                ]
            });
        }

        // total balance calculation

        let sumOfDr = journalModel.journals
            .filter(a => journalModel.isDetail
                ? a.isOpeningBalance
                    ? a.type === JournalType.Debit
                    : a.type === JournalType.Credit
                : a.amount < 0)
            .map(j => Math.abs(j.amount))
            .reduce((a, b) => a + b, 0);

        let sumOfCr = journalModel.journals
            .filter(a => journalModel.isDetail ? a.isOpeningBalance ? a.type === JournalType.Credit : a.type === JournalType.Debit : a.amount > 0)
            .map(j => Math.abs(j.amount))
            .reduce((a, b) => a + b, 0);

        let row = {
            style: { backgroundColor: "#faf2cc" },
            data: [
                "",
                {
                    style: { fontWeight: "bold" },
                    data: "Total Cash In/Cash Out"
                },
                "",
                {
                    style: { textAlign: "right", fontWeight: "bold" },
                    data: "BDT " + sumOfDr.toMoney()
                },
                "",
                {
                    style: { textAlign: "right", fontWeight: "bold" },
                    data: "BDT " + sumOfCr.toMoney()
                },
                "",
                ""
            ]
        };
        rows.push(row);


        if (openingBalance) {
            sumOfCr += openingBalance.credit;
            sumOfDr += openingBalance.debit;
        }

        let totalBalance = Math.abs(sumOfCr - sumOfDr);

        rows.push({
            style: { backgroundColor: "#dff0d8" },
            data: [
                "",
                {
                    style: { fontWeight: "bold" },
                    data: "Total"
                },
                "",
                sumOfDr >= sumOfCr ? "BDT " + totalBalance.toMoney() : "BDT 0.00",

                "",
                sumOfCr > sumOfDr ? "BDT " + totalBalance.toMoney() : "BDT 0.00",
                "",
                ""
            ]
        });
        return rows;
    }
    if (journals && journals.journalQuery && journals.journalQuery.journal && journals.journalQuery.journal.journalViewModel) {
        journalModel = journals.journalQuery.journal.journalViewModel;
        header = [
            "Date of Transaction",
            "Account Against",
            "Cash In",
            "Cash Out",
            "Balance"
        ];
        rows = generateRows(journalModel);
    }
    function searchJournals(id) {
        loadJournals({
            variables: {
                query: {
                    params: [
                        {
                            key: "accountId",
                            value: id + ""
                        },
                        {
                            key: "isDetail",
                            value: "true"
                        },
                        {
                            key: "fromDate",
                            value: JSON.stringify(period.fromDate)
                        },
                        {
                            key: "toDate",
                            value: JSON.stringify(period.toDate)
                        }
                    ]
                }
            }
        });
    }

    useEffect(() => {
        try {
            let id = params.id;
            if (id) {
                id = id.split("-");
                id = id[id.length - 1];
                id = atob(id);
                setAccountId(id);
            }
        } catch (e) {
            //alert("Not account provided")
        }
    }, [params.id])

    useEffect(() => {
        searchJournals(accountId);
    }, [accountId])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date of Transaction',
                accessor: 'date'
            },
            {
                Header: "Account Against",
                accessor: 'account'
            },
            {
                Header: 'Cash In',
                accessor: 'cashIn'
            },
            {
                Header: 'Cash Out',
                accessor: 'cashOut'
            },
            {
                Header: 'Balance',
                accessor: 'balance'
            }
        ],
        []
    );

    const table = React.useMemo(
        () => {
            let data = []
            if (journals && journals.journalQuery && journals.journalQuery.journal && journals.journalQuery.journal.journalViewModel) {
                const journalModel = journals.journalQuery.journal.journalViewModel;
                let openingBalance = journalModel.openingBalance;
                let endingBalance = journalModel.openingBalance ? journalModel.openingBalance.debit - journalModel.openingBalance.credit : 0;

                if (journalModel.isDetail && openingBalance) {
                    data.splice(0, 0, {
                        date: "Initial Balance",
                        account: `From ${convt1.format(AddDays(period.fromDate, -1))}`,
                        cashIn: "",
                        cashOut: "",
                        balance: Math.abs(openingBalance.debit - openingBalance.credit).toMoney() + ((openingBalance.debit - openingBalance.credit) >= 0 ? " (In)" : " (Out)"),

                    })
                };
                if (journalModel.isDetail && journalModel.journals.length === 0) {
                    data.push(
                        { date: "---", account: "---", cashIn: "---", cashOut: "---", balance: "---" },
                        { date: "---", account: "---", cashIn: "---", cashOut: "---", balance: "---" },
                        { date: "---", account: "---", cashIn: "---", cashOut: "---", balance: "---" }
                    );
                }
                else {
                    (journalModel.journals || []).map((a, i) => {
                        endingBalance += a.isOpeningBalance
                            ? a.type === JournalType.Debit
                                ? a.amount
                                : -1 * a.amount
                            : a.type === JournalType.Credit
                                ? a.amount
                                : -1 * a.amount;
                        const voucherDt = a.voucherDate && new Date(a.voucherDate);
                        const minDate = new Date(1, 1, 1);
                        var row = {
                            date: voucherDt && voucherDt.getTime() > minDate.getTime() ? convt1.format(voucherDt) : "",
                            account: a.isOpeningBalance
                                ? "(Opening Balance)"
                                : <Link
                                    title="Click to see details"
                                    to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(btoa(a.voucherNo))}` : `/accounting/report/details/${a.account.name.toSeoUrl()}-${btoa(a.accountId)}`}>
                                    {a.account.name}
                                </Link>,
                            cashIn: _getAmountForCell(a, JournalType.Debit),
                            cashOut: _getAmountForCell(a, JournalType.Credit),
                            balance: Math.abs(endingBalance).toMoney() + " " + (endingBalance >= 0 ? "(In)" : "(Out)")
                        }
                        data.push(row)
                    });
                }
                // total balance calculation
                let sumOfDr = journalModel.journals
                    .filter(a => journalModel.isDetail
                        ? a.isOpeningBalance
                            ? a.type === JournalType.Debit
                            : a.type === JournalType.Credit
                        : a.amount < 0)
                    .map(j => Math.abs(j.amount))
                    .reduce((a, b) => a + b, 0);

                let sumOfCr = journalModel.journals
                    .filter(a => journalModel.isDetail ? a.isOpeningBalance ? a.type === JournalType.Credit : a.type === JournalType.Debit : a.amount > 0)
                    .map(j => Math.abs(j.amount))
                    .reduce((a, b) => a + b, 0);
                data.push({ date: "", account: "Total Cash In/Cash Out", cashIn: "BDT " + sumOfDr.toMoney(), cashOut: "BDT " + sumOfCr.toMoney(), balance: "" });


                if (openingBalance) {
                    sumOfCr += openingBalance.credit;
                    sumOfDr += openingBalance.debit;
                }

                let totalBalance = Math.abs(sumOfCr - sumOfDr);
                data.push({
                    date: "",
                    account: "Total",
                    cashIn: sumOfDr >= sumOfCr ? "BDT " + totalBalance.toMoney() : "",
                    cashOut: sumOfCr > sumOfDr ? "BDT " + totalBalance.toMoney() : "",
                    balance: ""
                });
            }
            return <CustomDataTable columns={columns} data={data} title={journalModel?.account?.name} loading={loading} canPrint={true} />
        },
        [journals]
    );

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <Row>
                            <Col md={3} style={{ marginLeft: 25 }}>
                                <FormLabel>From</FormLabel>
                                <DatePicker
                                    mask={null}
                                    formatString="dd-MMM-yyy"
                                    value={[period.fromDate]}
                                    onChange={({ date }) =>
                                        setPeriod({ ...period, fromDate: Array.isArray(date) ? date[0] : date })
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <FormLabel>To</FormLabel>
                                <DatePicker
                                    mask={null}
                                    formatString="dd-MMM-yyy"
                                    value={[period.toDate]}
                                    onChange={({ date }) =>
                                        setPeriod({ ...period, toDate: Array.isArray(date) ? date[0] : date })
                                    }
                                />
                            </Col>
                            <Col md={1} style={{ paddingTop: 19 }}>
                                <Button
                                    type="button"
                                    isLoading={loading}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }),
                                        },
                                    }}
                                    onClick={() => {
                                        if (accountId) searchJournals(accountId)
                                    }}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        {/* <h4 style={{ paddingLeft: 25 }}>
                            {journalModel && journalModel.account && journalModel.account.name}
                            {journalModel && typeof journalModel.account !== "undefined" ? `(${convt1.format(new Date(journalModel.account.createDate))})` : null}
                            </h4> */}
                        {/*<div ref={a => this.printArea = a}>*/}
                        <div>
                            {/* <TransactionTable
                                columns={header}
                                data={rows}
                                loading={loading}
                            /> */}
                             {table}
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default AccountReportDetails;


