import React from 'react';
import Button from 'components/Button/Button';
import { Plus } from 'assets/icons/PlusMinus';
import { gql, useQuery } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import { Grid, Row, Col } from 'components/FlexBox/FlexBox';
import { useHistory } from 'react-router-dom';
import { ADD_UPCOMING_STOCKS } from "../../../settings/constants";
import CustomDataTable from '../../../components/DataTable/CustomDataTable';

const GET_UPCOMING_PRODUCTS = gql`
query($query: QueryParams!) {
    stockQuery {
      upcomingStocks(query: $query) {
        id
        product {
          id
          title
        }
        quantity
        upcomingQuantity
      }
    }
  }`;

export default function ViewUpcomingStocks() {
    const history = useHistory();

    const { loading, data } = useQuery(GET_UPCOMING_PRODUCTS, {
        fetchPolicy: "network-only",
        variables: {
            "query": {

            }
        }
    });

    const columns = React.useMemo(
        () => [
            {
                Header: 'Serial Id',
                accessor: 'id', 
            },
            {
                Header: 'Product Name',
                accessor: 'productName', 
                disableFilters: true
            },
            {
                Header: 'Upcoming Quantity',
                accessor: 'upcomingQuantity', 
                disableFilters: true
            },
        ],
        []
    );

    const table = React.useMemo(
        () => {
            let upComingProduct = []
            if (data?.stockQuery?.upcomingStocks) {
                upComingProduct = data?.stockQuery?.upcomingStocks?.map((a, index) => ({
                    id: index + 1,
                    productId: a.product.id,
                    productName: a.product.title,
                    quantity: a.quantity,
                    upcomingQuantity: a.upcomingQuantity,
                }));
            }
            return <CustomDataTable columns={columns} data={upComingProduct} loading={loading} />
        },
        [data]
    );


    return (
        <Grid fluid={true}>
            <Row>
                <Col md={12}>
                    <Header
                        style={{
                            marginBottom: 40,
                            boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                        }}
                    >
                        <Col md={4} xs={12}>
                            <Heading>Upcoming Products</Heading>
                        </Col>

                        <Col md={8} xs={12}>
                            <Row>
                                <Col md={8} xs={12}>
                                </Col>

                                <Col md={4} xs={12}>
                                    <Button
                                        onClick={() => history.push(ADD_UPCOMING_STOCKS)}
                                        startEnhancer={() => <Plus />}
                                        overrides={{
                                            BaseButton: {
                                                style: () => ({
                                                    width: '100%',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }),
                                            },
                                        }}
                                    >
                                        Add Upcoming Products
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Header>
                    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                        {table}
                    </Wrapper>
                </Col>
            </Row>
        </Grid>
    );
}

