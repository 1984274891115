import { gql } from "@apollo/client";

export const GET_AUDIT_LIST = gql`
query GET_AUDITS($query: QueryParams!) {
  auditQuery {
    audits(query: $query) {
      page
      pageSize
      totalCount
      items {
        id
        title
        type
        status
        auditProgress
        auditNo
      }
    }
  }
}
`;

export const GET_INVENTORY_AUDITS = gql`
query inventoryAudits($query: QueryParams!) {
  query:auditQuery{
   result:inventoryAudits(query:$query){
     id
     auditId
     storeId
     productId
     variationId
     inventoryAmount
     systemAmount
     productName
     status
     price
     product{
      alias
     }
   }
 }
}`;

export const CANCEL_AUDIT = gql`
query cancelAudit($query: QueryParams!) {
  query:auditQuery {
    result:cancelAudit(query: $query)
  }
}
`;

export const ADD_NEW_AUDIT = gql`
mutation AddAudit($data: AuditRequestType!) {
  audit {
    addAudit(audit: $data) {
      success
      data {
        id
        title
        type
        status
      }
    }
  }
}
`;

export const ADD_AUDIT_PRODUCT = gql`
mutation AddInvAudit($data: InventoryAuditRequestType!) {
  mutate:audit {
    result:addInventoryAudit(inventoryAudit: $data) {
      success
      data {
        auditId
        storeId
        productId
        inventoryAmount
        status
        price
      }
    }
  }
}
`;

export const ADD_AUDIT_PRODUCTS = gql`
mutation AddInvAudits($data: [InventoryAuditRequestType]) {
  mutate:audit {
    result:addBulkInventoryAudit(inventoryAudits: $data) {
      success
      data
    }
  }
}
`;

export const CANCEL_INVENTORY_AUDIT = gql`
query cancelInvAudits($query: QueryParams!) {
  query:auditQuery {
    result:cancelInventoryAudit(query: $query)
  }
}
`;

export const COMPLETE_AUDIT = gql`
  mutation CompleteAudit($data: [ID]!, $isStockItemPriceArg:Boolean!) {
    mutate:audit {
      result:completeInventoryAudit(inventoryAuditComplete: $data, isStockItemPriceArg: $isStockItemPriceArg) {
        success
      }
    }
  }
`;

export const GET_BRANCH = gql`
query($query: QueryParams!){
  branchQuery{
    orgUnits(query:$query){
     page
      pageSize
      totalCount
      items{
        id
        name
        type
        address
        contactPerson
        phone
        remarks
        active
      }
    }
  }
}
`;
export const GET_PRODUCT_BY_STORE = gql`
query($query: QueryParams!){
    stockQuery{
      availableByStore(query:$query){
        id
        productId
        productName
        quantity
      }
    }
}`;
export const GET_CATEGORIES = gql`
query search_categories($query: QueryParams!){
  categoryQuery{
    categories(query:$query){
      id
      name
      parentId
      active
    }
  }
}
`;
export const GET_ASSIGNED_PRODUCTS = gql`query($query:QueryParams!){
    products: productQuery{
      result: assignedProducts(query:$query){
        id,
        title
        code
        active      
        category{
          id
          name
        }
      }
    }
  }`;

export const BRANCH_QUERY = gql`
  query{
    branchQuery{
      transferBranches{
        fromBranches{
          id
          name
        }
        toBranches{
          id
          name
        }
      }
    }
  }`

  export const PRODUCT_QUERY = gql`
  query($query: QueryParams!){
    productQuery{
      productsByAttribute(query: $query){
        id
        title
      }
    }
  }`


export const PRODUCT_QUERY_WITH_VARIATION = gql`
query($query: QueryParams!) {
  productQuery {
    pocketXProducts(query: $query) {
      items {
        id
        title
        code
        measureUnit
        productVariations {
          productId
          variationId
          variationValueId
          permutationId
        }
        variations {
          id
          name
          variationValues {
            id
            variationId
            value
          }
        }
      }
    }
  }
}`
