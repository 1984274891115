import React, { useEffect, useMemo, useState } from 'react';
import { withStyle } from 'baseui';
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox';
import TransactionTable from "../../../components/DataTable/TransactionTable";
import Button from 'components/Button/Button';
import { Link, useParams, useLocation } from "react-router-dom";
import { Card } from "../../../components/Widgets/StickerCard/StickerCard.style";
import { AiFillPrinter } from "react-icons/ai";
import { AddDays } from "../../../settings/common";
import { Cash, ManufacturingOverhead, WorkInProcess } from "../../../settings/accountType";
import JournalType from "../../../settings/journalType";
import { SHAPE, SIZE } from "baseui/button";
import AccountHeader from 'components/AccountHeader/AccountHeader';
import { StyledRow } from 'baseui/table';
import { SmallCell } from 'components/DataTable/Datatable.style';

const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',
        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));

const fromDate = new Date();
fromDate.setHours(0);
fromDate.setMinutes(0);
fromDate.setSeconds(0);

const toDate = new Date();
toDate.setHours(23);
toDate.setMinutes(59);
toDate.setSeconds(59);

const convt1 = new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" });
const convt2 = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" });

type Props = any;
const AccCash: React.FC<Props> = () => {
    const [receivedData, setReceivedData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fromDateInitial, setFromDateInitial] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [dayType, setDayType] = useState("");
    let header = [], rows = [];

    const printStatement = (e) => {
        alert("Print")
    }

    let journalModel = null;

    const _getAmountForCell = (a, type) => {
        return a.isOpeningBalance
            ? (a.type === type
                ? a.amount.toMoney()
                : "---")
            : a.type === type
                ? "---"
                : a.amount.toMoney();
    }

    const _generateRow = (a, convt1, convt2, initBalance, endingBalance, isSpecial) => {
        const voucherDt = a.voucherDate && new Date(a.voucherDate);
        const minDate = new Date(1, 1, 1);
        const jvnInv = (a.account.name === "Sales" && a.salesInvoice.invoiceCode
            ? (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}<br />{`${a.salesInvoice.invoiceCode}`}</div>)
            : (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}</div>));
        if (isSpecial) {
            let op = a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit;
            return [
                <Link
                    title="Click to see details"
                    target='_blank'
                    to={
                        a.account.action
                            ? `/accounting/journal/view?no=${encodeURIComponent(
                                btoa(a.voucherNo)
                            )}`
                            : `/bank/details/${a.account.name.toSeoUrl()}-${btoa(
                                a.accountId
                            )}`
                    }
                >
                    {a.account.name}
                </Link>,
                op > 0 ? op.toMoney() : "0.00",
                op < 0 ? Math.abs(op).toMoney() : "0.00",
                a.debit.toMoney(),
                a.credit.toMoney(),
                endingBalance > 0 ? Math.abs(endingBalance).toMoney() : "0.00",
                endingBalance < 0 ? Math.abs(endingBalance).toMoney() : "0.00"
            ];

        } else {
            return [
                voucherDt && voucherDt.getTime() > minDate.getTime() ? convt1.format(voucherDt) : "",
                a.isOpeningBalance
                    ? "(Opening Balance)"
                    : <Link
                    title="Click to see details"
                    target='_blank'
                    to={ `/bank/details/${a.account.name.toSeoUrl()}-${btoa(
                                a.accountId
                            )}`}
                >
                    {a.account.name}
                </Link>,,
                // <Link
                //     title="Click to see details"
                //     to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(btoa(a.voucherNo))}` : `/accounting/details/${a.account.name.toSeoUrl()}-${btoa(a.accountId)}`}>
                //     {a.account.name}
                // </Link>,
                // Math.abs(initBalance).toMoney() + " " + (initBalance >= 0 ? "Dr" : "Cr"),
                _getAmountForCell(a, JournalType.Debit),
                _getAmountForCell(a, JournalType.Credit),
                // Math.abs(endingBalance).toMoney() + " " + (endingBalance >= 0 ? "Dr" : "Cr"),
                a.comments || "No Comments",
                // a.voucherNo ? jvnInv : ""
            ];
        }
    }

    const generateRows = (journalModel) => {
        journalModel = { ...journalModel };
        if (journalModel.journals) {
            journalModel.journals = [...journalModel.journals];
        }

        let lastBalance, manufecturings,
            endingBalance = journalModel.openingBalance ? journalModel.openingBalance.debit - journalModel.openingBalance.credit : 0;

        if (journalModel.isDetail && journalModel.account && journalModel.account.id === WorkInProcess) {
            manufecturings = journalModel.journals.filter(j => j.account.parentId === ManufacturingOverhead);
            journalModel.journals = journalModel.journals.filter(j => j.account.parentId !== ManufacturingOverhead);
        }

        const rows = (journalModel.journals || []).map((a, i) => {
            const voucherDt = a.voucherDate && new Date(a.voucherDate);

            if (!journalModel.isDetail) {
                if (!lastBalance) {
                    lastBalance = journalModel.openingBalance.debit - journalModel.openingBalance.credit;
                }
                return [
                    voucherDt ? convt1.format(voucherDt) : "",
                    "Balance",
                    Math.abs(lastBalance).toMoney() + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                    a.debit.toMoney(),
                    a.credit.toMoney(),
                    Math.abs((lastBalance = ((a.debit - a.credit) + lastBalance))).toMoney() + " " + (a.debit >= a.credit ? "Dr" : "Cr")
                ];
            }

            let initBalance = endingBalance;
            if (journalModel.isSpecial) {
                endingBalance = (a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit) + (a.debit - a.credit);
            } else {
                endingBalance += a.isOpeningBalance
                    ? a.type === JournalType.Debit
                        ? a.amount
                        : -1 * a.amount
                    : a.type === JournalType.Credit
                        ? a.amount
                        : -1 * a.amount;
            }
            return _generateRow(a, convt1, convt2, initBalance, endingBalance, journalModel.isSpecial);
        });

        if (manufecturings && manufecturings.length > 0) {
            rows.push([{ data: "Manufacturing Overhead", style: { textDecoration: "underline" } }, "", "", "", ""]);

            const grouped = Object.values((manufecturings).groupBy("accountId"));

            grouped.forEach((a: any) => {
                const dr = a.filter(b => b.type === JournalType.Debit).map(c => c.amount).reduce((n, m) => n + m, 0);
                const cr = a.filter(b => b.type === JournalType.Credit).map(c => c.amount).reduce((n, m) => n + m, 0);
                const total = Math.abs(cr - dr);
                rows.push([
                    convt1.format(new Date(a[0].voucherDate)),
                    (<Link
                        title="Click to see details"
                        to={`/accounting/details/${a[0].account.name.toSeoUrl()}-${a[0].accountId}`}>
                        {a[0].account.name}
                    </Link>),
                    "",
                    cr >= dr ? total.toMoney() : "---",
                    dr > cr ? total.toMoney() : "---",
                    // "---",
                    // "---"
                ]);
            });

            manufecturings.forEach(a => journalModel.journals.push(a));
        }

        if (rows.length === 0) {
            rows.push(["---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---"]);
        }

        let openingBalance = journalModel.openingBalance;
        if (journalModel.isDetail && openingBalance) {
            rows.splice(0, 0, {
                style: { backgroundColor: (openingBalance.debit - openingBalance.credit) < 0 ? "#f7b9b5" : "#d9edf7" },
                data: [
                    {
                        style: { fontWeight: "bold" },
                        data: "Initial Balance"
                    },
                    `From ${convt1.format(AddDays(fromDateInitial, -1))}`,
                    //openingBalance.debit >= openingBalance.credit ? (openingBalance.debit - openingBalance.credit).toMoney() : "",
                    //openingBalance.credit > openingBalance.debit ? (openingBalance.credit - openingBalance.debit).toMoney() : "",
                    "৳ " + (openingBalance.debit - openingBalance.credit).toMoney(),
                ]
            });
        }

        if (!journalModel.isDetail) {
            rows.push({
                style: { backgroundColor: "#dff0d8" },
                data: [
                    "",
                    {
                        style: { textAlign: "right", fontWeight: "bold" },
                        data: "Total"
                    },
                    (Math.abs(lastBalance) || 0).toMoney() + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                    "",
                    "",
                    ""
                ]
            });
            return rows;
        }

        if (journalModel.isSpecial) {

            let sumOfOpDr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.debit - j.account.openingBalanceJournal.credit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            let sumOfOpCr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.credit - j.account.openingBalanceJournal.debit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            let sumOfDr = journalModel.journals
                .map(j => j.debit)
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .map(j => j.credit)
                .reduce((a, b) => a + b, 0);

            let endDr = sumOfOpDr + sumOfDr;
            let endCr = sumOfOpCr + sumOfCr;

            let row1 = {
                style: { backgroundColor: "#dff0d8", fontWeight: "500" },
                data: [
                    "Total Opening Debit: ",
                    sumOfOpDr.toMoney(),
                ]
            };
            rows.push(row1);
            let row2 = {
                style: { backgroundColor: "#dff0d8", fontWeight: "500" },
                data: [
                    "Total Opening Credit: ",
                    sumOfOpCr.toMoney(),
                ]
            };
            rows.push(row2);
            let row3 = {
                style: { backgroundColor: "#dff0d8", fontWeight: "500" },
                data: [
                    "Total Debit: ",
                    `৳ ${sumOfDr.toMoney()}`,
                ]
            };
            rows.push(row3);
            let row4 = {
                style: { backgroundColor: "#dff0d8", fontWeight: "500" },
                data: [
                    "Total Crebit: ",
                    `৳ ${sumOfCr.toMoney()}`,
                ]
            };
            rows.push(row4);
            let row5 = {
                style: { backgroundColor: "#dff0d8", fontWeight: "500" },
                data: [
                    "End Debit: ",
                    endDr.toMoney(),
                ]
            };
            rows.push(row5);
            let row6 = {
                style: { backgroundColor: "#dff0d8", fontWeight: "500" },
                data: [
                    "End Credit: ",
                    endCr.toMoney()
                ]
            };
            rows.push(row6);
        } else if (journalModel.journals) { // total balance calculation

            let sumOfDr = journalModel.journals
                .filter(a => journalModel.isDetail
                    ? a.isOpeningBalance
                        ? a.type === JournalType.Debit
                        : a.type === JournalType.Credit
                    : a.amount < 0)
                .map(j => Math.abs(j.amount))
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .filter(a => journalModel.isDetail ? a.isOpeningBalance ? a.type === JournalType.Credit : a.type === JournalType.Debit : a.amount > 0)
                .map(j => Math.abs(j.amount))
                .reduce((a, b) => a + b, 0);

            let row = {
                style: { backgroundColor: "#faf2cc" },
                data: [
                    {
                        style: { textAlign: "right", fontWeight: "bold" },
                        data: "Total Cash In"
                    },
                    "",
                    "৳ " + sumOfDr.toMoney(),
                ]
            };
            rows.push(row);
            let row2 = {
                style: { backgroundColor: "#faf2cc" },
                data: [
                    {
                        style: { textAlign: "right", fontWeight: "bold" },
                        data: "Total Cash Out"
                    },
                    "",
                    "৳ " + sumOfCr.toMoney(),
                ]
            };
            rows.push(row2);


            if (openingBalance) {
                sumOfCr += openingBalance.credit;
                sumOfDr += openingBalance.debit;
            }

            let totalBalance = (sumOfDr - sumOfCr);

            rows.push({
                style: { backgroundColor: totalBalance < 0 ? "#f7b9b5" : "#d9edf7" },
                data: [
                    {
                        style: { textAlign: "right", fontWeight: "bold" },
                        data: "Current Balance"
                    },
                    sumOfDr >= sumOfCr ? "৳ " + totalBalance.toMoney() : "",
                    sumOfCr > sumOfDr ? "৳ " + totalBalance.toMoney() : "",
                ]
            });

        }
        return rows;
    }
    // if (data && data.journalQuery && data.journalQuery.journal && data.journalQuery.journal.journalViewModel) {
    if (receivedData && receivedData.journalQuery && receivedData.journalQuery.journal && receivedData.journalQuery.journal.journalViewModel) {
        journalModel = receivedData.journalQuery.journal.journalViewModel;
        header = !journalModel.isSpecial
            ? [
                "Date",
                "Accounts",
                // journalModel.isDetail ? "Debit" : "Opening Balance",
                "Cash in",
                // journalModel.isDetail ? "Credit" : "Debit",
                "Cash Out",
                // journalModel.isDetail ? "Note" : "Credit",
                "Note"
                // journalModel.isDetail ? "JVN/INV" : "Ending Balance"
            ]
            : [
                "Account Against",
                "Opening(Debit)",
                "Opening(Credit)",
                "Debit",
                "Credit",
                "Ending(Debit)",
                "Ending(Credit)"
            ];

        // if (!journalModel.isSpecial) {
        //     //if (journalModel.isDetail) {
        //     //    header.splice(2, 0, "Opening Balance");
        //     //}
        //     if (journalModel.isDetail) {
        //         header.splice(4, 0, "Balance");
        //     }
        // }

        rows = generateRows(journalModel);
    }

    return (
        <>
            <AccountHeader accId={Cash.toString()} setLoading={setLoading} setReceivedData={setReceivedData} setFromDateInitial={setFromDateInitial} setToDate={setToDate} setDayType={setDayType} />
            <Row style={{ alignItems: 'flex-start' }}>
                <Col md={8}>
                    <Card>
                        <h4 style={{ paddingLeft: 25, textAlign: 'center' }}>
                            {journalModel && journalModel.account && journalModel.account.name}<br />
                            {
                                dayType !== "CUSTOM" &&
                                    dayType !== "TODAY" && dayType !== "YESTERDAY" ?
                                    (convt1.format(AddDays(fromDateInitial, 0)) + " - " + convt1.format(AddDays(toDate, 0))) :
                                    convt1.format(AddDays(toDate, 0))
                            }
                        </h4>
                        {/*<div ref={a => this.printArea = a}>*/}
                        <div>
                            <TransactionTable
                                accountID={4}
                                columns={header}
                                data={rows}
                                loading={loading}
                            />
                        </div>
                    </Card>
                </Col>
                <Col md={4} >
                    <Card>
                        {
                            rows?.map(row => {
                                // Prepare the row for display
                                if (row.style) {
                                    return <StyledRow style={row.style}>
                                        {// Loop over the rows cells
                                            row.data.map(cell => {
                                                // Apply the cell props
                                                if (cell.data) {
                                                    return <SmallCell style={cell.style}>
                                                        {cell.data}
                                                    </SmallCell>
                                                }
                                                return (
                                                    <SmallCell>
                                                        {cell}
                                                    </SmallCell>
                                                )
                                            })}
                                    </StyledRow>
                                }
                            })
                        }
                    </Card>
                </Col>
            </Row>
            <Col md={2}>
                <Button shape={SHAPE.pill} onClick={printStatement}><AiFillPrinter /></Button>
            </Col>

        </>
    );
}
export default AccCash;

