import React, { Fragment, useCallback, useState } from 'react';
import { Grid } from 'components/FlexBox/FlexBox';
import { useQuery, gql } from '@apollo/client';
import { Wrapper } from 'components/Wrapper.style';
import CustomDataTable from "../../components/DataTable/CustomDataTable";
import { DateFormat } from "../../settings/common";
import { Col, Row } from "./SalesInvoice.style"
import { CURRENCY } from "../../settings/constants";
import { GetSalesPaymentStatus } from "../../lib/sales"

export const SALES_INV_DETAILS = gql`query($query: QueryParams!){
    salesQuery{
        salesInvoice(query:$query){
            invoiceCode
            status
            salesDate
            total
            totalPaid
            vat
            note
            discount
            client{
                id
                name
              contact{
                number
                place
              }
            }
            invoicePayments{
                amount
                paymentDate
                createdBy{
                  fullName
                }
            }
            invoiceItems{
                id
                quantity
                price
                discount
                vat
                status
                product{
                    id
                    title
                    measureUnit
                    code
                    warrantyInMonths
                }
                warranties{
                    id
                    serial1
                    warrantyInMonths
                }
            }
        }
    }
}
`;

export const POCKETX_SALES_DETAILS = gql`query($query: QueryParams!){
    salesQuery{
      pocketXSalesInvoice(query: $query){
          invoiceCode
          status
          salesDate
          total
          vat
          note
          discount
          client{
              id
              name
          }
          invoiceItems{
              id
              quantity
              itemVariationName
              price
              discount
              vat
              status
              product{
                  id
                  title
                  measureUnit
                  code
                  warrantyInMonths
              }
          }
      }
    }
  }
`;




export default function SalesDetails({ id }) {
    const { loading, data } = useQuery(POCKETX_SALES_DETAILS,
        {
            variables: {
                query: {
                    "id": id
                }
            }
        });

    const columns = React.useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
                Filter: false
            },
            {
                Header: 'Code',
                accessor: 'code',
                Filter: false
            },
            {
                Header: 'Measuring Unit',
                accessor: 'measureUnit',
                Filter: false
            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                Filter: false
            },
            {
                Header: 'Sales price',
                accessor: 'price',
                Filter: false
            }
        ],
        []
    );

    const [SalesInvoice, table] = React.useMemo(
        () => {
            let invoice = null;
            let salesItems = [];
            if (data && data.salesQuery && data.salesQuery.pocketXSalesInvoice.invoiceItems) {
                salesItems = data.salesQuery.pocketXSalesInvoice.invoiceItems.map(salesItem => {
                    let variation = salesItem.itemVariationName ? `(${salesItem.itemVariationName})` : ""
                    return{
                        title: `${salesItem.product.title } ${variation}`,
                        code: salesItem.product.code,
                        measureUnit: salesItem.product.measureUnit,
                        quantity: salesItem.quantity,
                        price: salesItem.price
                    }
                });
                invoice = data.salesQuery.pocketXSalesInvoice;
            }
            return [invoice, <CustomDataTable columns={columns} data={salesItems} loading={loading} />]
        },
        [data]
    );

    function getNetPayable(inv) {
        let totalPaid = parseFloat(inv.totalPaid) ? parseFloat(inv.totalPaid) : 0;
        let discount = parseFloat(inv.discount) ? parseFloat(inv.discount) : 0;
        let invTotal = parseFloat(inv.total)
        let vat = parseFloat(inv.vat)

        let netPayble = ((invTotal + vat) - discount)
        return Number(netPayble).toFixed(2)
    }

    return (
        <Grid style={{ backgroundColor: "#FFFFFF", padding: 30 }} fluid={true}>
            <Col md={12}>
                <Row>
                    <Col md={12} style={{ textAlign: "center" }}><h1>{SalesInvoice && SalesInvoice.status === "QUOTATION" ? "Quotation" : "Sales Invoice"} Details</h1></Col>
                </Row>
                <Row style={{ margin: '15px 0px 10px 20px' }}>
                    <Col md={2}>
                        {SalesInvoice && SalesInvoice.status === "QUOTATION" ? "Quotation" : "Sales Invoice"} No
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                        <b>:</b> {SalesInvoice && SalesInvoice.invoiceCode}
                    </Col>
                    <Col md={3} style={{ textAlign: "right" }}>
                        {SalesInvoice && SalesInvoice.status === "QUOTATION" ? "Quotation" : "Sales"} Date
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                        <b>:</b> {DateFormat.format(new Date(SalesInvoice && SalesInvoice.salesDate))}
                    </Col>
                </Row>
                <Row style={{ margin: '15px 0px 10px 20px' }}>
                    <Col md={2}>
                        Client Name
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                        <b>:</b> {SalesInvoice && SalesInvoice.client.name}
                    </Col>

                    <Col md={3} style={{ textAlign: "right" }}>
                        Sub-Total
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                        <b>:</b> {SalesInvoice && SalesInvoice.total && `${CURRENCY} ${SalesInvoice.total.toMoney(false)}`}
                    </Col>
                </Row>
                <Row style={{ margin: '15px 0px 10px 20px' }}>
                    <Col md={2}>
                        Note
                    </Col>
                    <Col md={3}>
                        <b>:</b> {SalesInvoice && `${SalesInvoice.note}`}
                    </Col>

                    <Col md={3} style={{ textAlign: "right" }}>
                        Discount
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                        <b>:</b> {SalesInvoice && SalesInvoice.discount && `${CURRENCY} ${SalesInvoice.discount}`}
                    </Col>
                </Row>
                <Row style={{ margin: '15px 0px 10px 20px' }}>
                    {SalesInvoice && SalesInvoice.status !== "QUOTATION" ?
                        <Fragment>
                            <Col md={2}>
                                Status.
                            </Col>
                            <Col md={3} style={{ textAlign: "left" }}>
                                {/*<b>:</b> {SalesInvoice && SalesInvoice.status}*/}
                                <b>:</b> {GetSalesPaymentStatus(SalesInvoice)}
                            </Col>
                        </Fragment> :
                        <Fragment>
                            <Col md={2}>
                            </Col>
                            <Col md={3} style={{ textAlign: "left" }}>
                            </Col>
                        </Fragment>
                    }
                    <Col md={3} style={{ textAlign: "right" }}>
                        Vat
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                        <b>:</b> {SalesInvoice && `${CURRENCY} ${SalesInvoice.vat}`}
                    </Col>
                </Row>
                <Row style={{ margin: '15px 0px 10px 20px' }}>
                    <Col md={5}>
                    </Col>
                    <Col md={3} style={{ textAlign: "right" }}>
                        Total
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                        {/*<b>:</b> {SalesInvoice && `${CURRENCY} ${((SalesInvoice.total - SalesInvoice.discount + SalesInvoice.vat).toMoney(false))}`}*/}
                        <b>:</b> {SalesInvoice && `${CURRENCY} ${getNetPayable(SalesInvoice)}`}
                    </Col>
                </Row>
                <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', paddingTop: 30, marginTop: 20 }}>
                    {table}
                </Wrapper>
            </Col>
        </Grid>
    );
}