import { useState } from "react";
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Plus } from 'assets/icons/PlusMinus';
import Button from 'components/Button/Button';
import ManualDataTable from 'components/DataTable/ManualDataTable';
import { Col, Grid, Row } from 'components/FlexBox/FlexBox';
import { Header, Heading, Wrapper } from 'components/Wrapper.style';
import { useDrawerDispatch } from 'context/DrawerContext';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { AiOutlineWeibo } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { DateFormat } from 'settings/common';
import { PRODUCT_TRANSFER_REQUEST } from 'settings/constants';

export const GET_PRODUCT_TRANSFER_LIST = gql`
query($query: QueryParams!) {
  stockQuery {
    stockTransferList(query: $query) {
      page
      pageSize
      totalCount
      items {
        id
        createDate
        status
        title
      }
    }
  }
}
`;

type InitialStateType = {
  requestProducts: [];
  transferData: [];
  toggleModal: boolean;
  productId: number;
}

const initialState: InitialStateType = {
  requestProducts: [],
  transferData: [],
  toggleModal: false,
  productId: 0
}

enum ActionType {
  SET_REQUEST_PRODUCTS,
  SET_TRANSFER_DATA,
  SET_TOGGLE_MODAL,
  SET_PRODUCT_ID
}

const stateReducer = (state: InitialStateType, action: { type: ActionType, payload: any }) => {
  switch (action.type) {
    case ActionType.SET_REQUEST_PRODUCTS:

      return {
        ...state,
        requestProducts: action.payload
      }
    case ActionType.SET_TRANSFER_DATA:

      return {
        ...state,
        transferData: action.payload
      }
    case ActionType.SET_TOGGLE_MODAL:

      return {
        ...state,
        toggleModal: action.payload
      }
    case ActionType.SET_PRODUCT_ID:

      return {
        ...state,
        productId: action.payload
      }

    default:
      return initialState;
  }

}
const TABLE_PAGE_SIZE = 10;
const ReceiveProduct = () => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const [paginationData, setPaginationData] = useState({ totalCount: 0, page: 1, pageSize: TABLE_PAGE_SIZE })
  const dispatchDrawer = useDrawerDispatch();
  const history = useHistory();

  /* 
   * State Start
  */

  const {
    requestProducts,
    transferData,
    toggleModal,
    productId

  }: InitialStateType = state

  const [loadProductTransfer, { data: products, loading, refetch }] = useLazyQuery(GET_PRODUCT_TRANSFER_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setPaginationData(data && data.stockQuery && data.stockQuery.stockTransferList)
      let response = data && data.stockQuery && data.stockQuery.stockTransferList && data.stockQuery.stockTransferList.items.map((a: any) => ({
        id: a.id,
        title: a.title || "---",
        createDate: moment(a.createDate).format("LL"),
        status: a.status || "---",
      }))
      dispatch({ type: ActionType.SET_REQUEST_PRODUCTS, payload: response })
    },
    onError: (error) => {
      console.log(error.message)
    }
  });

  useEffect(() => {
    loadProductTransfer({
      variables: {
        query: {
          orderKey: "id",
          pageSize: TABLE_PAGE_SIZE,
          assending: false,
          params: [
            {
              key: "onlyTypes",
              value: "[7]"
            }
          ]
        }
      }
    })
  }, [])
  /* 
  * GraphQL API End
  */

  const openDetails = useCallback(
    (id) => dispatchDrawer({ type: 'OPEN_DRAWER', drawerComponent: 'VIEW_TRANSFER_REQUEST', props: { id: id, "isConfirm": false, refetch: false }, data: { closeable: true } }),
    [dispatch]
  );
  const openCode = useCallback(
    () => dispatchDrawer({ type: 'OPEN_DRAWER', drawerComponent: 'VIEW_TRANSFER_REQUEST', props: { id: 0, "isConfirm": true, refetch: refetch }, data: { closeable: true } }),
    [dispatch]
  );
  const columns = useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code'
      },
      {
        Header: 'Create Date',
        accessor: 'createDate',
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        disableFilters: true
      }
    ],
    []
  );

  const table = useMemo(
    () => {
      let data = []
      if (requestProducts && requestProducts.length > 0) {
        data = requestProducts.map((c: any) => ({
          code: c.id,
          createDate: DateFormat.format(new Date(c.createDate)),
          status: c.status,
          actions: <div style={{ fontSize: "large", justifyContent: 'center' }}>
            <AiOutlineWeibo title="View" onClick={() => openDetails(c.id)} />
          </div>
        }));
      }
      // return <CustomDataTable columns={columns} data={data} loading={loading} />
      return <ManualDataTable
        columns={columns}
        data={data}
        loading={loading}
        totalCount={paginationData.totalCount}
        fetchData={fetchData}
        page={paginationData.page}
        pageSize={paginationData.pageSize} />
    },
    [requestProducts]
  );
  function fetchData(pageSize = TABLE_PAGE_SIZE, page = 1, sortBy = "id", ascending = false, filterKey = null, filterValue = null) {
    loadProductTransfer({
      variables: {
        query: {
          "page": page,
          "pageSize": pageSize,
          "orderKey": sortBy,
          "assending": false,
          "filter": filterValue,
          params: [
            {
              key: "onlyTypes",
              value: "[7]"
            }
          ]
        }
      }
    })
  }

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 40,
              boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
            }}
          >
            <Col md={4} xs={12}>
              <Heading>Product Transfer</Heading>
            </Col>
            <Col md={8} xs={12}>
              <Row>
                <Col md={5} xs={12}>

                </Col>
                <Col md={3} xs={12}>
                  <Button
                    onClick={() => openCode()}
                    // startEnhancer={() => <Plus />}
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: '90%',
                          borderTopLeftRadius: '3px',
                          borderTopRightRadius: '3px',
                          borderBottomLeftRadius: '3px',
                          borderBottomRightRadius: '3px',
                        }),
                      },
                    }}
                  >
                    Code
                  </Button>
                </Col>
                <Col md={4} xs={12}>
                  <Button
                    onClick={() => history.push(PRODUCT_TRANSFER_REQUEST)}
                    startEnhancer={() => <Plus />}
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: '100%',
                          borderTopLeftRadius: '3px',
                          borderTopRightRadius: '3px',
                          borderBottomLeftRadius: '3px',
                          borderBottomRightRadius: '3px',
                        }),
                      },
                    }}
                  >
                    New Request
                  </Button>
                </Col>
              </Row>
            </Col>
          </Header>
          <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
            {table}
          </Wrapper>
        </Col>
      </Row>
    </Grid>
  )
}

export default ReceiveProduct;
