import React, { useEffect, useMemo, useState } from 'react';
import { withStyle } from 'baseui';
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox';
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import Input from 'components/Input/Input';
import TransactionTable from "../../components/DataTable/TransactionTable";
import Button from 'components/Button/Button';
import { Header, Heading } from 'components/Wrapper.style';
import { Form } from 'containers/DrawerItems/DrawerItems.style';
import { from, gql, useLazyQuery, useQuery } from '@apollo/client';
import useCustomSnackBar from 'hooks/useCustomSnackBar';
import DrawerBox from "../../components/DrawerBox/DrawerBox";
import { Link, useParams, useLocation } from "react-router-dom";
import { StyledSpinnerNext as Spinner } from "baseui/spinner";
import { Card } from "../../components/Widgets/StickerCard/StickerCard.style";
import { StyledHeadCell, StyledTable } from "baseui/table-grid";
import { AiFillPrinter } from "react-icons/ai";
import { Table } from "baseui/table-semantic";
import { AddDays } from "../../settings/common";
import { ManufacturingOverhead, WorkInProcess } from "../../settings/accountType";
import JournalType from "../../settings/journalType";
import { SHAPE, SIZE } from "baseui/button";
import { DatePicker } from "baseui/datepicker";
import { Select } from "baseui/select";
import AccountDetailsHeader from 'components/AccountHeader/AccountDetailsHeader';
import { StyledRow } from 'baseui/table';
import { SmallCell } from 'components/DataTable/Datatable.style';


const QUERY = gql`query($query: QueryParams!){
    journalQuery{
        journal(query: $query){
            baseAccountType
            journalViewModel{
                account{
                    id
                    name
                    createDate
                }
                openingBalance{
                    credit
                    debit
                }
                journals{
                    amount
                    type
                    voucherDate
                    voucherNo
                    accountId
                    comments
                    debit
                    credit
                    isOpeningBalance
                    account{
                        name
                    }
                    salesInvoice{
                        invoiceCode
                    }
                    account{
                        action
                        name
                        openingBalanceJournal{
                            debit
                            credit
                        }
                    }
                }
                isDetail
                isSpecial
            }
        }
    }
}`;

const ACCOUNT_QUERY = gql`query($query: QueryParams!){
    accountQuery{
        accounts(query: $query){
            id
            willHaveChild
            name
        }
    }
}`;

const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',
        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));

const fromDate = new Date();
fromDate.setHours(0);
fromDate.setMinutes(0);
fromDate.setSeconds(0);

const toDate = new Date();
toDate.setHours(23);
toDate.setMinutes(59);
toDate.setSeconds(59);

const convt1 = new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" });
const convt2 = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" });

type Props = any;
const ExpenseDetails: React.FC<Props> = () => {
    const params: any = useParams();
    const [fromDateInitial, setFromDateInitial] = useState(new Date());
    let header = [], rows = [];

    const printStatement = (e) => {
        alert("Print");
    }

    let journalModel = null;

    const [loadJournals, { loading, data }] = useLazyQuery(QUERY,
        {
            fetchPolicy: "network-only"
        });

    const { data: accounts } = useQuery(ACCOUNT_QUERY, {
        variables: {
            query: {
                params: [
                    {
                        key: "filters",
                        value: "[\"head office bank\",\"head office supplier\",\"head office client\",\"Head Office Operating Expense\", \"Head Office Cash\", \"Head Office Sales\"]"
                    }
                ]
            }
        }
    });

    const accountOptions = useMemo(() => {
        let options = [];
        if (accounts && accounts.accountQuery && accounts.accountQuery.accounts) {
            options = accounts.accountQuery.accounts.map(a => ({ label: a.name, value: a.id }))
        }
        return options;
    }, [accounts]);

    const _getAmountForCell = (a, type) => {
        return a.isOpeningBalance
            ? (a.type === type
                ? a.amount.toMoney()
                : "---")
            : a.type === type
                ? "---"
                : a.amount.toMoney();
    }

    const _generateRow = (a, convt1, convt2, initBalance, endingBalance, isSpecial) => {
        const voucherDt = a.voucherDate && new Date(a.voucherDate);
        const minDate = new Date(1, 1, 1);
        const jvnInv = (a.account.name === "Sales" && a.salesInvoice.invoiceCode
            ? (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}<br />{`${a.salesInvoice.invoiceCode}`}</div>)
            : (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}</div>));
        if (isSpecial) {
            return [
                a.account.name,
                // op > 0 ? op.toMoney() : "0.00",
                // op < 0 ? op.toMoney() : "0.00",
                a.debit.toMoney(),
                a.credit.toMoney(),
                endingBalance > 0 ? endingBalance.toMoney() : "0.00",
                endingBalance < 0 ? endingBalance.toMoney() : "0.00"
            ];

        } else {
            // let op = a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit;
            return [
                voucherDt && voucherDt.getTime() > minDate.getTime() ? convt1.format(voucherDt) : "",
                // a.isOpeningBalance
                //     ? "(Opening Balance)":
                //     a.account.name,
                //Math.abs(initBalance).toMoney() + " " + (initBalance >= 0 ? "Dr" : "Cr"),
                initBalance.toMoney(),
                // op > 0 ? op.toMoney() : "0.00",
                // _getAmountForCell(a, JournalType.Credit),
                _getAmountForCell(a, JournalType.Debit),
                a.comments || "No Comments",
                // a.voucherNo ? jvnInv : ""
            ];
        }
    }

    const generateRows = (journalModel) => {
        journalModel = { ...journalModel };
        if (journalModel.journals) {
            journalModel.journals = [...journalModel.journals];
        }

        let lastBalance, manufecturings,
            endingBalance = journalModel.openingBalance ? journalModel.openingBalance.debit - journalModel.openingBalance.credit : 0;

        if (journalModel.isDetail && journalModel.account && journalModel.account.id === WorkInProcess) {
            manufecturings = journalModel.journals.filter(j => j.account.parentId === ManufacturingOverhead);
            journalModel.journals = journalModel.journals.filter(j => j.account.parentId !== ManufacturingOverhead);
        }

        const rows = (journalModel.journals || []).map((a, i) => {
            const voucherDt = a.voucherDate && new Date(a.voucherDate);

            if (!journalModel.isDetail) {
                if (!lastBalance) {
                    lastBalance = journalModel.openingBalance.debit - journalModel.openingBalance.credit;
                }
                return [
                    voucherDt ? convt1.format(voucherDt) : "",
                    "Balance",
                    lastBalance.toMoney() + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                    a.debit.toMoney(),
                    a.credit.toMoney(),
                    (lastBalance = ((a.debit - a.credit) + lastBalance)).toMoney() + " " + (a.debit >= a.credit ? "Dr" : "Cr")
                ];
            }

            let initBalance = endingBalance;
            if (journalModel.isSpecial) {
                endingBalance = (a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit) + (a.debit - a.credit);
            } else {
                endingBalance += a.isOpeningBalance
                    ? a.type === JournalType.Debit
                        ? a.amount
                        : -1 * a.amount
                    : a.type === JournalType.Credit
                        ? a.amount
                        : -1 * a.amount;
            }
            return _generateRow(a, convt1, convt2, initBalance, endingBalance, journalModel.isSpecial);
        });

        if (manufecturings && manufecturings.length > 0) {
            rows.push([{ data: "Manufacturing Overhead", style: { textDecoration: "underline" } }, "", "", "", "", ""]);

            const grouped = Object.values((manufecturings).groupBy("accountId"));

            grouped.forEach((a: any) => {
                const dr = a.filter(b => b.type === JournalType.Debit).map(c => c.amount).reduce((n, m) => n + m, 0);
                const cr = a.filter(b => b.type === JournalType.Credit).map(c => c.amount).reduce((n, m) => n + m, 0);
                const total = cr - dr;
                rows.push([
                    convt1.format(new Date(a[0].voucherDate)),
                    (<Link
                        title="Click to see details"
                        to={`/accounting/details/${a[0].account.name.toSeoUrl()}-${a[0].accountId}`}>
                        {a[0].account.name}
                    </Link>),
                    "",
                    cr >= dr ? total.toMoney() : "---",
                    dr > cr ? total.toMoney() : "---",
                    "---",
                    "---"
                ]);
            });

            manufecturings.forEach(a => journalModel.journals.push(a));
        }

        if (rows.length === 0) {
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
        }

        let openingBalance = journalModel.openingBalance;
        // if (journalModel.isDetail && openingBalance) {
        //     rows.splice(0, 0, {
        //         style: { backgroundColor: (openingBalance.debit - openingBalance.credit)<0 ? "#f7b9b5" :"#dff0d8" },
        //         data: [
        //             {
        //                 style: { fontWeight: "bold" },
        //                 data: "Initial Balance"
        //             }
        //             ,
        //             // `From ${convt1.format(AddDays(fromDateInitial, -1))}`,
        //             // "৳ "+(openingBalance.debit - openingBalance.credit).toMoney()
        //             "৳ " + sumOfDr.toMoney()
        //         ]
        //     });
        // }

        if (!journalModel.isDetail) {
            rows.push({
                style: { backgroundColor: "#dff0d8" },
                data: [
                    "",
                    {
                        style: { textAlign: "right", fontWeight: "bold" },
                        data: "Total"
                    },
                    (lastBalance || 0).toMoney() + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                    "",
                    "",
                    ""
                ]
            });
            return rows;
        }

        if (journalModel.isSpecial) {

            let sumOfOpDr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.debit - j.account.openingBalanceJournal.credit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            let sumOfOpCr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.credit - j.account.openingBalanceJournal.debit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            let sumOfDr = journalModel.journals
                .map(j => j.debit)
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .map(j => j.credit)
                .reduce((a, b) => a + b, 0);

            let endDr = sumOfOpDr + sumOfDr;
            let endCr = sumOfOpCr + sumOfCr;

            let row = {
                style: { backgroundColor: "#dff0d8", fontWeight: "500" },
                data: [
                    "Total: ",
                    sumOfOpDr.toMoney(),
                    sumOfOpCr.toMoney(),
                    `৳ ${sumOfDr.toMoney()}`,
                    `৳ ${sumOfCr.toMoney()}`,
                    endDr.toMoney(),
                    endCr.toMoney()
                ]
            };
            rows.push(row);
        } else if (journalModel.journals) { // total balance calculation

            let sumOfDr = journalModel.journals
                .filter(a => journalModel.isDetail
                    ? a.isOpeningBalance
                        ? a.type === JournalType.Debit
                        : a.type === JournalType.Credit
                    : a.amount < 0)
                .map(j => j.amount)
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .filter(a => journalModel.isDetail ? a.isOpeningBalance ? a.type === JournalType.Credit : a.type === JournalType.Debit : a.amount > 0)
                .map(j => j.amount)
                .reduce((a, b) => a + b, 0);

            // let row = {
            //     style: { backgroundColor: "#faf2cc" },
            //     data: [
            //         {
            //             style: { textAlign: "right", fontWeight: "bold" },
            //             data: "Total Cash In"
            //         },
            //         "",
            //         "৳ " + sumOfDr.toMoney(),
            //     ]
            // };
            // rows.push(row);
            // let row2 = {
            //     style: { backgroundColor: "#faf2cc" },
            //     data: [
            //         {
            //             style: { textAlign: "right", fontWeight: "bold" },
            //             data: "Total Cash Out"
            //         },
            //         "",
            //         "৳ " + sumOfCr.toMoney(),
            //     ]
            // };
            // rows.push(row2);


            if (openingBalance) {
                sumOfCr += openingBalance.credit;
                sumOfDr += openingBalance.debit;
            }

            let totalBalance = sumOfDr - sumOfCr;

            rows.push({
                style: { backgroundColor: totalBalance<0 ? "#f7b9b5" :"#dff0d8" },
                data: [
                    {
                        style: { textAlign: "right", fontWeight: "bold" },
                        data: "Total Expense"
                    },
                    // sumOfDr >= sumOfCr ? "৳ " + totalBalance.toMoney() : "",
                    // sumOfCr > sumOfDr ? "৳ " + totalBalance.toMoney() : "",
                    "",
                    "৳ " + sumOfDr.toMoney()
                ]
            });

        }
        return rows;
    }
    if (data && data.journalQuery && data.journalQuery.journal && data.journalQuery.journal.journalViewModel) {
        journalModel = data.journalQuery.journal.journalViewModel;
        header = !journalModel.isSpecial
            ? [
                "Date of Transaction",
                "Initial Expense",
                "Current Expense",
                "Note"
            ]
            : [
                "Account Against",
                "Opening(Debit)",
                "Opening(Credit)",
                "Debit",
                "Credit",
                "Ending(Debit)",
                "Ending(Credit)"
            ];

        // if (!journalModel.isSpecial) {
        //     //if (journalModel.isDetail) {
        //     //    header.splice(2, 0, "Opening Balance");
        //     //}
        //     if (journalModel.isDetail) {
        //         header.splice(4, 0, "Balance");
        //     }
        // }

        rows = generateRows(journalModel);
    }

    function searchJournals(fromDate, toDate) {
        let accId = 0;
        let id = params.id;
        if (id) {
            id = id.split("-");
            id = id[id.length - 1];
            id = atob(id);
            accId = id;
        }
        loadJournals({
            variables: {
                query: {
                    params: [
                        {
                            key: "accountId",
                            value: accId + ""
                        },
                        {
                            key: "isDetail",
                            value: "true"
                        },
                        {
                            key: "fromDate",
                            value: JSON.stringify(fromDate)
                        },
                        {
                            key: "toDate",
                            value: JSON.stringify(toDate)
                        }
                    ]
                }
            }
        });
    }

    return (
        <>
            <AccountDetailsHeader setFromDateInitial={setFromDateInitial} searchJournals={searchJournals} />
            <Row style={{alignItems: 'flex-start'}}>
                <Col md={8}>
                    <Card>
                        <h4 style={{ paddingLeft: 25 }}>
                            {journalModel && journalModel.account && journalModel.account.name}
                            {/* {journalModel && typeof journalModel.account !== "undefined" ? `(${convt1.format(new Date(journalModel.account.createDate))})` : null} */}
                        </h4>
                        {/*<div ref={a => this.printArea = a}>*/}
                        <div>
                            <TransactionTable
                                accountID={20}
                                columns={header}
                                data={rows}
                                loading={loading}
                            />
                        </div>
                    </Card>
                </Col>
                <Col md={4} >
                    <Card>
                        {
                            rows?.map(row => {
                                // Prepare the row for display
                                if (row.style) {
                                    return <StyledRow style={row.style}>
                                        {// Loop over the rows cells
                                            row.data.map(cell => {
                                                // Apply the cell props
                                                if (cell.data) {
                                                    return <SmallCell style={cell.style}>
                                                        {cell.data}
                                                    </SmallCell>
                                                }
                                                return (
                                                    <SmallCell>
                                                        {cell}
                                                    </SmallCell>
                                                )
                                            })}
                                    </StyledRow>
                                }
                            })
                        }
                    </Card>
                </Col>
                <Col md={2}>
                    <Button shape={SHAPE.pill} onClick={printStatement}><AiFillPrinter /></Button>
                </Col>
            </Row>
        </>
    );
}
export default ExpenseDetails;
// export default function RideCheckr (){
//   let history = useHistory();
// //get Products

//   return (
//     <div>
//         <h1>
//           That is Ride Checker
//         </h1>
//     </div>
//   );
// }

