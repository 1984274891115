import React from 'react';
import { withStyle } from 'baseui';
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox';
import { useDrawerDispatch } from 'context/DrawerContext';
import { useQuery, gql } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import CustomDataTable from 'components/DataTable/CustomDataTable';
import { Link, useHistory } from 'react-router-dom';

const QUERY = gql`query($query: QueryParams!){
    accountQuery{
        accounts(query: $query){
            id
            willHaveChild
            name
            childs{
                id
                name
                openingBalance
                orgUnit{
                    id
                    name
                }
                contact{
                    notes
                    place
                    number
                }
            }
        }
    }
}`;

const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',

        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));

export default function BankReport() {
    const dispatch = useDrawerDispatch();
    const history = useHistory();
    const { loading, data: banks, refetch } = useQuery(QUERY, {
        fetchPolicy:'network-only',
        variables: {
            query: {
                params: [
                    {
                        key: "forContact",
                        value: "true"
                    },
                    {
                        key: "filter",
                        value: "bank"
                    }
                ]
            }
        }
    })

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: "Current Amount (BDT)",
                accessor: 'balance'
            },
            {
                Header: 'Branch',
                accessor: 'branch'
            }
        ],
        []
    );

    const table = React.useMemo(
        () => {
            let data = []
            if (banks && banks.accountQuery && banks.accountQuery.accounts && banks.accountQuery.accounts[0].childs) {
                const children = banks.accountQuery.accounts.reduce((acc, elem) => acc.concat(elem.childs), [])
                data = children.filter(c => c.openingBalance > 0).map(c => ({
                    //name: c.name,
                    name: <Link
                        title="Click to see details"
                        to={`/accounting/report/details/${c.name.toSeoUrl()}-${btoa(c.id)}`}>
                        {c.name}
                    </Link>,
                    balance: c.openingBalance.toMoney(false),
                    branch: c.orgUnit?.name
                }));
            }
            return <CustomDataTable columns={columns} data={data} loading={loading} title={"Bank Report"} canPrint={true} />
        },
        [banks]
    );

    return (
        <Grid fluid={true}>
            <Row>
                <Col md={12}>
                    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                        {table}
                    </Wrapper>
                </Col>
            </Row>
        </Grid>
    )
}
