import { gql } from "@apollo/client";

// queries
const GET_CLIENTS = gql`
    query($query: QueryParams!){
        accountQuery{
            accounts(query: $query){
                id
                willHaveChild
                name
                childs{
                    id
                    name
                    openingBalance
                    contact{
                        notes
                        place
                        number
                    }
                }
            }
        }
    }
`;

export { GET_CLIENTS }