import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';

export default function ConfirmationModal({ isOpen, modalBody, modalHeader, closeCallback, cancelCallBack, submitCallback, loading = false }) {

    return (
        <Modal onClose={closeCallback} isOpen={isOpen}>
            <ModalHeader>{modalHeader}</ModalHeader>
            <ModalBody>
                {modalBody}
            </ModalBody>
            <ModalFooter>
                <ModalButton kind="tertiary" onClick={cancelCallBack}>
                    Cancel
                </ModalButton>
                <ModalButton onClick={submitCallback} disabled={loading}>{loading ? "Loading..." : "Yes"}</ModalButton>
            </ModalFooter>
        </Modal>
    )
}