import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button } from 'baseui/button';
import { AuditStatus } from './Model';
import Checkbox from 'components/CheckBox/CheckBox';
import { COMPLETE_AUDIT, GET_INVENTORY_AUDITS } from './AuditQuery';
import { Col, Row } from 'components/FlexBox/FlexBox';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { useHistory } from 'react-router-dom';
import { INVENTORY_AUDIT } from 'settings/constants';
import { CURRENCY } from "../../../settings/constants";

export default function CompleteAudit(props) {
    const { open, setOpen, auditId, handleCloseInventory } = props;
    const [auditProducts, setAuditProducts] = useState([]);
    const enqueue = useCustomSnackBar();
    let history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const [getInvAudits, { loading: invAuditsLoading, refetch }] = useLazyQuery(GET_INVENTORY_AUDITS, {
        onCompleted: (data) => {
            let res = data && data.query && data.query.result;
            if (res && res.length > 0) {
                setAuditProducts(res.filter((a) => [AuditStatus.Running, AuditStatus.Started].includes(a.status)).map((audit) => ({
                    id: audit.id,
                    inventoryAmount: audit.inventoryAmount,
                    systemAmount: audit.systemAmount,
                    productName: audit.productName,
                    checked: false,
                    price: audit.price,
                    variationName: audit.product.alias
                })))
            }
        },
        onError: (e) => {
            console.log(e.message)
        },
    });

    const [completeAudit, { loading: submitting }] = useMutation(COMPLETE_AUDIT, {
        onCompleted: (data) => {
            if (data && data.mutate.result && data.mutate.result.success) {
                refetch();
                handleClose();
                handleCloseInventory();
                enqueue("success", "Audit Completed Successfully");

                history.push(INVENTORY_AUDIT)

            } else {
                enqueue("error", "Something went wrong");
            }
        },
        onError: (e) => {
            enqueue("error", "Something went wrong");
        }
    })


    const getInvAuditList = () => {
        getInvAudits({
            variables: {
                query: {
                    params: [
                        {
                            key: "auditId",
                            value: auditId
                        },
                        {
                            key: "showVariation",
                            value: "true"
                        }
                    ]
                }
            }
        })
    }

    useEffect(() => {
        getInvAuditList()
    }, []);

    const selectAll = () => {
        setAuditProducts((st) => {
            let newSt = [...st]
            newSt.forEach((item) => item.checked = true);
            return [...newSt]
        })
    }

    const unSelectAll = () => {
        setAuditProducts((st) => {
            let newSt = [...st]
            newSt.forEach((item) => item.checked = false);
            return [...newSt]
        })
    }
    const handleCheckItem = (itemId) => {
        var idx = auditProducts.findIndex((item) => item.id == itemId);
        setAuditProducts((st) => {
            let newSt = [...st]
            newSt[idx].checked = !newSt[idx].checked
            return [...newSt]
        })
    }
    const handleAuditCompleted = () => {
        const items = auditProducts.filter(x => x.checked).map((audit) => (audit.id));
        completeAudit({
            variables: {
                data: items,
                isStockItemPriceArg: true
            }
        })
    }

    return (
        <Modal isOpen={open} onClose={handleClose}>
            <ModalHeader>Complete Audit</ModalHeader>
            <ModalBody sx={{ overflow: "visible", minHeight: "50vh", minWidth: '59.7vh' }}>
                <Row spacing={3} justifyContent="center">
                    <Col md={12}>
                        {
                            auditProducts.length <= 0 ?
                                <Row>
                                    <Col md={12} textAlign='center'>
                                        <h3>No data found!</h3>
                                    </Col>
                                </Row> :
                                <span>
                                    <Row>
                                        <Col md={2}>
                                            <Checkbox
                                                checked={auditProducts.every(product => product.checked == true) ? true : false}
                                                onChange={() => {
                                                    auditProducts.every(product => product.checked == true) ? unSelectAll() : selectAll()
                                                }}
                                            />
                                        </Col>
                                        <Col md={9}>
                                            <p style={{ marginTop: 5, fontSize: 16 }}>
                                                <b>{auditProducts.every(product => product.checked == true) ? "Unselect All" : "Select All"}</b>
                                            </p>
                                        </Col>
                                    </Row>
                                    <div>
                                        {auditProducts &&
                                            auditProducts.map((item, index) => {
                                                return (
                                                    <span>
                                                        <Row style={{ backgroundColor: '#F0EEED' }}>
                                                            <Col md={1} style={{ marginTop: 15 }}>
                                                                <Checkbox
                                                                    checked={item.checked ? true : false}
                                                                    onChange={() => {
                                                                        handleCheckItem(item.id)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col md={10} style={{ marginTop: -15, marginLeft: 10 }}>
                                                                {/* <h5 style={{ color: 'green', fontSize: 20 }}><b>{item.productName + " " + item.variationName }</b></h5> */}
                                                                <h5 style={{ color: 'green', fontSize: 20 }}><b>{item.productName}  <span>{item.variationName?.length > 0 && `(${item.variationName})`}</span> </b></h5>
                                                                <h5 style={{ fontSize: 15, marginTop: -15 }}><b> Current Quantity: {item.inventoryAmount}</b></h5>
                                                                <h5 style={{ fontSize: 15, marginTop: -15 }}><b> Unit Price: {CURRENCY} {item.price}</b></h5>
                                                            </Col>
                                                        </Row>

                                                        <br />
                                                    </span>
                                                );
                                            })}
                                    </div>
                                </span>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Row style={{ justifyContent: 'end', marginBottom: 20, marginRight: 5, marginTop: 0 }}>
                    <Col>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            onClick={() => {
                                auditProducts.some(product => product.checked == true) && handleAuditCompleted()
                            }}
                            disabled={auditProducts.every(product => product.checked == false)}
                        >Save</Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )
}
