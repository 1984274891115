import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from 'components/FlexBox/FlexBox';
import { useDrawerDispatch } from 'context/DrawerContext';
import { Plus } from 'assets/icons/PlusMinus';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import { DateFormat } from "../../../settings/common";
import { Col, Row } from "./Order.style"
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { useHistory } from 'react-router-dom';
import { ADD_ORDER } from "../../../settings/constants";
import { AiOutlineWeibo } from 'react-icons/ai';
import { FaListAlt } from 'react-icons/fa';
import { ImNewTab } from 'react-icons/im';
import DrawerBox from "../../../components/DrawerBox/DrawerBox";
import ManualDataTable from 'components/DataTable/ManualDataTable';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import Input from "../../../components/Input/Input";
import { Button, KIND as ButtonKind, SHAPE, SIZE as ButtonSize, KIND } from "baseui/button";



const ORDER_INVOICE_QUERY = gql`query($query: QueryParams!, $invoiceCode: String) {
    salesQuery {
      orderInvoiceList(query: $query, invoiceCode:$invoiceCode) {
        page
        pageSize
        totalCount
        items {
          id
          invoiceCode
          status
          salesDate
          total
          orderStatus
          trackingNumber
          note
          orgUnit {
            name
          }
          vat
          discount
          client {
            name
          }
          invoiceItems {
            quantity
            price
          }
          salesInvoiceAdditionalInfos{
            key
            value
          }
        }
      }
    }
  }`;

const ADD_TRACK_NUMBER = gql`
mutation($inputArgs: [SalesInvoiceAdditionalInfoInput]!) {
    sales {
      addOrUpdateInvoiceAdditionalInfo(additonalInfoArgs: $inputArgs) {
        success
      }
    }
  }
  
`

const TABLE_PAGE_SIZE = 10;

export default function OrderInvoice() {
    const dispatch = useDrawerDispatch();
    const history = useHistory();
    const enqueue = useCustomSnackBar();
    const [salesDate, setSalesDate] = React.useState([new Date()]);
    const [open, setOpen] = useState(false)
    const [trackNo, setTrackNo] = useState("")
    const [orderInvoiceId, setOrderInvoiceId] = useState(null)

    useEffect(() => {
        loadOrdersInvoice({
            variables: {
                "query": {
                    "page": 1,
                    "pageSize": TABLE_PAGE_SIZE,
                    "orderKey": "Id",
                    "assending": false,

                },
                "invoiceCode": null
            }
        })
    }, [])

    const [loadOrdersInvoice, { loading, data, refetch: orderInvoiceRefetch }] = useLazyQuery(ORDER_INVOICE_QUERY, {
        fetchPolicy: "network-only",
        onError: (dt) => {
            console.log(dt.message)
        }
    });

    const [addOrderMutation, { loading: mutateLoading }] = useMutation(ADD_TRACK_NUMBER, {
        onCompleted: (data) => {
            if (data.sales.addOrUpdateInvoiceAdditionalInfo.success) {
                enqueue("success", "Track Number SuccessFully Added");
                setOpen(false);
                orderInvoiceRefetch();
            }
        },

        onError: (e) => {
            enqueue("error", "Track Number Couldn't be added, please try again later");
        }
    });

    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'invoiceCode',// accessor is the "key" in the data
            },
            // {
            //     Header: 'Customer Name',
            //     accessor: 'name',
            //     disableSortBy: true,
            // },
            {
                Header: 'Sales Date',
                accessor: 'salesDate',
                disableFilters: true
            },
            {
                Header: 'Grand Total (৳)',
                accessor: 'total',
                disableFilters: true
            },
            {
                Header: 'Track Number',
                accessor: 'trackNo',
                disableFilters: true
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableFilters: true
            },
            {
                Header: 'Order Completed',
                accessor: 'orderCompleted',
                disableFilters: true
            },
            {
                Header: 'Note',
                accessor: 'note',
                disableFilters: true
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                disableSortBy: true,
                disableFilters: true
            }
        ],
        []
    );

    const openDetails = useCallback(
        (id) => dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'ORDER_DETAILS', props: { id: id }, data: { closeable: true } }),
        [dispatch]
    );

    const getDataFormat = (dataSet, key) => {
        const targetRow = dataSet && dataSet.find((a) => a.key == key);
        if (targetRow) {
            return targetRow.value;
        }
        else {
            return "";
        }
    }

    const table = React.useMemo(
        () => {
            let salesData = []
            let sData = { totalCount: 0, page: 1, pageSize: TABLE_PAGE_SIZE };
            if (data?.salesQuery?.orderInvoiceList?.items) {
                sData = data?.salesQuery?.orderInvoiceList;
                const additionalInfo = data?.salesQuery?.orderInvoiceList?.items?.map(a => a.salesInvoiceAdditionalInfos);
                salesData = data?.salesQuery?.orderInvoiceList?.items.map((s, i) => {
                    return {
                        invoiceCode: s.invoiceCode,
                        total: (s.total - s.discount + s.vat).toMoney(),
                        salesDate: DateFormat.format(new Date(s.salesDate)),
                        name: s.client?.name,
                        status: s.status,
                        trackNo: getDataFormat(additionalInfo && additionalInfo[i], "PATHAO-PARCEL-TRACK-NO"),
                        note: s.note,
                        orderCompleted:`${parseFloat(s.orderStatus).toFixed(2)} ${'%'}`,
                        actions: <div style={{ fontSize: "large", justifyContent: 'center' }}>
                            <AiOutlineWeibo title="View" onClick={() => openDetails(s.id)} />
                            <ImNewTab style={{ marginLeft: 8 }} title="Add track no" onClick={() => openUrl(s.id)} />
                            <FaListAlt style={{ marginLeft: 8 }} title="Track no" onClick={() => openModal(s.id)} />
                        </div>
                    }
                });
            }
            return <ManualDataTable columns={columns}
                data={salesData}
                loading={loading}
                totalCount={sData.totalCount}
                fetchData={fetchOrderInvoice}
                page={sData.page}
                pageSize={sData.pageSize} />
        },
        [data]
    );

    function fetchOrderInvoice(pageSize = TABLE_PAGE_SIZE, page = 1, sortBy = "id", ascending = false, filterKey = null, filterValue = null) {
        loadOrdersInvoice({
            variables: {
                "query": {
                    "page": page,
                    "pageSize": TABLE_PAGE_SIZE,
                    "orderKey": "Id",
                    "assending": false,
                },
                "invoiceCode": filterValue
            }
        });
    }


    const handleSubmit = () => {
        addOrderMutation({
            variables: {
                inputArgs: [
                    {
                        id: 0,
                        salesInvoiceId: orderInvoiceId,
                        key: "PATHAO-PARCEL-TRACK-NO",
                        value: trackNo
                    }
                ]
            }
        })
    }

    const handleClose = () => {
        setOpen(false);
    }

    function openUrl(Id) {
        let invUrl = `https://pathao.com/parcel/`;
        window.open(invUrl, '_blank');
        setOpen(true);
        setOrderInvoiceId(Id)

    }

    const openModal = (id) => {
        setOrderInvoiceId(id)
        setOpen(true)
    }
    return (
        <Grid fluid={true}>
            <DrawerBox>
                <Row>
                    <Col md={12}>
                        <Header

                            style={{
                                marginBottom: 40,
                                boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                            }}
                        >
                            <Col md={4} xs={12}>
                                <Heading>Order Invoice</Heading>
                            </Col>

                            <Col md={8} xs={12}>
                                <Row>
                                    <Col md={8} xs={12}>
                                    </Col>

                                    <Col md={4} xs={12}>
                                        <Button
                                            onClick={() => history.push(ADD_ORDER)}
                                            startEnhancer={() => <Plus />}
                                            overrides={{
                                                BaseButton: {
                                                    style: () => ({
                                                        width: '100%',
                                                        borderTopLeftRadius: '3px',
                                                        borderTopRightRadius: '3px',
                                                        borderBottomLeftRadius: '3px',
                                                        borderBottomRightRadius: '3px',
                                                    }),
                                                },
                                            }}
                                        >
                                            Add Order
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Header>
                        <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                            {table}
                        </Wrapper>
                    </Col>
                </Row>

                <Modal isOpen={open} onClose={handleClose}>
                    <ModalHeader>Add Track Number</ModalHeader>
                    <ModalBody sx={{ overflow: "visible", minHeight: "50vh", minWidth: '59.7vh' }}>
                        <Row spacing={3} justifyContent="center">
                            <Col md={12}>
                                {
                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                onChange={(e) => { setTrackNo(e.target.value) }}
                                            />
                                        </Col>
                                        <Col md={9}>
                                            <p style={{ marginTop: 5, fontSize: 16 }}>
                                            </p>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{ justifyContent: 'end', marginBottom: 20, marginRight: 2, marginTop: 0 }}>
                            <Col>
                                <Button
                                    kind={KIND.minimal}
                                    type="button"
                                    onClick={handleClose}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                                marginRight: '15px',
                                                color: $theme.colors.red400,
                                            }),
                                        },
                                    }}
                                >
                                    Cancel
                                </Button>

                            </Col>

                            <Col>
                                <Button
                                    onClick={() => handleSubmit()}
                                >Save</Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </DrawerBox>
        </Grid >
    );
}
