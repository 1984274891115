import React, { useCallback, useState } from 'react';
import { Grid } from 'components/FlexBox/FlexBox';
import { useDrawerDispatch } from 'context/DrawerContext';
import Button from 'components/Button/Button';
import { Plus } from 'assets/icons/PlusMinus';
import { useQuery } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import CustomDataTable from "../../../components/DataTable/CustomDataTable";
import { Col, Row } from "./Audit.style";
import { AuditStatus } from '../../../containers/Inventory/Audit/Model'
import { AiOutlineWeibo } from "react-icons/ai";
import { FaListAlt } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { GET_AUDIT_LIST } from './AuditQuery';
import { useHistory } from 'react-router-dom';
import { INVENTORY_AUDIT_EXECUTE, INVENTORY_STOCK } from 'settings/constants';
import CompleteAudit from './CompleteAudit';
import InventoryAuditExecute from './InventoryAuditExecute';


export default function AuidtList() {
  const history = useHistory();
  const [auditStatus, setAuditStatus] = useState(AuditStatus.Started);
  const dispatch = useDrawerDispatch();


  const openDrawer = useCallback(
    (id = 0) =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'NEW_AUDIT', props: { refetchAuditList, categoryId: id }}),
    [dispatch]
  );

  const openAuditDetailsDrawer = useCallback(
    (id = 0) =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'AUDIT_DETAILS', props: { refetchAuditList, auditId: id }, data: {closeable: true} }),
    [dispatch]
  );


  const { data, loading, refetch: refetchAuditList } = useQuery(GET_AUDIT_LIST, {
    fetchPolicy: "network-only",
    variables: {
      query: {
        page: 1,
        pageSize: 20,
        orderKey: "Id",
        assending: false
      }
    },
    onCompleted: (data) => {
      let items = data && data.auditQuery && data.auditQuery.audits && data.auditQuery.audits.items;
    },
    onError: (e) => {
      console.log(e.message);
    }
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'AuditNo',
        accessor: 'auditNo', // accessor is the "key" in the data
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      }
    ],
    []
  );

  const table = React.useMemo(
    () => {
      let items = []
      if (data && data.auditQuery && data.auditQuery.audits && data.auditQuery.audits.items) {
        items = data.auditQuery.audits.items.map(c => ({
          auditNo: c.auditNo,
          title: c.title,
          status: c.status,
          actions: <div style={{ fontSize: "large", justifyContent: 'center' }}> {c.status !== "COMPLETED" && <GrEdit title="Audit" onClick={() => history.push(INVENTORY_AUDIT_EXECUTE + "/" + btoa(c.id))} />}
            {c.status === "COMPLETED" && <AiOutlineWeibo title="View Stock" onClick={() => history.push(INVENTORY_STOCK)} />}

            <FaListAlt style={{ marginLeft: 8 }} title="Audit" onClick={() => openAuditDetailsDrawer(c.id)} />
          </div>
        }));
      }
      return <CustomDataTable columns={columns} data={items} loading={loading} />
    },
    [data]
  );

  return (
    <>
      <Grid fluid={true}>
        <Row>
          <Col md={12}>
            <Header
              style={{
                marginBottom: 40,
                boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
              }}
            >
              <Col md={4} xs={12}>
                <Heading>Audit List</Heading>
              </Col>

              <Col md={8} xs={12}>
                <Row>
                  <Col md={8} xs={12}>
                  </Col>

                  <Col md={4} xs={12}>
                    <Button
                      onClick={() => openDrawer()}
                      startEnhancer={() => <Plus />}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            width: '100%',
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                            borderBottomLeftRadius: '3px',
                            borderBottomRightRadius: '3px',
                          }),
                        },
                      }}
                    >
                      New Audit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Header>
            <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
              {table}
            </Wrapper>
          </Col>
        </Row>
      </Grid>

    </>
  );
}
