// import { Input, SIZE } from 'baseui/input'
import Input from 'components/Input/Input';
import DrawerBox from 'components/DrawerBox/DrawerBox'
import { Col, Row } from 'components/FlexBox/FlexBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'
import { Heading } from 'components/Wrapper.style'
import { Form } from 'containers/DrawerItems/DrawerItems.style'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, SHAPE } from 'baseui/button';
import { Tag, KIND, VARIANT } from "baseui/tag";
import { CURRENCY, SALES_INVOICE } from 'settings/constants'
import { CgClose } from "react-icons/cg";
import { Select, SIZE } from 'baseui/select'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { DateFormat } from 'settings/common'
import { useForm } from "react-hook-form";
import useCustomSnackBar from "../../hooks/useCustomSnackBar";
import { useHistory } from 'react-router-dom';
import { useDrawerDispatch } from "../../context/DrawerContext";
import { DatePicker } from "baseui/datepicker";
import { Content } from 'components/Widgets/RadialBarChart/RadialBarChart.style'
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { useStyletron } from 'baseui';
import { GetProductVariationId } from "../../utils/inventory"


const INVOICE_QUERY = gql`query($query: QueryParams!){
salesQuery{
   salesInvoices(query:$query){
     items{
       id
       invoiceCode
    }
  }
 }
}`

// const PRODUCT_QUERY = gql`
// query($invoiceId: ID!) {
//   salesQuery {
//     pocketXSalesInvoice(invoiceId: $invoiceId) {
//       id
//       invoiceCode
//       salesDate
//       total
//       clientId
//       clientName
//       clientNumber
//       note
//       invoiceItems {
//         quantity
//         price
//         discount
//         vat
//         productId
//         productName
//         productUnit
//       }
//     }
//   }
// }`

const SALES_QUERY = gql`
query($query: QueryParams!) {
    salesQuery {
      pocketXSalesInvoice(query: $query) {
        id
        invoiceCode
        salesDate
        total
        clientId
        clientName
        clientAddress
        clientNumber
        invoiceItems {
          id
          quantity
          price
          discount
          vat
          productId
          productName
          productCode
          productUnit
          itemVariationName
          permutationId
          product {
            id
            title
            measureUnit
            productVariations {
              productId
              variationId
              variationValueId
              permutationId
            }
          }
        }
      }
    }
  }`

const PRODUCT_QUERY = gql`
     query($query: QueryParams!){
       salesQuery{
        salesInvoice(query:$query){
        id
        invoiceCode
        salesDate
        total
        clientId
        clientName
        clientAddress
        clientNumber
        invoiceItems {
          id
          quantity
          price
          discount
          vat
          productId
          productName
          productCode
          productUnit
          itemVariationName
          permutationId
          product {
            id
            title
            measureUnit
            productVariations {
              productId
              variationId
              variationValueId
              permutationId
            }
          }
        }
      }
    }
  }`

const GET_PRODUCT_QUERY = gql`query($query: QueryParams!){
    productQuery{
        products(query: $query){
            items{
                id
                title
                code
                measureUnit
            }
        }
    }
}`;

const STOCK_QUERY = gql`query($query: QueryParams!){
    stockQuery{
        available(query: $query){
            quantity
            variationId
            product{
                id
                measureUnit
                code
                title
                salesPrice
                priceExtensions{
                    active
                    amount
                    amountType
                    priceType
                }
            }
        }
    }
}`

const MUTATION = gql`mutation($inv: SalesInvoiceInput!){
    sales{
        addOrUpdate(salesInvoice: $inv){
            success
            data{
                id
            }
        }
    } 
}`;

const POCKETX_RETURN_MUTATION = gql`mutation($inv: SalesInvoiceDTORequestType!){
    sales{
      pocketXReturnSales(salesInvoiceDTO: $inv){
        success
        data{
            id
        }
      }
    }
  }`

const PRODUCT_QUERY_WITH_VARIATION = gql`
query($query: QueryParams!) {
  productQuery {
    pocketXProducts(query: $query) {
      items {
        id
        title
        code
        measureUnit
        productVariations {
          productId
          variationId
          variationValueId
          permutationId
        }
        variations {
          id
          name
          variationValues {
            id
            variationId
            value
          }
        }
      }
    }
  }
}`

const colHeaderStyle = { fontSize: "0.9vw" }

const SalesInvoiceReturn = () => {
    //hooks
    const { handleSubmit } = useForm();
    const [css, theme] = useStyletron();
    const enqueue = useCustomSnackBar();
    const history = useHistory();
    const dispatch = useDrawerDispatch();
    //hooks

    //state
    const [salesInvoice, setSalesInvoice] = useState([]);
    const [selectInvoice, setSelectInvoice] = useState([]);
    const [salesItem, setSalesItem] = useState([]);
    const [productInvoices, setProductInvoices] = useState([]);
    const [salesDate, setSalesDate] = useState([new Date()])

    const [invItems, setInvItems] = useState([])
    const [reProdOptions, setReProdOptions] = useState([])
    const [returnItems, setReturnItems] = useState([])


    const [productOptions, setProductOptions] = useState([])
    const [invoiceItems, setInvoiceItems] = useState([]);

    const [discount, setDiscount] = useState('0.00')
    const [vat, setVat] = useState('0.00')
    const [isOpen, setIsOpen] = useState(false)
    const [count, setCount] = useState(1);
    //state

    const openDrawer = useCallback(
        (drawerProps) =>
            dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'ADD_PAYMENT', props: drawerProps }),
        [dispatch]
    );

    useEffect(() => { getInvoices() }, [])

    useEffect(() => {
        if (selectInvoice.length > 0) {
            getInvoiceDetail(
                {
                    variables: {
                        query: {
                            "id": selectInvoice[0].id
                        }
                    }
                }
            )
        } else {
            setSalesItem([])
        }
    }, [selectInvoice])

    const [loadMutation, { loading: submitting }] = useMutation(MUTATION, {
        onCompleted: (data) => {
            if (data && data.sales && data.sales.addOrUpdate && data.sales.addOrUpdate.success) {
                enqueue("success", "Invoice Successfully Generated");
                history.push(SALES_INVOICE)
                resetInv()
            } else {
                enqueue("error", "Invoice couldn't be generated. Please try again later.");
            }
        },
        onError: () => {
            enqueue("error", "Invoice couldn't be generated. Please try again later.");
        }
    });

    // const [loadMutation, { loading: submitting }] = useMutation(POCKETX_RETURN_MUTATION, {
    //     onCompleted: (data) => {
    //         if (data && data.sales && data.sales.pocketXReturnSales && data.sales.pocketXReturnSales.success) {
    //             enqueue("success", "Invoice Successfully Generated");
    //             history.push(SALES_INVOICE)
    //             resetInv()
    //         } else {
    //             enqueue("error", "Invoice couldn't be generated. Please try again later.");
    //         }
    //     },
    //     onError: () => {
    //         enqueue("error", "Invoice couldn't be generated. Please try again later.");
    //     }
    // });

    const [getInvoices] = useLazyQuery(INVOICE_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            "query": {
                "page": 1,
                "pageSize": 1000,
                "orderKey": "invoiceCode",
                "assending": false,
                "params": [
                    { "key": "onlyTypes", value: JSON.stringify([0]) }
                ]
            }
        },
        onCompleted: (data) => {
            let items = data && data.salesQuery && data.salesQuery.salesInvoices && data.salesQuery.salesInvoices.items
            setSalesInvoice(items);
        },
        onError: (error) => {
            alert(error.message);
        }
    })

    const handleProductDetails = (e: any) => {
        resetInv()
        setSelectInvoice(e.value);
    }


    // const [getInvoiceDetail, { loading, data }] = useLazyQuery(PRODUCT_QUERY,
    //     {
    //         fetchPolicy: "network-only",
    //         onCompleted: (data) => {
    //             let res = data && data.salesQuery && data.salesQuery.salesInvoice
    //             setSalesItem([res]);

    //             let invProducts = [];
    //             if (Array.isArray(res.invoiceItems)) {
    //                 invProducts = res.invoiceItems.map(a => {
    //                     return {
    //                         "label": a.product.title,
    //                         "id": a.product.id
    //                     }
    //                 })
    //             }
    //             const itms = res.invoiceItems || []
    //             setInvItems(JSON.parse(JSON.stringify(itms)))
    //             setReProdOptions(invProducts || [])
    //         },
    //         onError: (error) => {
    //             alert(error.message);
    //         }
    //     });

    const [getInvoiceDetail, { loading, data }] = useLazyQuery(SALES_QUERY,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                let res = data?.salesQuery?.pocketXSalesInvoice;
                setSalesItem([res]);

                let invProducts = [];
                if (Array.isArray(res.invoiceItems)) {
                    invProducts = res.invoiceItems.map(a => {
                        let t = a.product.title
                        if (a.permutationId > 0) {
                            t = `${a.product.title}(${a.itemVariationName})`
                        }
                        return {
                            "label": t,
                            "id": a.id
                        }
                    })
                }
                const itms = res.invoiceItems || []
                setInvItems(JSON.parse(JSON.stringify(itms)))
                setReProdOptions(invProducts || [])
            },
            onError: (error) => {
                alert(error.message);
            }
        });




    const handleChangeReturnQty = (e: any, index: number) => {
        const value = e.target.value;
        const newItems = [...returnItems];
        let item = newItems[index]
        if (item.quantity < parseInt(value)) {
            return
        }
        newItems[index]["returnQty"] = value;
        setReturnItems(newItems);
    }

    const addReturnItems = (selectedOptions) => {
        let items = []
        if (returnItems.find(a => a.soldItemId === selectedOptions[0].id)) return;

        let item = invItems.find(a => a.id === selectedOptions[0].id)

        let reItem = {
            title: selectedOptions[0].label,
            measureUnit: item.product.measureUnit,
            quantity: item.quantity,
            discount: item.discount,
            vat: item.vat,
            price: item.price,
            returnQty: 0,
            productId: item.product.id,
            permutationId: item.permutationId,
            status: "RETURNED",
            soldItemId: selectedOptions[0].id
        }

        let r = JSON.parse(JSON.stringify(returnItems))
        r.push(reItem)

        setProductInvoices(r)
        setReturnItems(r)
    }

    //add Product
    // const [loadProducts, { data: products }] = useLazyQuery(GET_PRODUCT_QUERY, {
    //     onCompleted: (data) => {
    //         if (data && data.productQuery && data.productQuery.products && data.productQuery.products.items) {
    //             const opts = data.productQuery.products && data.productQuery.products.items.map(a => ({
    //                 label: a.title + (a.code ? ` (${a.code})` : ""),
    //                 value: a.id
    //             }))
    //             if (opts.length > 0) setProductOptions(opts)
    //         }
    //     }
    // });

    const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY_WITH_VARIATION, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            let res = data?.productQuery?.pocketXProducts?.items
            if (res) {
                const opts = res.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))

                if (opts.length > 0) {
                    setProductOptions(opts);
                }
            }
        }
    });

    function handleAutoComplete(e) {
        const val = e.target.value.toLowerCase();
        if (val.length < 2) return;
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 5,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val,
                    params: [
                        {
                            key: "checkStock",
                            value: "true"
                        }
                    ]
                }
            }
        });
    }

    // function handleProductAdd(value) {
    //     const product = value[0];
    //     if (invoiceItems.find(a => a.productId === product.value)) return;
    //     loadStocks({
    //         variables: {
    //             query: {
    //                 params: [
    //                     { key: "summary", value: "true" },
    //                     { key: "directSalesPrice", value: "true" },
    //                     { key: "productId", value: product.value + "" }
    //                 ]
    //             }
    //         }
    //     });
    // }

    function handleProductAdd(value) {
        const product = value[0];

        // if (invoiceItems?.find(a => a.productId === product.value)) {
        //     enqueue("error", "Product already added!");
        //     return;
        // };
        const productList = products?.productQuery?.pocketXProducts?.items || [];

        let selectedItem = productList.find(a => a.id === product.value) || null;
        if (!selectedItem) return;


        const currentCount = count + 1
        setCount(currentCount);

        let items = [...invoiceItems];
        let item = {
            sl: currentCount,
            productId: product.value,
            title: product.label,
            rem: 0,
            measureUnit: selectedItem.measureUnit || "",
            productVariations: selectedItem.productVariations,
            variations: selectedItem.variations,
            selectedVariations: [],
            permutationId: 0,
            quantity: '',
            salesPrice: '',
        }
        items.push(item);

        setInvoiceItems(items);

        if (selectedItem.variations.length === 0) {
            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "showZeroStock", value: "false" },
                            { key: "summary", value: "true" },
                            { key: "onlyOwnOrg", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productId", value: product.value + "" }
                        ]
                    }
                }
            });
        }
    }

    const [loadStocks] = useLazyQuery(STOCK_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.stockQuery && data.stockQuery.available) {
                const stock = data.stockQuery.available[0];
                const discount = (stock.product.priceExtensions?.find(a => a.priceType === "DISCOUNT" && a.active === true) || { amount: 0, amountType: "" })
                const vat = (stock.product.priceExtensions?.find(a => a.priceType === "VAT" && a.active === true) || { amount: 0, amountType: "" })

                // let invItems: any = [...invoiceItems]
                // let invItem: any = invItems.find((a: any) => a.productId == stock.product.id);


                let allInvoiceItems: any = [...invoiceItems]
                let invItems: any = allInvoiceItems.filter((a: any) => a.productId == stock.product.id);

                let invItem = null;

                if(stock.variationId > 0){
                    invItem = invItems.find((a: any) => a.productId === stock.product.id && a.permutationId === stock.variationId)
                }else{
                    invItem = invItems.find((a: any) => a.productId === stock.product.id)
                }

                debugger

                invItem.rem = stock.quantity;
                invItem.salesPrice = stock.product.salesPrice;
                invItem.vat = itemAmountCalculate(1, vat.amount, vat.amountType, (stock.product.salesPrice -
                    itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice))).toFixed(2);
                invItem.discount = itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice);
                invItem.discountType = discount.amountType;

                let itemInd = allInvoiceItems.indexOf(a => a.sl === invItem.sl)
                allInvoiceItems[itemInd] = invItem

                setInvoiceItems(allInvoiceItems);

                // // TODO: Later when inclusive/exclusive discount is implemented, the salesPrice would be provided by the server
                // setInvoiceItems([...invoiceItems, {
                //     productId: stock.product.id,
                //     title: stock.product.title,
                //     salesPrice: stock.product.salesPrice,
                //     measureUnit: stock.product.measureUnit,
                //     rem: stock.quantity,
                //     code: stock.product.code,
                //     vat: itemAmountCalculate(1, vat.amount, vat.amountType, stock.product.salesPrice),
                //     //discount: discount.amount,
                //     discount: itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice),
                //     discountType: discount.amountType,
                //     quantity: "",
                //     status: "SOLD"
                // }]);
            }
        }
    });


    function getRowTotalPrice(quantity, uPrice, discount, vat) {
        //return ((uPrice-discount) * quantity).toFixed(2)
        return (((parseFloat(uPrice) + parseFloat(vat)) - parseFloat(discount)) * parseFloat(quantity)).toFixed(2)
    }

    //this is for new added items
    function getTotalPrice() {
        const items = [...invoiceItems] || [];
        return items.map(item => {
            //return ((((parseFloat(item.salesPrice) || 0) + (parseFloat(item.vat) || 0)) - (parseFloat(item.discount) || 0)) * (parseFloat(item.quantity) || 0));
            return (((parseFloat(item.salesPrice) || 0) * (parseFloat(item.quantity) || 0)));
        }).reduce((c, d) => c + d, 0)
    }

    function getTotalReturnPrice() {
        const items = [...returnItems] || [];
        return items.map(item => {
            return ((((parseFloat(item.price) || 0) + (parseFloat(item.vat) || 0)) - (parseFloat(item.discount) || 0)) * (parseFloat(item.returnQty) || 0));
        }).reduce((c, d) => c + d, 0)
    }

    function getTotal() {
        let result = 0;
        let totalReturnPrice = getTotalReturnPrice()
        result = getTotalCustomerPayable() - parseFloat(totalReturnPrice + "")
        return result
    }

    //how much customer need to pay
    //this is for new added items
    function getTotalCustomerPayable() {
        let totalPrice = getTotalPrice();
        //let result = (parseFloat(totalPrice + "") + parseFloat(vat)) - parseFloat(discount)
        let result = (parseFloat(totalPrice + "") + parseFloat(vat) - parseFloat(discount))
        return result;
    }

    //this calculate when adding a new item
    function getTotalDiscount(invData: any) {
        const invoiceData = invData || [];
        let result = 0;
        if (invoiceData.length > 0) {
            result = invoiceItems.map((item, i) => ({ discount: item.discount, discountType: item.discountType, index: i }))
                .reduce((acc, item) => {
                    const data = invoiceData[item.index];
                    let itemDiscount = (Number(data?.quantity) || 0) * Number(data?.discount || 0)
                    return itemDiscount + acc;
                }, 0)
        }
        setDiscount(result + "")
    }

    //this calculate when adding a new item
    function getTotalVat(invData: any) {
        const invoiceData = invData || [];
        let result = 0;
        if (invoiceData.length > 0) {
            result = invoiceItems.map((item, i) => ({ vat: item.vat, vatType: item.vatType, index: i }))
                .reduce((acc, item) => {
                    const data = invoiceData[item.index];
                    let itemDiscount = (Number(data?.quantity) || 0) * Number(data?.vat || 0)
                    return itemDiscount + acc;
                }, 0)
        }
        setVat(result + "")
    }

    // function getTotalVat(invData) {
    //     const invoiceData = invData || [];
    //     let result = invoiceItems.map((item, i) => ({ vat: item.vat, index: i }))
    //         .reduce((acc, item) => {
    //             const data = invoiceData[item.index];
    //             let itemVat = ((Number(data.quantity) || 0) * (Number(data.salesPrice) || 0)) * (item.vat / 100);
    //             return itemVat + acc;
    //         }, 0)
    //     setVat(result + "")
    // }

    function handleReturnItemRemove(index: any) {
        const items = returnItems.filter((_, i) => i !== index);
        setReturnItems(items);
    }

    function handleSalesItemRemove(sl) {
        // const items = invoiceItems.filter((_, i) => i !== index);
        let products = [...invoiceItems];
        const items = products.filter((item) => item.sl !== sl);
        setInvoiceItems(items);
        getTotalVat(items)
        getTotalDiscount(items)
    }

    function handleUpdateSalesItems(e: any, index: number) {
        const value = e.target.value;
        const newItems = [...invoiceItems];
        let item = newItems[index]

        //if item is more than discount
        if (e.target.name === 'discount') {
            if (parseFloat(item.salesPrice) <= parseFloat(value)) {
                return
            }
        }

        newItems[index][e.target.name] = value;

        if (e.target.name === 'quantity' || e.target.name === 'salesPrice' || e.target.name === 'discount' || e.target.name === 'vat') {
            newItems[index]['rowTotal'] = getRowTotalPrice(item.quantity, item.salesPrice, item.discount, item.vat)
        }

        setInvoiceItems(newItems);
        getTotalDiscount(newItems);
        getTotalVat(newItems);
    }

    //add Product
    const onSubmit = (data, payments = null) => {
        if (!isValid()) {
            // alert("Please add supplier AND purchase items");
            return;
        }

        let allInvItems = [];
        invoiceItems.forEach(a => {
            allInvItems.push({
                ...a,
                status: "SOLD",
                price: a.salesPrice
            })
        })

        returnItems.forEach(b => {
            allInvItems.push({
                ...b,
                status: "RETURNED",
                quantity: parseFloat(b.returnQty)
            })
        })

        const inv = {
            status: "RETURNED",
            parentId: selectInvoice[0].id,
            discount: parseFloat(discount),
            total: getTotalPrice(), //only sending the added item total, return total is calculating in backend
            vat: parseFloat(vat),
            clientId: salesItem[0].clientId,
            salesDate: new Date().toISOString(),
            invoicePayments: [],
            note: data.note,
            invoiceItems: allInvItems.map((a, i) => ({
                price: parseFloat(a.price),
                discount: parseFloat(a.discount),
                vat: parseFloat(a.vat),
                quantity: parseFloat(a.quantity),
                productId: a.productId,
                permutationId: a.permutationId ? a.permutationId : 0,
                status: a.status
            }))
        }
        if (Array.isArray(payments) && payments.length > 0) {
            inv.invoicePayments = payments.map(p => ({ ...p, paymentDate: salesDate[0] }));
        }

        loadMutation({
            variables: {
                inv: inv
            }
        });
    };

    function isValid() {
        let invalidCount = 0;
        // invoiceItems.forEach(a => {
        //     let qty = a.quantity
        //     qty = parseFloat(qty) ? parseFloat(qty) : 0

        //     let price = a.salesPrice
        //     price = parseFloat(price) ? parseFloat(price) : 0

        //     if (qty < 1 || price < 1) {
        //         invalidCount = invalidCount + 1
        //     }
        // })
        // returnItems.forEach(a => {
        //     let qty = a.returnQty
        //     qty = parseFloat(qty) ? parseFloat(qty) : 0

        //     let price = a.price
        //     price = parseFloat(price) ? parseFloat(price) : 0

        //     if (qty < 1 || price < 1) {
        //         invalidCount = invalidCount + 1
        //     }
        // })
        let hasVariationErr = invoiceItems.reduce((a, c) => {
            let cn = c.selectedVariations.length === c.variations.length
            if (cn) {
                return a + 0
            } else {
                return a + 1
            }
        }, 0) > 0

        let isQty = invoiceItems.every(a => +a.quantity > 0);

        let isReturnQty = returnItems.every(a => +a.returnQty > 0);

        if (hasVariationErr) {
            enqueue("error", "Please select all variations");
            return false
        }

        if (!isQty) {
            enqueue("error", "Please input quantity");
            return false
        }
        if (!isReturnQty) {
            enqueue("error", "Please input return quantity");
            return false
        }

        return true
        // invalidCount === 0;
    }

    function handlePayment() {
        // if (!isValid()) {
        //     alert("Please add client AND invoice items");
        //     return;
        // }
        isValid() && openDrawer({
            total: Number(getTotal()),
            submitCallback: handleSubmit(onSubmit), extraLabel: "Change"
        });
    }

    let options = salesInvoice.map(v => ({ label: v.invoiceCode, id: v.id }))

    function itemAmountCalculate(qty, discount, type, sellPrice) {
        let itemVat = 0;
        if (type === "PERCENTAGE") {
            itemVat = ((Number(qty) || 0) * (Number(sellPrice) || 0)) * (discount / 100);
        } else {
            itemVat = ((Number(qty) || 0) * (Number(discount) || 0));
        }
        return itemVat;
    }

    //reset
    function resetInv() {
        setSelectInvoice([])
        resetReturnItems()
        resetReplaceItems()
    }
    function resetReturnItems() {
        setReProdOptions([])
        setReturnItems([])
    }
    function resetReplaceItems() {
        setInvoiceItems([])
    }
    //reset

    function getReturnBtnText() {
        let txt = ""
        if (getTotalCustomerPayable() > getTotalReturnPrice()) {
            txt = "Pay later and Return"
            // onChangeConfirmationModal()
        } else if (getTotalCustomerPayable() === getTotalReturnPrice()) {
            txt = "Return"
        } else {
            txt = "Cash Return"
        }
        return txt
    }

    const onChangeConfirmationModal = () => {
        // if (!isValid()) {
        //     alert("Please add client And invoice items");
        //     return;
        // }
        isValid() && setIsOpen(true);
    }


    function cancelModal() {
        // reset();
        closeModal();
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleChangeVariation = (sl: number, value: any, variationId: number) => {
        let items = [...invoiceItems]
        let item = items.find(a => a.sl == sl);
        if (!item) return;

        //resetting
        item.rem = 0;
        item.salesPrice = 0;
        item.vat = 0;
        item.discount = 0;
        item.discountType = null;
        item.permutationId = 0;
        item.quantity = 0;

        let selectedVariations = item.selectedVariations || [];
        let isExistVariationValue = selectedVariations.some(a => a.variationId == variationId);

        if (isExistVariationValue) {
            selectedVariations = selectedVariations.filter(a => a.variationId != variationId);
        }

        if (value.length > 0) {
            let permutationId = item.productVariations.find(a => a.variationId === variationId && a.variationValueId === value[0].value)?.permutationId || 0;
            selectedVariations.push(
                {
                    variationId: variationId,
                    variationValueId: value[0].value,
                    variationValue: value[0].label,
                    permutationId: permutationId
                })

            if (item.selectedVariations.length === item.variations.length) {
                let vId = GetProductVariationId(item.productVariations, selectedVariations)

                if (items.find(a => a.permutationId === vId) && items.find(a => a.permutationId === vId).sl !== sl) {
                    let newItems = items.filter(a => a.sl !== sl);
                    setInvoiceItems(newItems);

                    enqueue("error", "This item already added!!");
                    return
                };

                item.permutationId = vId;
                selectedVariations = selectedVariations.map(a => {
                    return {
                        ...a,
                        permutationId: vId
                    }
                })
            }
            item.selectedVariations = selectedVariations;
        }

        let ind = items.findIndex(a => a.sl == sl);
        items[ind] = item;
        setInvoiceItems(items);

        if (selectedVariations.length === item.variations.length) {
            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "showZeroStock", value: "false" },
                            { key: "summary", value: "true" },
                            { key: "onlyOwnOrg", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productId", value: item.productId + "" },
                            { key: "permutationId", value: item.permutationId + "" },
                            { key: "includeVariationName", value: "true" }
                        ]
                    }
                }
            });
        }
    }

    return (
        <div>
            <Row>
                <Col md={4} xs={12}>
                    <Heading>Sales Invoice Return</Heading>
                </Col>
            </Row>
            <Form>
                <DrawerBox>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col md={3}>
                            <FormLabel>Invoice Code</FormLabel>
                            <Select
                                options={options || []}
                                labelKey="label"
                                valueKey="id"
                                searchable={true}
                                value={selectInvoice}
                                onChange={handleProductDetails}
                                size={SIZE.compact}
                            />
                        </Col>
                        <Col md={3}>
                            <FormLabel>Sold Products</FormLabel>
                            <Select
                                options={reProdOptions || []}
                                labelKey="label"
                                valueKey="id"
                                searchable={true}
                                size={SIZE.compact}
                                onChange={e => addReturnItems(e.value)}
                            />
                        </Col>
                        <Col md={4}>
                            {/*<FormFields>*/}
                            <FormLabel>Replace Product Name/Code</FormLabel>
                            <Select
                                options={productOptions || []}
                                disabled={returnItems.length < 1}
                                labelKey="label"
                                valueKey="value"
                                value={[]}
                                searchable={true}
                                onChange={({ value }) => handleProductAdd(value)}
                                onInputChange={handleAutoComplete}
                                size={SIZE.compact}
                            />
                            {/*</FormFields>*/}
                        </Col>
                        <Col md={2}>
                            {/*<FormFields>*/}
                            <FormLabel>Sales Date</FormLabel>
                            <DatePicker
                                mask={null}
                                formatString="dd-MMM-yyy"
                                value={salesDate}
                                onChange={({ date }) =>
                                    setSalesDate(Array.isArray(date) ? date : [date])
                                }
                                size={SIZE.compact}
                            />
                            {/*</FormFields>*/}
                        </Col>
                    </Row>
                    {selectInvoice.length > 0 &&
                        <>
                            <Row style={{ marginTop: 30 }}>
                                <Col md={3}>
                                    <FormFields>
                                        <FormLabel>Customer Name</FormLabel>
                                    </FormFields>
                                </Col>
                                <Col md={3}>
                                    <FormFields>
                                        <FormLabel>Customer Phone</FormLabel>
                                    </FormFields>
                                </Col>
                                <Col md={3}>
                                    <FormFields>
                                        <FormLabel>Invoice Date</FormLabel>
                                    </FormFields>
                                </Col>
                                <Col md={3}>
                                    <FormFields>
                                        <FormLabel>Invoice Note</FormLabel>
                                    </FormFields>
                                </Col>
                            </Row>
                            {
                                salesItem.map((item, idx) => {
                                    return (
                                        <Row key={idx} style={{ marginBottom: 40 }}>
                                            <Col md={3}>
                                                <FormFields>
                                                    <span>{item.clientName}</span>
                                                </FormFields>
                                            </Col>
                                            <Col md={3}>
                                                <FormFields>
                                                    <span>{item.clientNumber}</span>
                                                </FormFields>
                                            </Col>
                                            <Col md={3}>
                                                <FormFields>
                                                    <span>{DateFormat.format(new Date(item.salesDate))}</span>
                                                </FormFields>
                                            </Col>
                                            <Col md={3}>
                                                <FormFields>
                                                </FormFields>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }

                        </>
                    }
                    <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                    <h3>Return Items</h3>
                    {returnItems.length < 1 &&
                        <h4>No item added for return</h4>
                    }
                    {returnItems && returnItems.length > 0 && <>
                        <Row style={{ marginBottom: 5, marginTop: 5 }}>
                            <Col md={2}><b style={colHeaderStyle}>Product Name</b></Col>
                            <Col md={2}><b style={colHeaderStyle}>Return Quantity</b></Col>
                            <Col md={2}><b style={colHeaderStyle}>Unit Sell Price({CURRENCY})</b></Col>
                            <Col md={1}><b style={colHeaderStyle}>Discounts</b></Col>
                            <Col md={1}><b style={colHeaderStyle}>Vat</b></Col>
                            <Col md={1}><b style={colHeaderStyle}>Status</b></Col>
                            <Col md={2}><b style={colHeaderStyle}>Total <span style={{ fontWeight: 900 }}>{CURRENCY}</span></b></Col>
                        </Row>
                        <hr style={{ border: ".75px solid lavender", marginBottom: 5 }} />
                        {returnItems && returnItems.map((item, i) =>
                            <Row>
                                <Col md={2} style={{ alignSelf: 'flex-start', marginTop: '-10px' }}><p>{item.title}</p></Col>
                                {/*<Col md={1} style={{ alignSelf: 'center', marginTop: '-10px' }}><p>{`${item.quantity} (${item.measureUnit})`}</p></Col>*/}
                                <Col md={2}>
                                    <Input
                                        type="number"
                                        name="returnQty"
                                        value={item.returnQty}
                                        onChange={(e) => handleChangeReturnQty(e, i)}
                                        size={SIZE.compact}
                                    />
                                    <p className={css({ fontSize: '12px', marginTop: '0px' })}>Ordered <span className={css({ fontWeight: 'bold', color: 'green' })}>{`${item.quantity}`}</span> {`(${item.measureUnit})`}</p>
                                </Col>
                                <Col md={2} style={{ alignSelf: 'flex-start', marginTop: '-10px' }}><p>{item.price}</p></Col>
                                <Col md={1} style={{ alignSelf: 'flex-start', marginTop: '-10px' }}><p>{item.discount * item.returnQty}</p></Col>
                                <Col md={1} style={{ alignSelf: 'flex-start', marginTop: '-10px' }}><p>{item.vat * item.returnQty}</p></Col>
                                <Col md={1} style={{ alignSelf: 'flex-start', marginTop: '0px' }}>
                                    <Tag
                                        closeable={false}
                                        kind={KIND.red}
                                        variant={VARIANT.solid}
                                    >
                                        Return</Tag>
                                </Col>
                                <Col md={2}>
                                    <Input
                                        type="number"
                                        name="total"
                                        disabled={true}
                                        value={item.returnQty * ((item.price + item.vat) - item.discount)}
                                        onChange={(e) => handleChangeReturnQty(e, i)}
                                        size={SIZE.compact}
                                    />
                                </Col>
                                <Col md={1} style={{ alignItems: 'center' }}>
                                    <Button
                                        size={SIZE.mini}
                                        shape={SHAPE.circle}
                                        type="button"
                                        onClick={() => handleReturnItemRemove(i)}
                                    >
                                        <CgClose style={{ fontSize: 'larger' }} />
                                    </Button>
                                </Col>
                            </Row>
                        )}

                    </>}

                    {/*exchange product*/}
                    {invoiceItems.length > 0 &&
                        <>
                            <h3>Replace Items</h3>
                            <Row style={{ marginBottom: 0 }}>
                                <Col md={2}><b>Product Name</b></Col>
                                <Col md={2}><b>Variation</b></Col>
                                <Col md={1}><b>Quantity</b></Col>
                                <Col md={1}><b>Unit Sell Price({CURRENCY})</b></Col>
                                <Col md={1}><b>Discount({CURRENCY})</b></Col>
                                <Col md={1}><b>Vat({CURRENCY})</b></Col>
                                <Col md={1}><b>Status</b></Col>
                                <Col md={2}><b>Total({CURRENCY})</b></Col>
                                <Col md={1}></Col>
                            </Row>
                            <hr style={{ border: ".75px solid lavender", marginBottom: 5 }} />

                            {invoiceItems.map((item, i) =>
                                <Row key={item.productId}>
                                    <Col md={2}
                                        style={{ alignSelf: 'flex-start' }}>{item.title} {item.code ? `(${item.code})` : ""}</Col>
                                    {
                                        item.variations.length > 0 ?
                                            item.variations.map((a) => {
                                                return (
                                                    <Col md={1}>
                                                        <Select
                                                            options={a.variationValues.map(v => { return { label: v.value, value: v.id } }) || []}
                                                            placeholder={`${a.name}`}
                                                            labelKey="label"
                                                            valueKey="value"
                                                            value={item.selectedVariations.filter(s => s.variationId === a.id).map(v => { return { label: v.variationValue, value: v.variationValueId } }) || []}
                                                            searchable={true}
                                                            onChange={({ value }) => handleChangeVariation(item.sl, value, a.id)}
                                                            size='compact'
                                                        />
                                                    </Col>
                                                )
                                            })
                                            : <Col md={2}><b>No Variation</b></Col>
                                    }
                                    <Col md={1}>
                                        <Input
                                            type="number"
                                            name="quantity"
                                            value={item.quantity}
                                            onChange={(e: any) => {
                                                const val = parseFloat(e.target.value) || 0
                                                if (val > item.rem) {
                                                    return
                                                }
                                                handleUpdateSalesItems(e, i)
                                            }
                                            }
                                            size={SIZE.compact}
                                        />
                                        {/*<p style={{marginTop: '-2px', fontSize: '12px'}}>Available <span style={{fontWeight: 'bold', color: 'green'}}>{`${item.rem}`}</span> {`(${item.measureUnit})`}</p>*/}
                                        <p className={css({ fontSize: '12px' })}>Available <span className={css({ fontWeight: 'bold', color: 'green' })}>{`${item.rem}`}</span> {`(${item.measureUnit})`}</p>
                                    </Col>
                                    <Col className={css({ margin: '0px' })} md={1}>
                                        <Input
                                            type="number"
                                            name="salesPrice"
                                            value={item.salesPrice}
                                            onChange={(e: any) => {
                                                handleUpdateSalesItems(e, i)
                                            }
                                            }
                                            size={SIZE.compact}
                                        />

                                    </Col>
                                    <Col md={1}>
                                        <Input
                                            type="number"
                                            name="discount"
                                            value={item.discount}
                                            onChange={(e: any) => {
                                                handleUpdateSalesItems(e, i)
                                            }
                                            }
                                            size={SIZE.compact}
                                        />

                                    </Col>
                                    <Col md={1}>
                                        <Input
                                            type="number"
                                            name="vat"
                                            value={item.vat}
                                            onChange={(e: any) => {
                                                handleUpdateSalesItems(e, i)
                                            }
                                            }
                                            size={SIZE.compact}
                                        />

                                    </Col>
                                    <Col md={1}
                                    // style={{ alignSelf: 'center', marginTop: '-10px' }}
                                    >
                                        <Tag
                                            closeable={false}
                                            kind={KIND.green}
                                            variant={VARIANT.solid}
                                        >
                                            New Sell</Tag>
                                    </Col>
                                    <Col md={2}>
                                        <Input
                                            type="number"
                                            value={item.rowTotal}
                                            name={`rowTotal`}
                                            disabled={true}
                                            size={SIZE.compact}
                                        />
                                    </Col>
                                    <Col md={1} style={{ alignSelf: 'flex-start' }}>
                                        <Button
                                            size={SIZE.mini}
                                            shape={SHAPE.circle}
                                            type="button"
                                            onClick={() => handleSalesItemRemove(item.sl)}
                                        >
                                            <CgClose style={{ fontSize: 'larger' }} />
                                        </Button>
                                    </Col>
                                </Row>)}
                        </>
                    }
                    {/*exchange product*/}


                    {/*total calculation*/}
                    {(invoiceItems.length > 0 || returnItems.length > 0) &&
                        <>
                            <hr style={{ border: ".75px solid lavender", marginBottom: 5 }} />

                            <Row style={{ marginBottom: -15, marginLeft: 380, justifyContent: 'flex-end' }}>
                                <Col md={8}><h4 style={{ float: 'right' }}><strong>Total Return Price({CURRENCY})</strong></h4></Col>
                                <Col md={2}>
                                    <Input
                                        disabled={true}
                                        value={getTotalReturnPrice()}
                                        name="totalPrice"
                                        size={SIZE.compact}
                                    />
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: -15, marginLeft: 380, justifyContent: 'flex-end' }}>
                                <Col md={8}><h4 style={{ float: 'right' }}><strong>Sub Total({CURRENCY})</strong></h4></Col>
                                <Col md={2}>
                                    <Input
                                        disabled={true}
                                        value={getTotalPrice()}
                                        name="totalPrice"
                                        size={SIZE.compact}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: -15, marginLeft: 380, justifyContent: 'flex-end' }}>
                                <Col md={8}><h4 style={{ float: 'right' }}><strong>Discount({CURRENCY})</strong></h4></Col>
                                <Col md={2}>
                                    <Input
                                        name="totalDiscount"
                                        type="number"
                                        disabled={true}
                                        value={discount}
                                        onChange={(e: any) => setDiscount(e.target.value)}
                                        size={SIZE.compact}
                                    /></Col>
                            </Row>
                            <Row style={{ marginBottom: -15, marginLeft: 380, justifyContent: 'flex-end' }}>
                                <Col md={8}><h4 style={{ float: 'right' }}><strong>Vat({CURRENCY})</strong></h4></Col>
                                <Col md={2}>
                                    <Input
                                        name="totalVat"
                                        type="number"
                                        disabled={true}
                                        value={vat}
                                        onChange={(e: any) => setVat(e.target.value)}
                                        size={SIZE.compact}
                                    /></Col>
                            </Row>
                            <Row style={{ marginBottom: -15, marginLeft: 380, justifyContent: 'flex-end' }}>
                                <Col md={8}><h4 style={{ float: 'right' }}><strong>Total({CURRENCY})</strong></h4></Col>
                                <Col md={2}>
                                    <Input
                                        name="total"
                                        disabled={true}
                                        type="number"
                                        value={getTotal()}
                                        size={SIZE.compact}
                                    //onChange={(e: any) => setVat(e.target.value)}
                                    /></Col>
                            </Row>
                            {/*<Row style={{marginBottom: 0}}>*/}
                            {/*    <Col md={8}><h3 style={{float: 'right'}}><strong>Total({CURRENCY})</strong></h3></Col>*/}
                            {/*    <Col md={2}><Input*/}
                            {/*        disabled={true}*/}
                            {/*        inputRef={register()}*/}
                            {/*        name="netPrice"*/}
                            {/*    /></Col>*/}
                            {/*</Row>*/}
                        </>
                    }


                    {/*total calculation*/}



                    {(invoiceItems.length > 0 || returnItems.length > 0) &&
                        <>
                            <hr style={{ border: ".75px solid lavender", marginBottom: 8 }} />

                            <Row style={{ display: "flex", justifyContent: "end" }}>
                                <Col md={2}>
                                    <Button
                                        type="button"
                                        onClick={getReturnBtnText() === "Pay later and Return" ? onChangeConfirmationModal : handleSubmit(onSubmit)}
                                        isLoading={submitting}
                                        overrides={{
                                            BaseButton: {
                                                style: ({ $theme }) => ({
                                                    width: '100%',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                    borderBottomLeftRadius: '3px',
                                                    background: '#d6cb61',
                                                    ":hover": {
                                                        background: "#c7be61"
                                                    }
                                                }),
                                            },
                                        }}
                                    >
                                        {getReturnBtnText()}
                                    </Button>
                                </Col>

                                {getTotalCustomerPayable() > getTotalReturnPrice() &&
                                    <Col md={2}>
                                        <Button
                                            type="button"
                                            onClick={handlePayment}
                                            isLoading={submitting}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        width: '100%',
                                                        borderTopLeftRadius: '3px',
                                                        borderTopRightRadius: '3px',
                                                        borderBottomRightRadius: '3px',
                                                        borderBottomLeftRadius: '3px',
                                                        backgrondColor: 'red'
                                                    }),
                                                },
                                            }}
                                        >
                                            Pay Now and Return
                                        </Button>
                                    </Col>
                                }
                            </Row>
                        </>
                    }

                </DrawerBox>
            </Form>
            <ConfirmationModal
                isOpen={isOpen}
                modalBody={''}
                modalHeader={"Are you sure to proceed with Pay later?"}
                closeCallback={closeModal}
                cancelCallBack={cancelModal}
                submitCallback={handleSubmit(onSubmit)}
            />
        </div>
    )
}

export default SalesInvoiceReturn
