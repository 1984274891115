import React, { useCallback } from 'react';
import { Grid } from 'components/FlexBox/FlexBox';
import { useDrawerDispatch } from 'context/DrawerContext';
import Button from 'components/Button/Button';
import { Plus } from 'assets/icons/PlusMinus';
import { useQuery, gql } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import CustomDataTable from "../../components/DataTable/CustomDataTable";
import { Col, Row } from "./Branch.Style"

const BRANCH_QUERY = gql`
query($query: QueryParams!){
    branchQuery{
      branches(query:$query){
          id
          name
          type
          address
          contactPerson
          phone
          remarks
          active
          parentId
      }
    }
  }`;

export default function ViewBranch() {
    const dispatch = useDrawerDispatch();

    const { loading, data, refetch, } = useQuery(BRANCH_QUERY, {
        fetchPolicy: "network-only",
        variables:
        {
            "query": {

            }
        }
    });

    const openDrawer = useCallback(
        (id = 0) =>
            dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'ADD_BRANCH', props: { "refetch": refetch, branchId: id } }),
        [dispatch]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Phone Number',
                accessor: 'phone',
            },
            {
                Header: 'Address',
                accessor: 'address',
            },
            {
                Header: 'Contact',
                accessor: 'contact',
            },
            {
                Header: 'Note',
                accessor: 'remarks',
            }
        ],

        []
    );

    const table = React.useMemo(
        () => {
            let branches = []
            if (data && data.branchQuery && data.branchQuery.branches) {
                branches = data.branchQuery.branches.map(p => ({
                    name: p.name,
                    type: p.type,
                    phone: p.phone,
                    address: p.address,
                    remarks: p.remarks,
                    contact: p.contactPerson,
                }));
            }
            return <CustomDataTable columns={columns} data={branches} loading={loading} />
        },
        [data]
    );


    return (
        <Grid fluid={true}>
            <Row>
                <Col md={12}>
                    <Header
                        style={{
                            marginBottom: 40,
                            boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                        }}
                    >
                        <Col md={4} xs={12}>
                            <Heading>Branch</Heading>
                        </Col>

                        <Col md={8} xs={12}>
                            <Row>
                                <Col md={8} xs={12}>

                                </Col>

                                <Col md={4} xs={12}>
                                    <Button
                                        onClick={() => openDrawer()}
                                        startEnhancer={() => <Plus />}
                                        overrides={{
                                            BaseButton: {
                                                style: () => ({
                                                    width: '100%',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }),
                                            },
                                        }}
                                    >
                                        Add Branch
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Header>

                    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                        {table}
                    </Wrapper>
                </Col>
            </Row>
        </Grid>
    );
}
