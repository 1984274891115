import React, {useMemo} from "react";
import {useSnackbar} from "baseui/snackbar"
import {Check, Delete} from "baseui/icon";

export default function useCustomSnackBar(){
    const {enqueue} = useSnackbar();

    const customEnqueue = useMemo(() => (status, message) => {
        enqueue({
            message: message,
            startEnhancer: ({size}) => status === "success" ? <Check size={size} /> : <Delete size={size} />,
            overrides: {
                Content: {
                    style: {
                        background: status === "success" ? "green" : "red"
                    }
                }
            }
        });
    }, []);

    return customEnqueue;
}