import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "components/Button/Button";
import DrawerBox from "components/DrawerBox/DrawerBox";
import { Col, Grid, Row } from "components/FlexBox/FlexBox";
import { FormFields, FormLabel } from "components/FormFields/FormFields";
import Input from "components/Input/Input";
import { Heading } from "components/Wrapper.style";
import { Form } from "containers/DrawerItems/DrawerItems.style";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import CustomSelect from "components/Select/Select";

const initialItemsState = {
    title: "",
    companyName: "",
    companySubtitle: "",
    companyAddress: "",
    companyPhone: "",
    companyLogo: ""
}

const initialPositionItemsState = {
    titlePosition: null,
    companyNamePosition: null,
    companySubtitlePosition: null,
    companyAddressPosition: null,
    companyPhonePosition: null,
    companyLogoPosition: null
}

const positionOptions = [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' }
];

const MUTATION = gql`mutation($journals: [JournalInput]!){
  journal{
    add(journals: $journals){
      success
      data{
        voucherNo
        needReceipt
      }
    }
  }
}`;

export default function ReportSettings() {
    const [items, setItems] = useState(initialItemsState);
    const [positionItems, setPositionItems] = useState(initialPositionItemsState);
    const enqueue = useCustomSnackBar();
    // enqueue("error", "Couldn't complete transaction. Please try again later");
    // enqueue("success", "Transaction Successful");

    const handleItemsChange = (event: any) => {
        const { name, value } = event.target;
        const data = { ...items };
        data[name] = value;
        setItems(data);
    }

    const onSubmit = () => {
        console.log(items);
        console.log(positionItems);
    }

    return (
        <Grid fluid={true}>
            <Row>
                <Col md={8}>
                    <Heading style={{ paddingBottom: 20 }}>Report Settings</Heading>
                    <Form>
                        <DrawerBox>
                            <Row style={{ marginBottom: '30px' }}>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Title</FormLabel>
                                        <Input
                                            name="title"
                                            value={items.title}
                                            onChange={(e: any) => handleItemsChange(e)}
                                        />
                                    </FormFields>
                                </Col>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Title Position</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.titlePosition}
                                            onChange={(e:any) => setPositionItems({...positionItems, titlePosition: e.value})}
                                        />
                                    </FormFields>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '30px' }}>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Name</FormLabel>
                                        <Input
                                            name="companyName"
                                            value={items.companyName}
                                            onChange={(e: any) => handleItemsChange(e)}
                                        />
                                    </FormFields>
                                </Col>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Name Position</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.companyNamePosition}
                                            onChange={(e:any) => setPositionItems({...positionItems, companyNamePosition: e.value})}
                                        />
                                    </FormFields>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '30px' }}>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Subtitle</FormLabel>
                                        <Input
                                            name="companySubtitle"
                                            value={items.companySubtitle}
                                            onChange={(e: any) => handleItemsChange(e)}
                                        />
                                    </FormFields>
                                </Col>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Subtitle Position</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.companySubtitlePosition}
                                            onChange={(e:any) => setPositionItems({...positionItems, companySubtitlePosition: e.value})}
                                        />
                                    </FormFields>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '30px' }}>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Address</FormLabel>
                                        <Input
                                            name="companyAddress"
                                            value={items.companyAddress}
                                            onChange={(e: any) => handleItemsChange(e)}
                                        />
                                    </FormFields>
                                </Col>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Address Position</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.companyAddressPosition}
                                            onChange={(e:any) => setPositionItems({...positionItems, companyAddressPosition: e.value})}
                                        />
                                    </FormFields>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '30px' }}>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Phone</FormLabel>
                                        <Input
                                            name="companyPhone"
                                            value={items.companyPhone}
                                            onChange={(e: any) => handleItemsChange(e)}
                                        />
                                    </FormFields>
                                </Col>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Phone Position</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.companyPhonePosition}
                                            onChange={(e:any) => setPositionItems({...positionItems, companyPhonePosition: e.value})}
                                        />
                                    </FormFields>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '30px' }}>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Logo</FormLabel>
                                        <Input
                                            name="companyLogo"
                                            type="file"
                                        // value={data.amount}
                                        // onChange={e => setData({...data, amount: e.target.value})}
                                        />
                                    </FormFields>
                                </Col>
                                <Col md={6}>
                                    <FormFields>
                                        <FormLabel>Company Logo Position</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.companyLogoPosition}
                                            onChange={(e:any) => setPositionItems({...positionItems, companyLogoPosition: e.value})}
                                        />
                                    </FormFields>
                                </Col>
                            </Row>
                            <Button
                                type="button"
                                onClick={onSubmit}
                                // isLoading={loading}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            width: '10%',
                                            borderTopLeftRadius: '3px',
                                            borderTopRightRadius: '3px',
                                            borderBottomRightRadius: '3px',
                                            borderBottomLeftRadius: '3px',
                                        }),
                                    },
                                }}
                            >
                                Save
                            </Button>
                        </DrawerBox>
                    </Form>
                </Col>
            </Row>
        </Grid>
    )
}