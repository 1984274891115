import React, { useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { Auth, SystemSettings, UserFeatures } from "../settings/common";

type AuthProps = {
  isAuthenticated: boolean;
  isErpManager: boolean;
  loading: boolean;
  loginError: boolean;
  authenticate: Function;
  checkToken: Function;
  signout: Function;
};

const AUTH_QUERY = gql`query($query: QueryParams!){userQuery{
    authorize(query: $query){
      expiration
      issuer
      refreshToken
      token
      requestToken
      requestTokenHeader
      roles
      isXeonAdmin
      me{
        id
        fullName
        orgUnit{
          id
          name
        }
      }
    }
  }
}`;

const SYSTEM_SETTINGS_QUERY = gql`query{
  userQuery{
    getAllSystemSettings{
      id
      key
      value
    }
  }
}`

export const GET_USER_FEATURES = gql`query($isCurrentRole: Boolean) {
  userFeatureQuery{
    getUserFeatures(isCurrentRole : $isCurrentRole){
      active
      userId
      featureId
      user{
        fullName
      }
    }
  }
}`

const isValidToken = () => {
  let isAuth = false;
  const auth_data = Auth.get();
  if (auth_data) {
    const expiration = new Date(auth_data.expiration);
    isAuth = expiration >= new Date();
  }
  return isAuth;
}

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());
  const [isErpManager, setIsErpManager] = useState(false)
  const [loginError, setLoginError] = useState(false);

  const [requestAuth, { loading, data }] = useLazyQuery(AUTH_QUERY, {
    onCompleted: (d) => {
      debugger
      let isAdmin = d?.userQuery?.authorize?.isXeonAdmin || false
      setIsAuthenticated(true);
      setLoginError(false);
      setIsErpManager(isAdmin)

      //requesting system settings data
      requestSystemSettings();
      getFeatureUsers({
        variables: {
          "isCurrentRole": true
        }
      });
    }, onError: (e) => {
      setLoginError(true)
    }
  });

  const [requestSystemSettings] = useLazyQuery(SYSTEM_SETTINGS_QUERY, {
    onCompleted: (d) => {
      let data = d.userQuery && d.userQuery.getAllSystemSettings
      if (data) {
        let settings = SystemSettings.generateSettings(data)
        SystemSettings.set(settings)
      }
    }
  });

  const [getFeatureUsers] = useLazyQuery(GET_USER_FEATURES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.userFeatureQuery && data.userFeatureQuery.getUserFeatures) {
        const result = data.userFeatureQuery.getUserFeatures;
        const formatData = result && result.map((a: any) => ({
          userId: a.userId,
          featureId: a.featureId,
          active: a.active
        }))
        UserFeatures.set(formatData);
      }
    }
  });

  if (data) {
    Auth.set(data);
  }

  function authenticate({ username, password }, cb) {
    requestAuth({
      variables: {
        query: {
          params: [
            {
              key: "userName",
              value: username
            },
            {
              key: "password",
              value: password
            }
          ]
        }
      },
    });
    setTimeout(cb, 100);
  }

  function checkToken() {
    setIsAuthenticated(isValidToken());
  }

  function signout(cb) {
    Auth.remove();
    SystemSettings.remove();
    UserFeatures.remove();
    setIsAuthenticated(false);
    //window.location.reload()
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        loading,
        loginError,
        checkToken,
        signout,
        isErpManager
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
