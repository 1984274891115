import React, { useState } from 'react';
import { Row, Col, Grid } from 'components/FlexBox/FlexBox';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Select, SIZE } from 'baseui/select';
import { Header, Heading, Wrapper } from 'components/Wrapper.style';
import Input from 'components/Input/Input';
import { Button, SHAPE } from 'baseui/button';
import { Modal, ModalBody, ModalFooter } from "baseui/modal";
import useCustomSnackBar from 'hooks/useCustomSnackBar';
import { CURRENCY } from 'settings/constants';
import { CgClose } from 'react-icons/cg';
import { useStyletron } from "baseui";


type Props = any;

const PRODUCT_QUERY = gql`query($query: QueryParams!){
    productQuery{
        products(query: $query){
            items{
                id
                title
                code
                measureUnit
            }
        }
    }
}`;

const TRANSFORM_PRODUCT_MUTATION = gql`
mutation($request: StockTransformRequestType!){
    stock{
      transformProductStock(stockTransformArg: $request){
        success
      }
    }
  }
`
const STOCK_QUERY = gql`query($query: QueryParams!){
    stockQuery{
        available(query: $query){
            quantity
            product{
                id
                measureUnit
                code
                title
                salesPrice
                priceExtensions{
                    active
                    amount
                    amountType
                    priceType
                }
            }
        }
    }
}`;


const StockTransform: React.FC<Props> = () => {
    const [productOptions, setProductOptions] = useState([]);
    const [productWithOutStockOptions, setProductWithoutStockOptions] = useState([]);
    const [open, setOpen] = useState(false)
    const [outputItems, setOutputItems] = useState([]);
    const [inputItems, setInputItems] = useState([]);
    const [availableItems, setAvailableItems] = useState({ quantity: 0, measureUnit: "" })
    const enqueue = useCustomSnackBar();
    const [css] = useStyletron();



    const [loadProducts] = useLazyQuery(PRODUCT_QUERY, {
        onCompleted: (data) => {
            if (data?.productQuery?.products?.items) {
                const opts = data?.productQuery?.products?.items?.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))
                if (opts.length > 0)
                    setProductOptions(opts)
            }
        }
    });

    const [loadProductsWithoutStocks] = useLazyQuery(PRODUCT_QUERY, {
        onCompleted: (data) => {
            if (data?.productQuery?.products?.items) {
                const opts = data?.productQuery?.products?.items?.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))
                if (opts.length > 0)
                setProductWithoutStockOptions(opts)
            }
        }
    });

    const [loadStocks] = useLazyQuery(STOCK_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data?.stockQuery?.available) {
                const availProduct = data?.stockQuery?.available[0];
                const qty = availProduct?.quantity;
                const measureUnit = availProduct?.product?.measureUnit;

                let inItems = [...inputItems]
                let itemIndex = inItems.findIndex(a => a.productId === availProduct.product.id);
                inItems[itemIndex].availabileQty = qty;
                inItems[itemIndex].measureUnint = measureUnit;

                setInputItems(inItems);
            }
        }
    });

    const [loadMutation, { loading }] = useMutation(TRANSFORM_PRODUCT_MUTATION, {
        onCompleted: (data) => {
            if (data?.stock?.transformProductStock?.success) {
                enqueue("success", "SuccessFully Transformed");
                reset();
            }
            else if (data?.stock?.transformProductStock?.success === false) {
                enqueue("error", "Couldn't be transformed, please try again later");
            }
        },

        onError: () => {
            enqueue("error", "Couldn't be transformed, please try again later");
        }
    });

    function handleAutoCompleteWithoutStock(e) {
        const val = e.target.value.toLowerCase();
        if (val.length < 2) return;
        loadProductsWithoutStocks({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 5,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val,
                }
            }
        });
    }

    function handleAutoComplete(e) {
        const val = e.target.value.toLowerCase();
        if (val.length < 2) return;
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 5,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val,
                    params: [
                        {
                            key: "checkStock",
                            value: "true"
                        }
                    ]
                }
            }
        });
    }


    const handleOutputProdcutChange = (event, name: string, id) => {

        let findIndex = outputItems.findIndex(a => a.productId === id);
        const newFormData = [...outputItems];

        if (event.option) {
            const fieldName = event.option.label;
            const fieldValue = event.option.value;
            newFormData[findIndex].productName = fieldName;
            newFormData[findIndex].productId = fieldValue;
            setOutputItems(newFormData);
        }
        else if (name === "qty") {
            const quantity = event.target.value;
            newFormData[findIndex].quantity = quantity;
            setOutputItems(newFormData);
        }
        else if (name === "costPrice") {
            const costPrice = event.target.value;
            newFormData[findIndex].costPrice = costPrice;
            setOutputItems(newFormData)
        }
    };

    const handleSearchInputProduct = (event) => {
        const newFormData = [...inputItems];
        const fieldName = event.option.label;
        const fieldValue = event.option.value;
        let isInputProductExist = newFormData.length > 0 && newFormData.some((item) => item.productId === fieldValue)
        
        if(!isInputProductExist){
            let newItem = {
                id: "",
                productId: fieldValue,
                productName: fieldName,
                quantity: "", measureUnint: "",
                availabileQty: 0
            }
            newFormData.push(newItem);
        }
        setInputItems(newFormData);

        loadStocks({
            variables: {
                query: {
                    params: [
                        { key: "summary", value: "true" },
                        { key: "onlyOwnOrg", value: "true" },
                        { key: "directSalesPrice", value: "true" },
                        { key: "productId", value: fieldValue + "" }
                    ]
                }
            }
        });
    }

    const handleAddOutputProduct = (event) => {
        const newFormData = [...outputItems];
        const fieldName = event.option.label;
        const fieldValue = event.option.value;
        let isOutputProductExist = newFormData.length > 0 && newFormData.some((item) => item.productId === fieldValue)


        if (!isOutputProductExist) {
            let newItem = {
                id: "",
                productId: fieldValue,
                productName: fieldName,
                quantity: ""
            }
            newFormData.push(newItem);
        }
        setOutputItems(newFormData);
    }

    const handleInputProdcutChange = (event, name: string, id) => {
        let findIndex = inputItems.findIndex(a => a.productId === id);
        const newFormData = [...inputItems];

        if (event.option) {
            const fieldName = event.option.label;
            const fieldValue = event.option.value;
            newFormData[findIndex].productName = fieldName;
            newFormData[findIndex].productId = fieldValue;
            newFormData[findIndex].measureUnint = availableItems.measureUnit
            newFormData[findIndex].availabileQty = availableItems.quantity
            setInputItems(newFormData);

            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "summary", value: "true" },
                            { key: "onlyOwnOrg", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productId", value: fieldValue + "" }
                        ]
                    }
                }
            });

        }

        else if (name === "qty") {
            const quantity = event.target.value;
            let itemAvailabileQty = newFormData[findIndex].availabileQty

            if (parseInt(quantity) > itemAvailabileQty) return;

            newFormData[findIndex].quantity = quantity;
            setInputItems(newFormData);
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleRemoveOutputProduct(pId) {
        let products = [...outputItems];
        const items = products.filter((item) => item.productId !== pId);
        setOutputItems(items);
    }

    function handleRemoveInputProduct(pId) {
        let products = [...inputItems];
        const items = products.filter((item) => item.productId !== pId);
        setInputItems(items);
    }

    function isValid() {
        let isValidInput = inputItems.every((item) => parseInt(item.quantity) > 0);
        //let isValidOutput = (outputItems.length > 0) ? outputItems.every((item) => parseInt(item.costPrice) > 0) : false;
        let isValidOutput = outputItems.every((item) => parseInt(item.costPrice) > 0 && parseInt(item.quantity) > 0);
        let isSufficientInputQty = inputItems.every(a => parseInt(a.quantity) > a.availabileQty)

        if (isValidInput === false) {
            enqueue("error", "Input items are incorrect");
            setOpen(false)
            return 
            
        }
        if ((outputItems.length > 0) && isValidOutput === false) {
            enqueue("error", "Output items are incorrect");
            setOpen(false)
            return 
        }
        return isValidInput || isValidOutput && !isSufficientInputQty;
    }

    const handleSubmit = () => {
        if (!isValid()) return;
        let inputProductItems = inputItems.map((item) => ({
            productId: item.productId,
            quantity: parseInt(item.quantity)
        }))

        let outputProductItems = outputItems.map((item) => ({
            productId: item.productId,
            quantity: parseInt(item.quantity),
            unitPrice: parseInt(item.costPrice)
        }))

        loadMutation({
            variables: {
                "request": {
                    "inputItems": inputProductItems,
                    "outputItems": outputProductItems
                }
            }
        });

    }

    const reset = () => {
        setInputItems([])
        setOutputItems([])
        setOpen(false)
    }

    const isDisabled = () => {
        return inputItems.length <= 0 && outputItems.length <= 0;
    }

    return (
        <div>
            <Grid fluid={true}>
                <Row>
                    <Col>
                        <Header
                            style={{
                                marginBottom: 40,
                                boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                            }}
                        >
                            <Col md={4} xs={12}>
                                <Heading>Stock Transformation</Heading>
                            </Col>
                        </Header>
                        <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                            <Grid style={{ padding: 10 }}>
                                <Row>
                                    <Col md={6}>
                                        <Heading style={{ margin: 20, fontSize: 20, color: "#617A55", fontFamily: "Helvetica Neue" }}>Add Input Products</Heading>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Col md={8}>
                                            <Select
                                                options={productOptions || []}
                                                labelKey="label"
                                                valueKey="value"
                                                //value={selectInputItems}
                                                searchable={true}
                                                onChange={(e) => handleSearchInputProduct(e)}
                                                onInputChange={handleAutoComplete}
                                            />
                                        </Col>
                                    </Col>
                                </Row>
                                {
                                    inputItems.length > 0 && <>
                                        <Row style={{ marginBottom: 0, marginLeft: 20 }}>
                                            <Col md={8} ><b>Product Name/Code</b></Col>
                                            <Col md={2}><b>Quantity</b></Col>
                                        </Row>
                                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                                        {
                                            inputItems.map((item, i) =>
                                                <Row style={{ marginLeft: 20 }}>
                                                    <Col md={8}>
                                                        <Input type="text"
                                                            name="name"
                                                            value={item.productName}
                                                        />
                                                    </Col>

                                                    <Col md={2}>
                                                        <Input type="number"
                                                            name="qty"
                                                            value={item.quantity}
                                                            onChange={(e) => { handleInputProdcutChange(e, "qty", item.productId) }}
                                                        />
                                                        {item.availabileQty > 0 &&
                                                            <p className={css({ fontSize: '12px', marginTop: '0px' })}>Available <span className={css({ fontWeight: 'bold', color: 'green' })}>{`${item.availabileQty + ""}`}</span> {`(${item.measureUnint})`}</p>
                                                        }
                                                    </Col>

                                                    <Col md={1} style={{ paddingTop: 10 }} >
                                                        <Button
                                                            size={SIZE.mini}
                                                            shape={SHAPE.circle}
                                                            type="button"
                                                            onClick={() => handleRemoveInputProduct(item.productId)}
                                                            overrides={{
                                                                BaseButton: {
                                                                    style: ({ $theme }) => ({
                                                                        backgroundColor: $theme.colors.red400,
                                                                    }),
                                                                },
                                                            }}
                                                        >
                                                            <CgClose title="Remove" style={{ fontSize: 'larger' }} />
                                                        </Button>
                                                    </Col>
                                                </Row>)}
                                    </>}
                            </Grid>

                            <Grid style={{ padding: 10 }}>
                                <Row>
                                    <Col md={3}>
                                        <Heading style={{ margin: 20, fontSize: 20, color: "#617A55", fontFamily: "Helvetica Neue" }}> Add Output Products</Heading>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Col md={8}>
                                            <Select
                                                options={productWithOutStockOptions || []}
                                                labelKey="label"
                                                valueKey="value"
                                                //value={selectInputItems}
                                                searchable={true}
                                                onChange={(e) => handleAddOutputProduct(e)}
                                                onInputChange={handleAutoCompleteWithoutStock}
                                            />
                                        </Col>
                                    </Col>
                                </Row>

                                {
                                    outputItems.length > 0 && <>
                                        <Row style={{ marginBottom: 0, marginLeft: 20 }}>
                                            <Col md={6} ><b>Product Name/Code</b></Col>
                                            <Col md={2}><b>Quantity</b></Col>
                                            <Col md={2}><b>Cost Price({CURRENCY})</b></Col>
                                        </Row>
                                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                                        {
                                            outputItems.map((item, i) =>
                                                <Row key={i} style={{ marginLeft: 20, paddingBottom: 20 }}>
                                                    <Col md={6}>
                                                        <Input type="text"
                                                            name="name"
                                                            value={item.productName}
                                                        />
                                                    </Col>

                                                    <Col md={2}>
                                                        <Input type="number"
                                                            name="qty"
                                                            value={item.quantity}
                                                            onChange={(e) => { handleOutputProdcutChange(e, "qty", item.productId) }}
                                                        />
                                                    </Col>

                                                    <Col md={2}>
                                                        <Input type="number"
                                                            name="costPrice"
                                                            value={item.costPrice}
                                                            onChange={(e) => { handleOutputProdcutChange(e, "costPrice", item.productId) }}
                                                        />
                                                    </Col>

                                                    <Col md={1} style={{ paddingTop: 10 }} >
                                                        <Button
                                                            size={SIZE.mini}
                                                            shape={SHAPE.circle}
                                                            type="button"
                                                            onClick={() => handleRemoveOutputProduct(item.productId)}
                                                            overrides={{
                                                                BaseButton: {
                                                                    style: ({ $theme }) => ({
                                                                        backgroundColor: $theme.colors.red400,
                                                                    }),
                                                                },
                                                            }}
                                                        >
                                                            <CgClose title="Remove" style={{ fontSize: 'larger' }} />
                                                        </Button>
                                                    </Col>
                                                </Row>)
                                        }
                                    </>}

                                <Row>
                                    <Col md={8}></Col>
                                    <Col md={4}>
                                        <Button
                                            onClick={() => setOpen(true)}
                                            disabled={isDisabled()}
                                            overrides={{
                                                BaseButton: {
                                                    style: () => ({
                                                        width: '80%',
                                                        borderTopLeftRadius: '3px',
                                                        borderTopRightRadius: '3px',
                                                        borderBottomLeftRadius: '3px',
                                                        borderBottomRightRadius: '3px',
                                                    }),
                                                },
                                            }}
                                        >
                                            Transform
                                        </Button>
                                    </Col>
                                </Row>

                                <Modal isOpen={open} onClose={handleClose}>
                                    <ModalBody sx={{ overflow: "visible", minHeight: "50vh", minWidth: '59.7vh' }}>
                                        <Row spacing={3} justifyContent="center">
                                            <Col md={12} textAlign='center'>
                                                <h3>Are you transform stock?</h3>
                                            </Col>
                                        </Row>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Row style={{ justifyContent: 'end', marginBottom: 20, marginRight: 5, marginTop: 0 }}>
                                            <Col>
                                                <Button
                                                    overrides={{
                                                        BaseButton: {
                                                            style: ({ $theme }) => ({
                                                                backgroundColor: $theme.colors.red400,
                                                            }),
                                                        },
                                                    }}
                                                    onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                            </Col>

                                            <Col>
                                                <Button
                                                    disabled={loading}
                                                    onClick={() => {
                                                        handleSubmit()
                                                    }}
                                                >Save</Button>
                                            </Col>
                                        </Row>
                                    </ModalFooter>

                                </Modal>
                            </Grid>
                        </Wrapper>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default StockTransform;

