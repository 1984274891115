function parseBaseUrl() {
   const host = window.location.host;
   if (host.startsWith('localhost')) {
      return 'https://192.168.88.36:44386/';
   } else {
      let arr = window.location.host.split('.');
      if (host.endsWith('azurewebsites.net')) {
         arr[0] = arr[0] + '-api';
      } else {
         arr[0] = 'api-' + arr[0];
      }
      return `${window.location.protocol}//${arr.join('.')}/`;
   }
}

export const THERMAL_PRINTER_SERVICE_URL = 'http://localhost:3456/api/printer';
//export const THERMAL_PRINTER_SERVICE_URL = 'http://127.0.0.1:9876/api/printer'; //for xeon mobile printer service

export const ClientListForA4 = [
   {
      host: 'dentfiftytwolimited.xeonsoftware.com',
      apiUrl:
         'https://api-dentfiftytwolimited.xeonsoftware.com/report/sales-invoice/',
   },
   {
      host: 'gadgetsultan.xeonsoftware.com',
      apiUrl: 'https://api-gadgetsultan.xeonsoftware.com/report/sales-invoice/',
   },
];

export const API_URL = `${parseBaseUrl()}graphql`;
export const BASE_API_URL = `${parseBaseUrl()}`;
