import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars';
import Input from 'components/Input/Input';
import { Button, SHAPE } from 'baseui/button';
import DrawerBox from 'components/DrawerBox/DrawerBox';
import { Row, Col } from 'components/FlexBox/FlexBox';
import { Form } from '../../DrawerItems/DrawerItems.style';
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { Select, SIZE } from "baseui/select";
import { CgClose } from "react-icons/cg";
import { Header, Heading } from 'components/Wrapper.style';


type Props = any;

const PRODUCT_QUERY = gql`query($query: QueryParams!){
    productQuery{
        products(query: $query){
            items{
                id
                title
                code
                measureUnit
            }
        }
    }
}`;

const STOCK_QUERY = gql`query($query: QueryParams!){
    stockQuery{
        available(query: $query){
            quantity
            product{
                id
                measureUnit
                code
                title
                salesPrice
                priceExtensions{
                    active
                    amount
                    amountType
                    priceType
                }
            }
        }
    }
}`

const TRANSFER_BRANCH_QUERY = gql`
query{
    branchQuery{
      transferBranches{
        fromBranches{
          id
          name
        }
        toBranches{
          id
          name
        }
      }
    }
  }`


const SendProduct: React.FC<Props> = () => {
    const { register, handleSubmit, getValues, setValue, control } = useForm();
    const enqueue = useCustomSnackBar();
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [fromOptions, setFormOptions] = useState([]);
    const [from, setFrom] = useState([]);
    const [toOptions, setToOptions] = useState([]);
    const [to, setTo] = useState([]);
    const [fromId, setFromId] = useState(0);
    const [toId, setToId] = useState(0);
    const [quantity, setQuantity] = useState([]);
    const [product, setProduct] = useState(null);


    const { data: branches } = useQuery(TRANSFER_BRANCH_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.branchQuery && data.branchQuery.transferBranches && data.branchQuery.transferBranches.fromBranches) {
                const from = data.branchQuery.transferBranches.fromBranches.map((item) => ({ label: item.name, value: item.id }))
                setFormOptions(from)
            }
            if (data && data.branchQuery && data.branchQuery.transferBranches && data.branchQuery.transferBranches.toBranches) {
                const to = data.branchQuery.transferBranches.toBranches.map((item) => ({ label: item.name, value: item.id }))
                setToOptions(to)
            }
        },
        onError: (err) => {
            console.log(err)
        }
    });

    const [loadStocks] = useLazyQuery(STOCK_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.stockQuery && data.stockQuery.available) {
                const stock = data.stockQuery.available[0];
                // const discount = (stock.product.priceExtensions?.find(a => a.priceType === "DISCOUNT" && a.active === true) || { amount: 0, amountType: "" })
                // TODO: Later when inclusive/exclusive discount is implemented, the salesPrice would be provided by the server
                setInvoiceItems([...invoiceItems, {
                    productId: stock.product.id,
                    title: stock.product.title,
                    // salesPrice: stock.product.salesPrice,
                    measureUnit: stock.product.measureUnit,
                    rem: stock.quantity,
                    code: stock.product.code,
                    // vat: (stock.product.priceExtensions?.find(a => a.priceType === "VAT" && a.active === true) || { amount: 0 }).amount,
                    // discountType: discount.amountType
                }]);
            }
        }
    });

    const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY, {
        onCompleted: (data) => {
            if (data && data.productQuery && data.productQuery.products && data.productQuery.products.items) {
                const opts = data.productQuery.products && data.productQuery.products.items.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))
                if (opts.length > 0) setProductOptions(opts)
            }
        }
    });


    function handleAutoComplete(e) {
        const val = e.target.value.toLowerCase();
        if (val.length < 2) return;
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 5,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val,
                    params: [
                        {
                            key: "checkStock",
                            value: "true"
                        }
                    ]
                }
            }
        });
    }

    function handleProductAdd(value) {
        setProduct(value)
        const product = value[0];
        if (invoiceItems.find(a => a.productId === product.value)) return;
        loadStocks({
            variables: {
                query: {
                    params: [
                        { key: "summary", value: "true" },
                        { key: "directSalesPrice", value: "true" },
                        { key: "orgUnitId", value: from[0].value.toString() },
                        { key: "productId", value: product.value + "" }
                    ]
                }
            }
        });
    }

    function handleSalesItemRemove(index) {
        const items = invoiceItems.filter((_, i) => i !== index);
        setInvoiceItems(items);
    }

    const handleFromChange = (e: any) => {
        if (e.type === "clear") {
            setFromId(0)
            setFrom([])
            return;
        }
        setFromId(e.option.value);
        setFrom(e.value)
    }

    const handleChange = (e) => {
        if (e.type === "clear") {
            setToId(0)
            setTo([])
        }
        setToId(e.option.value)
        setTo(e.value)
    }

    console.log("InvoiceItems", invoiceItems);

    const onSubmit = (data) => {
        console.log("data", data)
    }


    return (
        <Scrollbars
            autoHide
            renderView={(props) => (
                <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
            )}
            renderTrackHorizontal={(props) => (
                <div
                    {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal"
                />
            )}
        >
            <DrawerBox>
                <Header style={{ marginBottom: 0, boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)', }}>
                    <Col md={4}>
                        <Heading>Send Product</Heading>
                    </Col>

                </Header>
            </DrawerBox>

            <Form style={{ marginTop: 15 }}>
                <DrawerBox>
                    <Row>
                        {(fromId !== 0 && toId !== 0) && (fromId !== toId) &&
                            <Col md={4}>
                                <FormFields>
                                    <FormLabel>Product Name/Code</FormLabel>
                                    <Select
                                        options={productOptions || []}
                                        labelKey="label"
                                        valueKey="value"
                                        value={product}
                                        searchable={true}
                                        onChange={({ value }) => handleProductAdd(value)}
                                        onInputChange={handleAutoComplete}
                                        size={SIZE.compact}
                                    />
                                </FormFields>
                            </Col>
                        }
                        <Col md={3}>
                            <FormFields>
                                <FormLabel>From</FormLabel>
                                <Select
                                    options={fromOptions || []}
                                    labelKey="label"
                                    valueKey="value"
                                    value={from}
                                    searchable={true}
                                    onChange={handleFromChange}
                                />
                            </FormFields>
                        </Col>

                        <Col md={3}>
                            <FormFields>
                                <FormLabel>To</FormLabel>
                                <Select
                                    options={toOptions || []}
                                    labelKey="label"
                                    valueKey="value"
                                    value={to}
                                    searchable={true}
                                    onChange={handleChange}
                                />
                            </FormFields>
                        </Col>
                    </Row>

                    {invoiceItems.length > 0 && <>
                        <Row style={{ marginTop: 50 }}>
                            <Col md={3}><b>Product Name</b></Col>
                            <Col md={2}><b>Available</b></Col>
                            <Col md={2}><b>Quantity</b></Col>
                        </Row>
                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                        {invoiceItems.map((item, i) =>
                            <Row key={item.productId}>
                                <Col md={3}
                                    style={{ alignSelf: 'center' }}>{item.title} {item.code ? `(${item.code})` : ""}
                                </Col>
                                <Col md={2} style={{ alignSelf: 'center' }}>{item.rem} {`(${item.measureUnit})`}</Col>
                                <Col md={2}>
                                    <Input
                                        inputRef={register()}
                                        type="number"
                                        name={`invoiceItems.[${i}].quantity`}
                                        onChange={(e) => {
                                            const val = parseFloat(e.target.value) || 0;
                                            if (val > item.rem) {
                                                setValue(`invoiceItems.[${i}].quantity`, item.rem);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={1} style={{ alignSelf: 'center' }}>
                                    <Button
                                        size={SIZE.mini}
                                        shape={SHAPE.circle}
                                        type="button"
                                        onClick={() => handleSalesItemRemove(i)}
                                    >
                                        <CgClose style={{ fontSize: 'larger' }} />
                                    </Button>
                                </Col>
                            </Row>)}

                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />
                        <Row>
                            <Col md={2}>
                                <Button
                                    type="button"
                                    // isLoading={submitting}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }),
                                        },
                                    }}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </>}
                </DrawerBox>
            </Form>
        </Scrollbars>
    );
};

export default SendProduct;
