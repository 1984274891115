import React from 'react';
import {Checkbox as BaseCheckbox} from 'baseui/checkbox';
import {STYLE_TYPE} from 'baseui/checkbox';

export const LABEL_PLACEMENT = Object.freeze({
    top: 'top',
    right: 'right',
    bottom: 'bottom',
    left: 'left',
});

const Checkbox = ({checked, ...props}) => {
    return <BaseCheckbox
        checkmarkType={STYLE_TYPE.toggle}
        checked={checked}
        {...props}
        overrides={{
            Toggle: {
                style: ({$theme}) => ({
                    backgroundColor: checked ? "green" : "red"
                })
            },
            ToggleTrack: {
                style: ({$theme}) => ({
                    backgroundColor: checked ? "lightgreen" : "pink"
                })
            },
            Label: {
                style: ({ $theme }) => ({
                    color: "#414885"
                })
            },
            ...props.overrides,
        }}
    />;
};

export default Checkbox;
