import React from 'react';
import Button from 'components/Button/Button';
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { Pagination } from "baseui/pagination";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { AiFillPrinter } from "react-icons/ai";
import { SHAPE } from "baseui/button";

import {
    TableWrapper,
    SmallCell,
    SmallHeadCell,
    StyledBody,
    StyledCell,
    StyledHead,
    StyledHeadCell
} from "./Datatable.style";
import { StyledTable, StyledRow } from "baseui/table";
import { ChevronDown, ChevronUp } from "baseui/icon"
import Input, { SIZE } from "../Input/Input";
import { StyledSpinnerNext as Spinner } from "baseui/spinner";
import { Row, Col } from 'components/FlexBox/FlexBox';
import { Link } from 'react-router-dom';
import { type } from 'os';
import { parse } from 'querystring';


function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    const count = preFilteredRows.length;

    return (
        <Input
            size={SIZE.mini}
            clearable
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search...`}
            overrides={{
                Input: {
                    style: ({ $theme }) => {
                        return {
                            height: 25,
                        };
                    },
                },
            }}
        />
    );
}

const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: DefaultColumnFilter
}

const filterTypes = {
    // Add a new fuzzyTextFilterFn filter type.
    //fuzzyText: fuzzyTextFilterFn,
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => {
        return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true;
        });
    }
};

export default function CustomDataTable({ columns, data, loading, showPagination = true, canPrint = false, title = "", initialState = {} }) {
    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes, initialState }, useFilters, useSortBy, usePagination)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = tableInstance;
    const handlePrint = () => {

        const doc = new jsPDF();

        doc.setLineWidth(2);
        doc.text(title, 80, 25);

        autoTable(doc, {
            head: [columns.map((colum) => colum.Header)],
            //body: page.map((row) => row.cells.map((cell) => cell.value?.props ? cell.value?.props?.children : cell.value)),
            body: data.map(d => columns.map(c => d[c.accessor]?.props ? d[c.accessor]?.props?.children : d[c.accessor])),
            startY: 30
        });
        //doc.save('table.pdf');
        //doc.autoPrint({variant: 'non-conform'});
        var w = doc.output("bloburi");
        window.open(w);
    };

    return (
        <TableWrapper>

            <Row>
                <Col md={11} >
                    <h3>{title}</h3>
                </Col>
                {canPrint && <Col md={1} style={{ marginTop: 5 }} >
                    <Button shape={SHAPE.pill} onClick={handlePrint}   ><AiFillPrinter /></Button>
                </Col>}
            </Row>
            <StyledTable {...getTableProps()}>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <StyledHead $width="100%" {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map((column, i) => (
                                    // Apply the header cell props
                                    <SmallHeadCell key={i}>
                                        <div>
                                            <div
                                                style={{ marginBottom: 10 }}>{column.canFilter ? column.render("Filter") :
                                                    <b>&nbsp;</b>}</div>
                                            <div {...column.getHeaderProps(column.getSortByToggleProps())}>{// Render the header
                                                column.render('Header')}
                                                <span>
                                                    {column.isSorted ? column.isSortedDesc ? <ChevronDown /> :
                                                        <ChevronUp /> : ''}
                                                </span></div>
                                        </div>
                                    </SmallHeadCell>
                                ))}
                        </StyledHead>
                    ))
                }

                <StyledBody $width="100%" {...getTableBodyProps()}>
                    {
                        loading &&
                        <div style={{ width: "fit-content", margin: "auto" }}>
                            <Spinner />
                        </div>
                    }
                    {
                        !loading && page.length === 0 &&
                        <h3 style={{ textAlign: "center" }}>No Data Found</h3>
                    }
                    {
                        page.map(row => {
                            // Prepare the row for display
                            prepareRow(row)
                            return (
                                // Apply the row props
                                <StyledRow {...row.getRowProps()}>
                                    {// Loop over the rows cells
                                        row.cells.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <SmallCell {...cell.getCellProps()}>
                                                    {// Render the cell contents
                                                        cell.render('Cell')}
                                                </SmallCell>
                                            )
                                        })}
                                </StyledRow>
                            )
                        })
                    }
                </StyledBody>
            </StyledTable>
            {
                showPagination && <Pagination
                    numPages={pageCount}
                    currentPage={pageIndex + 1}
                    onPageChange={({ nextPage }) => {
                        gotoPage(
                            Math.min(Math.max(nextPage, 1), pageCount) - 1
                        );
                    }}
                />
            }
            <div>
            </div>

        </TableWrapper>
    )

}
