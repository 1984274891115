import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars';
import Input from 'components/Input/Input';
import { Button, SHAPE } from 'baseui/button';
import DrawerBox from 'components/DrawerBox/DrawerBox';
import { Row, Col } from 'components/FlexBox/FlexBox';
import { Form } from '../DrawerItems/DrawerItems.style';
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import useCustomSnackBar from "../../hooks/useCustomSnackBar";
import { DatePicker } from 'baseui/datepicker';
import { Clients } from "../../settings/accountType";
import { Select, SIZE } from "baseui/select";
import { CURRENCY, ORDER_INVOICE } from "../../settings/constants";
import { CgClose } from "react-icons/cg";
import { useHistory, useLocation } from 'react-router-dom';
import { SystemSettings } from "../../settings/common";
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { useStyletron } from "baseui";
import MultipleProductModal from './SalesComponent/MultipleProductModal';
import { SALES_INV_DETAILS } from './SalesInvoiceDetails';
import { Header, Heading } from 'components/Wrapper.style';
import { GetProductVariationId } from 'utils/inventory';

type Props = any;
const CLIENT_MUTATION = gql`mutation($account: AccountInput!){
    account{
        addorUpdate(account: $account){
            success
            data{
                id
            }
        }
    }
}`;

const CLIENT_QUERY = gql`
    query($query: QueryParams!){
        accountQuery{
            accounts(query: $query){
                id
                willHaveChild
                name
                childs{
                    id
                    name
                    openingBalance
                    contact{
                        notes
                        place
                        number
                    }
                }
            }
        }
    }
`;

const PRODUCT_QUERY = gql`query($query: QueryParams!){
    productQuery{
        products(query: $query){
            items{
                id
                title
                code
                measureUnit
            }
        }
    }
}`;

const STOCK_QUERY = gql`query($query: QueryParams!){
    stockQuery{
        availableOrderProduct(query: $query){
            quantity
            upcomingQuantity
            product{
                id
                measureUnit
                code
                title
                salesPrice
                priceExtensions{
                    active
                    amount
                    amountType
                    priceType
                }
            }
        }
    }
}`;

const PRODUCT_QUERY_WITH_VARIATION = gql`
query($query: QueryParams!) {
  productQuery {
    pocketXProducts(query: $query) {
      items {
        id
        title
        code
        measureUnit
        productVariations {
          productId
          variationId
          variationValueId
          permutationId
        }
        variations {
          id
          name
          variationValues {
            id
            variationId
            value
          }
        }
      }
    }
  }
}`

const MUTATION = gql`
mutation($order: SalesInvoiceDTORequestType!){
    sales{
      addOrder(order:$order){
        success
      }
    }
  }`;

type QuotationInfoType = { productId: 0, quantity: 0, discount: 0, vat: 0 }
const colHeaderStyle = { fontSize: "0.9vw" }
let isQuotation = false;

const AddOrder: React.FC<Props> = () => {
    const { register, handleSubmit, getValues, setValue, control } = useForm();
    const enqueue = useCustomSnackBar();
    const history = useHistory();
    const loc: any = useLocation()
    const [css] = useStyletron();
    const [isRoute, setIsRoute] = useState(false);
    const [salesDate, setSalesDate] = React.useState(loc.state?.start ? [new Date(loc.state?.start)] : [new Date()]);
    const [client, setClient] = useState("");
    const [clientId, setClientId] = useState(0);
    const [phone, setPhone] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [isNewClient, setIsNewClient] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isSalesAllowed, setIsSalesAllowed] = useState("false"); //can sale if quantity is insufficient
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [isOpenMultiple, setIsOpenMultiple] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [previousProducts, setPreviousProducts] = useState([]);
    const [invoiceNote, setInvoiceNote] = useState("")
    const [address, setAddress] = useState("");
    const [productName, setProductName] = useState("")
    const [count, setCount] = useState(1);
    const [quotationInfo, setQuotationInfo] = useState<QuotationInfoType[]>([]);

    useEffect(() => {
        let settings = SystemSettings.get().AllowSalesWithoutStock || "false";
        if (settings) {
            setIsSalesAllowed(settings.toString().toLowerCase() + "")
        }
        if (loc.state) {
            loadQuotationById({
                variables: {
                    query: {
                        "id": loc.state.id
                    }
                }
            })
        }
    }, [])

    const [loadQuotationById] = useLazyQuery(SALES_INV_DETAILS,
        {
            fetchPolicy: "network-only",
            onCompleted: (dt) => {
                let note = dt && dt.salesQuery && dt.salesQuery.salesInvoice && dt.salesQuery.salesInvoice.note;
                setInvoiceNote(note);
                let client = dt && dt.salesQuery && dt.salesQuery.salesInvoice && dt.salesQuery.salesInvoice.client;
                let clientOption = [{
                    label: `${client.contact.number} - ${client.name}`,
                    name: client.name,
                    address: client?.contact?.place,
                    value: client.id
                }]
                setPhone(clientOption);
                setClient(clientOption[0].value > 0 ? clientOption[0].name : "");
                setAddress(clientOption[0].value > 0 ? clientOption[0].address : "");
                let invItems = dt && dt.salesQuery && dt.salesQuery.salesInvoice && dt.salesQuery.salesInvoice.invoiceItems;
                let productIds = invItems ? invItems.map((a: any) => a.product.id) : [];
                let quotationInfo: QuotationInfoType[] = invItems && invItems.map((a: any) => ({
                    productId: a.product.id,
                    quantity: a.quantity,
                    discount: a.discount,
                    vat: a.vat
                }));
                setQuotationInfo(quotationInfo);
                isQuotation = true;
                if (productIds.length > 0) {
                    loadStocks({
                        variables: {
                            query: {
                                params: [
                                    { key: "showZeroStock", value: isSalesAllowed },
                                    { key: "summary", value: "true" },
                                    { key: "onlyAllowedOrgs", value: "true" },
                                    { key: "directSalesPrice", value: "true" },
                                    { key: "productIds", value: JSON.stringify(productIds) }
                                ]
                            }
                        }
                    });
                }
            },
            onError: (e) => {

            }
        });

    // Reduce total price if invoice item is reduced
    useEffect(() => {
        adjustTotal(true);
    }, [invoiceItems.length])

    const { data: clients } = useQuery(CLIENT_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            query: {
                params: [
                    {
                        "key": "parentId",
                        "value": Clients + ""
                    },
                    {
                        "key": "onlyLeaf",
                        "value": "true"
                    },
                    {
                        "key": "forContact",
                        "value": "true"
                    },
                    {
                        "key": "filter",
                        "value": "clients"
                    }
                ]
            }
        }
    });

    const [loadStocks] = useLazyQuery(STOCK_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.stockQuery && data.stockQuery.availableOrderProduct.length === 1) {
                const stock = data.stockQuery.availableOrderProduct[0];
                const discount = (stock.product.priceExtensions?.find(a => a.priceType === "DISCOUNT" && a.active === true) || { amount: 0, amountType: "" })
                const vat = (stock.product.priceExtensions?.find(a => a.priceType === "VAT" && a.active === true) || { amount: 0, amountType: "" })
                // TODO: Later when inclusive/exclusive discount is implemented, the salesPrice would be provided by the server

                // ============== previous code
                // setInvoiceItems([...invoiceItems, {
                //     productId: stock.product.id,
                //     title: stock.product.title,
                //     salesPrice: stock.product.salesPrice,
                //     measureUnit: stock.product.measureUnit,
                //     rem: stock.quantity,
                //     upComingQuantity: stock.upcomingQuantity,
                //     code: stock.product.code,
                //     vat: itemAmountCalculate(1, vat.amount, vat.amountType, (stock.product.salesPrice -
                //         itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice))).toFixed(2),
                //     discount: itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice),
                //     discountType: discount.amountType
                // }]);
                // ==============

                let allInvoiceItems: any = [...invoiceItems]
                let invItems: any = allInvoiceItems.filter((a: any) => a.productId == stock.product.id);

                let invItem = null;

                if (stock.variationId > 0) {
                    invItem = invItems.find((a: any) => a.productId === stock.product.id && a.permutationId === stock.variationId)
                } else {
                    invItem = invItems.find((a: any) => a.productId === stock.product.id)
                }

                invItem.rem = stock.quantity;
                invItem.salesPrice = stock.product.salesPrice;
                invItem.vat = itemAmountCalculate(1, vat.amount, vat.amountType, (stock.product.salesPrice -
                    itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice))).toFixed(2);
                invItem.discount = itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice);
                invItem.discountType = discount.amountType;
                invItem.upComingQuantity = stock.upcomingQuantity;

                let itemInd = allInvoiceItems.indexOf(a => a.sl === invItem.sl)
                allInvoiceItems[itemInd] = invItem

                setInvoiceItems(allInvoiceItems);
            }
        },
        onError: (e) => {
            console.log(e.message);
        }
    });


    const [loadClientMutation, { loading: Clientsubmitting }] = useMutation(CLIENT_MUTATION, {
        onCompleted: (data) => {
            if (data && data.account && data.account.addorUpdate && data.account.addorUpdate.success) {
                enqueue("success", "Client SuccessFully Added");
                setClientId(data.account.addorUpdate.data.id);
                setIsNewClient(false);
            }
            //resetClientState();
        },

        onError: () => {
            enqueue("error", "Client Couldn't be added, please try again later");
        }
    });

    const [loadMutation, { loading: submitting }] = useMutation(MUTATION, {
        onCompleted: (data) => {
            if (data && data.sales && data.sales.addOrder && data.sales.addOrder.success) {
                enqueue("success", "Order Successfully Generated");
                history.push(ORDER_INVOICE);
                reset()
            } else {
                enqueue("error", "Order couldn't be generated. Please try again later.");
            }
        },
        onError: (e) => {
            enqueue("error", "Order couldn't be generated. Please try again later.");
        }
    });

    const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY_WITH_VARIATION, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            let res = data?.productQuery?.pocketXProducts?.items
            if (res) {
                const opts = res.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))
                const formatProd = res.map(a => ({
                    productId: a.id,
                    title: a.title + (a.code ? ` (${a.code})` : ""),
                    isSelected: false
                }))
                if (opts.length > 0) {
                    setAllProducts(formatProd);
                    setPreviousProducts(formatProd);
                    setProductOptions(opts);
                }
            }
        }
    });

    function handleAutoComplete(e) {
        const val = e.target.value.toLowerCase();
        if (val.length < 2) return;
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 20,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val,
                    params: [
                        {
                            key: "checkStock",
                            value: isSalesAllowed === "true" ? "false" : "true"
                        }
                    ]
                }
            }
        });
    }

    function handleProductAdd(value) {
        setProductName(value[0].label)
        const product = value[0];

        const productList = products?.productQuery?.pocketXProducts?.items || [];

        let selectedItem = productList.find(a => a.id === product.value) || null;
        if (!selectedItem) return;

        const currentCount = count + 1
        setCount(currentCount);

        let items = [...invoiceItems];
        let item = {
            sl: currentCount,
            productId: product.value,
            title: product.label,
            rem: 0,
            measureUnit: selectedItem.measureUnit || "",
            productVariations: selectedItem.productVariations,
            variations: selectedItem.variations,
            selectedVariations: [],
            permutationId: 0,
            quantity: '',
            salesPrice: '',
            upComingQuantity: 0
        }

        items.push(item);
        setInvoiceItems(items);

        if (selectedItem.variations.length === 0) {
            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "showZeroStock", value: isSalesAllowed },
                            { key: "summary", value: "true" },
                            { key: "onlyAllowedOrgs", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productId", value: product.value + "" },
                            { key: "includeVariationName", value: "true" }
                        ]
                    }
                }
            });
        }
    }

    const handleChangeVariation = (sl: number, value: any, variationId: number) => {
        let items = [...invoiceItems]
        let item = items.find(a => a.sl == sl);
        if (!item) return;


        //resetting
        item.rem = 0;
        item.salesPrice = 0;
        item.vat = 0;
        item.discount = 0;
        item.discountType = null;
        item.permutationId = 0;
        item.quantity = 0;


        let selectedVariations = item.selectedVariations || [];
        let isExistVariationValue = selectedVariations.some(a => a.variationId == variationId);

        if (isExistVariationValue) {
            selectedVariations = selectedVariations.filter(a => a.variationId != variationId);
            item.selectedVariations = selectedVariations;
        }

        if (value.length > 0) {
            selectedVariations.push(
                {
                    variationId: variationId,
                    variationValueId: value[0].value,
                    variationValue: value[0].label,
                    permutationId: 0
                })

            if (item.selectedVariations.length === item.variations.length) {
                let vId = GetProductVariationId(item.productVariations, item.selectedVariations)

                if (items.find(a => a.permutationId === vId) && items.find(a => a.permutationId === vId).sl !== sl) {
                    let newItems = items.filter(a => a.sl !== sl);
                    setInvoiceItems(newItems);

                    enqueue("error", "This item already added!!");
                    return
                };

                item.permutationId = vId;
                selectedVariations = selectedVariations.map(a => {
                    return {
                        ...a,
                        permutationId: vId
                    }
                })
            }
            item.selectedVariations = selectedVariations;
        }


        let ind = items.findIndex(a => a.sl == sl);
        items[ind] = item;
        setInvoiceItems(items);

        if (selectedVariations.length === item.variations.length) {
            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "showZeroStock", value: "false" },
                            { key: "summary", value: "true" },
                            { key: "onlyAllowedOrgs", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productId", value: item.productId + "" },
                            { key: "permutationId", value: item.permutationId + "" },
                            { key: "includeVariationName", value: "true" }
                        ]
                    }
                }
            });
        }
    }

    const handleChangeInvoiceItems = (value: any, name: string, index: number) => {
        let items = [...invoiceItems];
        items[index][name] = value;
        setInvoiceItems(items);

        if (name === "quantity") {
            if (value > (items[index].rem + items[index].upComingQuantity) && isSalesAllowed === "false") {
                value = items[index].rem + items[index].upComingQuantity;
                items[index][name] = value;
                setInvoiceItems(items);
            }
            adjustRowTotal(index, value, items[index].salesPrice, items[index].vat, items[index].discount);
        }
        else if (name === "salesPrice") {
            adjustRowTotal(index, items[index].quantity, value, items[index].vat, items[index].discount);
        }
        else if (name === "discount") {
            let sellPrice = items[index].salesPrice || 0;
            let vat = items[index].vat || 0;
            //if discount is more than sellPrice
            if (sellPrice - value < 2) {
                items[index][name] = sellPrice - 1;
                setInvoiceItems(items);
                value = sellPrice - 1
            }
            adjustRowTotal(index, items[index].quantity, sellPrice, vat, value)
        }
        else if (name === "vat") {
            adjustRowTotal(index, items[index].quantity, items[index].salesPrice, value, items[index].discount)
        }
    }

    function handleChange(value, setter) {
        if (value.length > 0) {
            setter(value);
            if (value[0].isCreatable) {
                setIsNewClient(true);
            }
            else {
                setIsNewClient(value[0].isCreatable)
            }
            setClient(value[0].value > 0 ? value[0].name : "");
            setAddress("");
        } else {
            setAddress("");
            setClient("")
            setPhone([])
            setIsNewClient(false)
        }
    }

    const generateClientLabel = (account) => {
        return (account.contact && account.contact.number) ? `${account.contact.number} - ${account.name}` : account.name;
    }

    const numberOptions = useMemo(() => {
        return clients && clients.accountQuery && clients.accountQuery.accounts && clients.accountQuery.accounts[0].childs ?
            clients.accountQuery.accounts[0].childs.map(a => ({ value: a.id, name: a.name, label: generateClientLabel(a) })) :
            [];
    }, [clients]);

    // function isValid() {
    //     const dataItems = invoiceItems.filter(a => a);
    //     return phone.length === 1 && salesDate.length === 1 && invoiceItems.length > 0 &&
    //         invoiceItems.filter((a, i) => dataItems[i].quantity <= 0).length === 0 &&
    //         invoiceItems.filter((a, i) => dataItems[i].sellPrice <= 0).length === 0;
    // }

    function isValid() {
        // const dataItems = invoiceItems.filter(a => a);
        let hasVariationErr = invoiceItems.reduce((a, c) => {
            let cn = c.selectedVariations.length === c.variations.length
            if (cn) {
                return a + 0
            } else {
                return a + 1
            }
        }, 0) > 0

        let isQty = invoiceItems.every(a => +a.quantity > 0);
        let isSalesPrice = invoiceItems.every(a => +a.salesPrice > 0);
        let isPhoneNumber = phone.length === 1;

        if (!isPhoneNumber) {
            enqueue("error", "Please select phone number or client");
            return false
        }

        if (hasVariationErr) {
            enqueue("error", "Please select all variations");
            return false
        }

        if (!isQty) {
            enqueue("error", "Please input quantity");
            return false
        }

        if (!isSalesPrice) {
            enqueue("error", "Please input unit price");
            return false
        }
        return true
        // phone.length === 1 && salesDate.length === 1 && invoiceItems.length > 0 &&
        // invoiceItems.filter((a, i) => dataItems[i].quantity <= 0).length === 0 &&
        // invoiceItems.filter((a, i) => dataItems[i].sellPrice <= 0).length === 0
    }

    function handleSalesItemRemove(pId) {
        let products = [...invoiceItems];
        let targetProduct = products && products.find(a => a.productId === pId);
        const items = products.filter((item) => item.productId !== targetProduct.productId);
        setInvoiceItems(items);
    }

    function getTotalPrice() {
        const items = [...invoiceItems];
        return items.map(item => {
            return (parseFloat(item.salesPrice) || 0) * (parseFloat(item.quantity) || 0);
        }).reduce((c, d) => c + d, 0)
    }
    function isClientValid() {
        return client && phone[0].value && client.length > 0 && phone[0].value.length > 0;
    }

    function handleNewClient() {
        if (!isClientValid()) {
            alert("Cannot Add Customer. Please fill up customer name");
            return;
        }

        let parentId = clients && clients.accountQuery && clients.accountQuery.accounts && clients.accountQuery.accounts ?
            clients.accountQuery.accounts[0].id : 0;

        if (parentId === 0) {
            alert("Cannot Add Customer. Please contact the support team");
            return;
        }
        const amount = 0;
        loadClientMutation({
            variables: {
                account: {
                    name: client,
                    parentId: parentId,
                    willHaveChild: isRoute,
                    contact: isRoute ? null : {
                        person: client,
                        number: phone[0].value,
                        place: address
                    },
                    openingBalanceJournal: {
                        amount: Math.abs(amount),
                        type: amount > 0 ? 2 : 1,
                        isOpeningBalance: true,
                        voucherDate: new Date()
                    }
                }
            }
        });
    }

    const onChangeConfirmationModal = () => {
        if (!isValid()) return;
        setIsOpen(true);
    }

    const onSubmit = (data) => {
        if (!isValid()) return;

        const dataItems = invoiceItems.filter(a => a);
        const inv = {
            clientId: clientId ? clientId : phone[0].value,
            salesDate: salesDate[0],
            status: "PENDING_ORDER",
            discount: getTotalDiscount(),
            vat: getTotalVat(),
            total: getTotalPrice(),
            note: invoiceNote,
            parentId: loc.state ? loc.state.id : null,
            invoiceItems: invoiceItems.map((a, i) => ({
                permutationId: a.permutationId,
                price: parseFloat(dataItems[i].salesPrice),
                quantity: parseFloat(dataItems[i].quantity),
                productId: invoiceItems[i].productId,
                discount: parseFloat(dataItems[i].discount) || 0,
                vat: parseFloat(dataItems[i].vat) || 0
            }))
        }
        //to solve previous date problem
        inv.salesDate.setHours(8)
        loadMutation({
            variables: {
                order: inv
            }
        });
    };

    function getTotalDiscount() {
        let invoiceData = [...invoiceItems];
        if (invoiceData) {
            invoiceData = JSON.parse(JSON.stringify(invoiceData))
            invoiceData = invoiceData.filter(a => a !== null)
            return invoiceItems.map((item, i) => ({ discount: item.discount, discountType: item.discountType, index: i }))
                .reduce((acc, item) => {
                    const data = invoiceData[item.index];
                    let itemDiscount = (Number(data ? data.quantity : 0)) * Number(data ? data.discount : 0)
                    return itemDiscount + acc;
                }, 0)
        } else {
            return 0;
        }
    }

    function getTotalVat() {
        let invoiceData = [...invoiceItems];
        if (invoiceData) {
            invoiceData = JSON.parse(JSON.stringify(invoiceData))
            invoiceData = invoiceData.filter(a => a !== null)
            return invoiceItems.map((item, i) => ({ discount: item.vat, discountType: item.discountType, index: i }))
                .reduce((acc, item) => {
                    const data = invoiceData[item.index];
                    let itemDiscount = (Number(data ? data.quantity : 0)) * Number(data ? data.vat : 0)
                    return itemDiscount + acc;
                }, 0)
        } else {
            return 0;
        }
    }


    function adjustTotal(recalculate = false) {
        const sum = getTotalPrice();
        let discount = parseFloat(getValues("totalDiscount")) || 0;
        let vat = parseFloat(getValues("totalVat")) || 0;
        if (recalculate) {
            discount = parseFloat(getTotalDiscount() + "");
            setValue("totalDiscount", discount.toFixed(2));
            vat = getTotalVat();
            setValue("totalVat", vat.toFixed(2));
        }
        setValue("totalPrice", getTotalPrice().toFixed(2));
        setValue("netPrice", (sum - discount + vat).toFixed(2));
    }

    function adjustRowTotal(i, qty, sellPrice, vat, discount) {
        let total = (Number(sellPrice) - Number(discount) + Number(vat)) * Number(qty)
        let items = [...invoiceItems];
        items[i].total = total.toFixed(2);
        setInvoiceItems(items);
        setValue(`invoiceItems.[${i}].rowTotal`, total.toFixed(2));
        adjustTotal(true);
    }

    function adjustTotalDiscount(val) {
        setValue(`totalDiscount`, val);
    }


    function itemAmountCalculate(qty, discount, type, sellPrice) {
        let itemVat = 0;
        if (type === "PERCENTAGE") {
            itemVat = ((Number(qty) || 0) * (Number(sellPrice) || 0)) * (discount / 100);
        } else {
            itemVat = ((Number(qty) || 0) * (Number(discount) || 0));
        }
        return itemVat;
    }

    function reset() {
        setSalesDate([new Date()]);
        setInvoiceItems([]);
        setValue("note", "")
        setValue("netPrice", "")
        setValue("totalDiscount", "")
        setValue("totalPrice", "")
        setValue("totalVat", "")
        setValue("invoiceItems", [])
        setClient("");
        setPhone([]);
        setIsOpen(false);
    }

    function cancelModal() {
        closeModal();
    }

    function closeModal() {
        setIsOpen(false);
    }
    function cancelMultipleModal() {
        closeMultipleModal();
    }

    function closeMultipleModal() {
        setIsOpenMultiple(false);
    }

    const handleMultipleSelect = () => {
        console.log("Multiple selected products here");
    }

    return (
        <Scrollbars
            autoHide
            renderView={(props) => (
                <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
            )}
            renderTrackHorizontal={(props) => (
                <div
                    {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal"
                />
            )}
        >
            <Form>
                <DrawerBox>
                    <Col md={12}>
                        <Header
                            style={{
                                marginBottom: 30,
                                marginLeft: -40,
                                boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                            }}
                        >
                            <Col md={4} xs={12}>
                                <Heading>Add Order</Heading>
                            </Col>

                            <Col md={8} xs={12}>
                            </Col>
                        </Header>

                    </Col>

                    <Row>
                        <Col md={3}>
                            <FormFields>
                                <FormLabel>Phone Number</FormLabel>
                                <Select
                                    options={numberOptions || []}
                                    labelKey="label"
                                    valueKey="value"
                                    value={phone}
                                    searchable={true}
                                    onChange={({ value }) => handleChange(value, setPhone)}
                                    creatable={true}
                                    size={SIZE.compact}
                                />
                            </FormFields>
                        </Col>
                        <Col md={3}>
                            <FormFields>
                                <FormLabel>Customer Name</FormLabel>
                                <Input
                                    inputRef={register()}
                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                    size={SIZE.compact}
                                    disabled={!isNewClient}
                                />
                            </FormFields>
                        </Col>
                        <Col md={3}>
                            <FormFields>
                                <FormLabel>Address</FormLabel>
                                <Input
                                    inputRef={register()}
                                    name="address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    size={SIZE.mini}
                                    disabled={!isNewClient}
                                />
                            </FormFields>
                        </Col>
                        {isNewClient &&
                            <Col md={1}>
                                <Button
                                    type="button"
                                    onClick={handleNewClient}
                                    isLoading={Clientsubmitting}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '60px',
                                                height: '40px',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                                marginTop: '30px'

                                            }),
                                        },
                                    }}
                                >
                                    Create
                                </Button>
                            </Col>
                        }
                        <Col md={2}>
                            <FormFields>
                                <FormLabel>Sales Date</FormLabel>
                                <DatePicker
                                    mask={null}
                                    formatString="dd-MMM-yyy"
                                    value={salesDate}
                                    onChange={({ date }) =>
                                        setSalesDate(Array.isArray(date) ? date : [date])
                                    }
                                    size={SIZE.compact}
                                />
                            </FormFields>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormFields>
                                <FormLabel>Product Name/Code</FormLabel>
                                <Select
                                    options={productOptions || []}
                                    labelKey="label"
                                    valueKey="value"
                                    value={[]}
                                    searchable={true}
                                    onChange={({ value }) => handleProductAdd(value)}
                                    onInputChange={handleAutoComplete}
                                    size={SIZE.compact}
                                />
                            </FormFields>
                        </Col>
                        <Col md={4}>
                            <FormFields>
                                <FormLabel>Invoice Note</FormLabel>
                                <Input
                                    value={invoiceNote}
                                    name="note"
                                    onChange={(e) =>
                                        setInvoiceNote(e.target.value)
                                    }
                                    size={SIZE.compact}
                                />
                            </FormFields>
                        </Col>

                        {
                            invoiceItems && invoiceItems.length > 0 &&
                            <Col md={1} style={{ marginTop: 11 }}>
                                <p style={{
                                    backgroundColor: '#25C68E',
                                    padding: 10, color: "white",
                                    borderRadius: 100,
                                    textAlign: 'center',
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>
                                    {invoiceItems.length}
                                </p>
                            </Col>
                        }
                    </Row>
                    {invoiceItems.length > 0 && <>
                        <Row style={{ marginBottom: 0 }}>
                            <Col md={2}><b>Product Name</b></Col>
                            <Col md={2}><b style={colHeaderStyle}>Variation</b></Col>
                            <Col md={2}><b>Quantity</b></Col>
                            <Col md={2}><b>Unit Sell Price({CURRENCY})</b></Col>
                            <Col md={2}><b>Discount({CURRENCY})</b></Col>
                            <Col md={1}><b>Vat({CURRENCY})</b></Col>
                            <Col md={2}><b>Total({CURRENCY})</b></Col>
                        </Row>
                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                        {invoiceItems.map((item, i) =>
                            <Row key={item.productId}>
                                <Col md={2}
                                    style={{ alignSelf: 'flex-start', marginTop: '10px' }}>{item.title} {item.code ? `(${item.code})` : ""}
                                </Col>
                                {
                                    item.variations.length > 0 ?
                                        item.variations.map((a) => {
                                            return (
                                                <Col md={1}>
                                                    <Select
                                                        options={a.variationValues.map(v => { return { label: v.value, value: v.id } }) || []}
                                                        placeholder={`${a.name}`}
                                                        labelKey="label"
                                                        valueKey="value"
                                                        value={item.selectedVariations.filter(s => s.variationId === a.id).map(v => { return { label: v.variationValue, value: v.variationValueId } }) || []}
                                                        searchable={true}
                                                        onChange={({ value }) => handleChangeVariation(item.sl, value, a.id)}
                                                        size='compact'
                                                    />
                                                </Col>
                                            )
                                        })
                                        : <Col md={2}><b>No Variation</b></Col>
                                }
                                <Col md={1}>
                                    <Input
                                        type="number"
                                        value={item.quantity || ""}
                                        onChange={(e: any) => handleChangeInvoiceItems(parseFloat(e.target.value), "quantity", i)}
                                    />
                                    <p className={css({ fontSize: '12px', marginTop: '0px' })}>Available Stock <span className={css({ fontWeight: 'bold', color: item.rem > 0 ? 'green' : 'red' })}>{`${item.rem}`}</span> {`(${item.measureUnit})`}</p>
                                    <p className={css({ fontSize: '12px', marginTop: '0px' })}>UpComing Stock <span className={css({ fontWeight: 'bold', color: item.upComingQuantity > 0 ? 'green' : 'red' })}>{`${item.upComingQuantity}`}</span> {`(${item.measureUnit})`}</p>
                                </Col>
                                <Col md={2}>
                                    <Input
                                        type="number"
                                        defaultValue={item.salesPrice}
                                        value={item.salesPrice}
                                        onChange={(e: any) => handleChangeInvoiceItems(parseFloat(e.target.value), "salesPrice", i)}
                                    />
                                </Col>
                                <Col md={1.5}>
                                    <Input
                                        type="number"
                                        defaultValue={item.discount}
                                        value={item.discount}
                                        onChange={(e: any) => handleChangeInvoiceItems(parseFloat(e.target.value), "discount", i)}
                                    />
                                </Col>
                                <Col md={1.5}>
                                    <Input
                                        type="number"
                                        defaultValue={item.vat}
                                        value={item.vat}
                                        onChange={(e: any) => handleChangeInvoiceItems(parseFloat(e.target.value), "vat", i)}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Input
                                        value={item.total || 0}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={1} style={{ alignSelf: 'flex-start', marginTop: '10px' }}>
                                    <Button
                                        size={SIZE.mini}
                                        shape={SHAPE.circle}
                                        type="button"
                                        onClick={() => handleSalesItemRemove(item.productId)}
                                    >
                                        <CgClose title="Remove" style={{ fontSize: 'larger' }} />
                                    </Button>
                                </Col>
                            </Row>)}

                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                        <Row style={{ marginBottom: 0 }}>
                            <Col md={10}><h3 style={{ float: 'right' }}><strong>Sub Total({CURRENCY})</strong></h3></Col>
                            <Col md={2}><Input
                                disabled={true}
                                inputRef={register()}
                                name="totalPrice"
                            /></Col>
                        </Row>
                        <Row style={{ marginBottom: 0 }}>
                            <Col md={10}><h3 style={{ float: 'right' }}><strong>Discount({CURRENCY})</strong></h3></Col>
                            <Col md={2}><Input
                                disabled={true}
                                inputRef={register()}
                                name="totalDiscount"
                                type="number"
                                defaultValue={0}
                                onChange={(e) => {
                                    const val = parseFloat(e.target.value) || 0;
                                    adjustTotalDiscount(val)
                                }
                                }
                            /></Col>
                        </Row>
                        <Row style={{ marginBottom: 0 }}>
                            <Col md={10}><h3 style={{ float: 'right' }}><strong>VAT({CURRENCY})</strong></h3></Col>
                            <Col md={2}><Input
                                inputRef={register()}
                                name="totalVat"
                                disabled={true}
                                type="number"
                                defaultValue={0}
                                onChange={() => adjustTotal()}
                            /></Col>
                        </Row>
                        <Row style={{ marginBottom: 0 }}>
                            <Col md={10}><h3 style={{ float: 'right' }}><strong>Total({CURRENCY})</strong></h3></Col>
                            <Col md={2}><Input
                                disabled={true}
                                inputRef={register()}
                                name="netPrice"
                            /></Col>
                        </Row>

                        <Row>
                            <Col md={2}>
                                <Button
                                    type="button"
                                    onClick={onChangeConfirmationModal}
                                    isLoading={submitting}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }),
                                        },
                                    }}
                                >
                                    Place Order
                                </Button>
                            </Col>
                        </Row>
                    </>}
                </DrawerBox>
            </Form>
            <ConfirmationModal
                isOpen={isOpen}
                modalBody={''}
                modalHeader={"Are you sure to add save this order?"}
                closeCallback={closeModal}
                cancelCallBack={cancelModal}
                submitCallback={handleSubmit(onSubmit)}
            />
            <MultipleProductModal
                isOpen={isOpenMultiple}
                closeCallback={closeMultipleModal}
                cancelCallBack={cancelMultipleModal}
                submitCallback={handleMultipleSelect}
                allProducts={allProducts}
                setAllProducts={setAllProducts}
                previousProducts={previousProducts}
            />
        </Scrollbars>
    );
};

export default AddOrder;
