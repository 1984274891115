import React, { useContext, lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
    LOGIN,
    INVENTORY,
    PURCHASE_INVOICE,
    DASHBOARD,
    SETTINGS,
    CATEGORY_SETTINGS,
    CLIENT_SETTINGS,
    BANK_SETTINGS,
    EXPENSE_SETTINGS,
    USER_SETTINGS,
    PRODUCT_SETTINGS,
    ACCOUNTING_REPORTS,
    ACCOUNTING_DETAILS,
    SUPPLIER_SETTINGS,
    TRANSACTIONS,
    CASH_RECEIVE,
    EXPENSE_ENTRY,
    SUPPLIER_PAYMENT,
    SALES,
    SALES_INVOICE,
    SALES_INVOICE_RETURN,
    PURCHASE_INVOICE_ADD,
    SALES_INVOICE_ADD,
    INVENTORY_STOCK,
    PRINT, REPORTS,
    REPORT_INVENTORY,
    VIEW_CASH_RECEIVE,
    VIEW_SUPPLIER_PAYMENT,
    VIEW_EXPENSE_ENTRY,
    GENERAL_TRANSACTIONS,
    VAT_SETTINGS,
    DISCOUNT_SETTINGS,
    SYSTEM_SETTINGS,
    PURCHASE_RETURN,
    ACCOUNTING_CASH,
    ACCOUNTING_BANK,
    ACCOUNTING_CLIENTS,
    ACCOUNTING_DISCOUNT,
    ACCOUNTING_EXPENSE,
    ACCOUNTING_SALES,
    ACCOUNTING_SALES_RETURN,
    ACCOUNTING_SUPPLIERS,
    BANK_DETAILS,
    CLIENT_DETAILS,
    EXPENSE_DETAILS,
    SUPPLIER_DETAILS,
    REPORT_SETTINGS,
    QUOTATION,
    QUOTATION_ADD,
    ASSET_MANAGEMENT,
    ADD_ASSET,
    BRANCH,
    ADD_BRANCH,
    INVENTORY_REPORT,
    SEND_PRODUCT,
    RECEIVE_PRODUCT,
    INVENTORY_LABEL_PRINT,
    INVENTORY_INVENTORY_OPENING,
    INVENTORY_AUDIT_EXECUTE,
    INVENTORY_AUDIT,
    REPORT_CLIENT_SALES,
    REPORT_TRANSACTION,
    LOW_STOCK_PRODUCTS,
    PRODUCT_TRANSFER_REQUEST,
    SALES_INVOICE_ADDITIONAL_INFO,
    USER_FEATURE_ASSIGN,
    USER_FEATURE_UNASSIGN,
    CLIENT_REPORT,
    SUPPLIER_REPORT,
    BRANCH_SUMMERY_REPORT,
    BANK_REPORT,
    ACCOUNTING_REPORT_DETAILS,
    STOCK_TRANSFORM,
    ADD_ORDER,
    ADD_PATHAO_ORDER,
    ORDER_INVOICE,
    VIEW_UPCOMING_STOCKS,
    ADD_UPCOMING_STOCKS,
    CLIENT_FEATURE_ASSIGN,
    VARIANT_SETTINGS,
    PARTHAO_ORDER_LIST,
    PARTHAO_ORDER_ADD,
    COMPLETE_PROCESS,
    CREATE_FORMULA,
    CREATE_PROCESS,
    MANUFACTURING,
    VIEW_CALENDAR,
    VIEW_FORMULAS,
    VIEW_PROCESSES,
    CUSTOM_ORDER,
    VIEW_DELIVERY_ORDERS,
    VIEW_INVOICE_CLOSING
} from 'settings/constants';
import AuthProvider, { AuthContext } from 'context/auth';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import SalesInvoiceReturn from 'containers/Sales/SalesInvoiceReturn';
import AccCash from 'containers/Accounting/AccountingReports/Cash';
import AccountingBank from 'containers/Accounting/AccountingReports/Bank';
import AccSuppliers from 'containers/Accounting/AccountingReports/Suppliers';
import AccExpense from 'containers/Accounting/AccountingReports/Expense';
import AccSales from 'containers/Accounting/AccountingReports/Sales';
import AccountingClients from 'containers/Accounting/AccountingReports/Clients';
import AccDiscount from 'containers/Accounting/AccountingReports/Discount';
import AccSalesReturn from 'containers/Accounting/AccountingReports/SalesReturn';
import BankDetails from 'containers/Accounting/BankDetails';
import ClientDetails from 'containers/Accounting/ClientDetails';
import ExpenseDetails from 'containers/Accounting/ExpenseDetails';
import SupplierDetails from 'containers/Accounting/SupplierDetails';
import ReportSettings from 'containers/Settings/Report/ReportSettings';
import AssetManagement from 'containers/Accounting/AccountingReports/AssetManagement';
import AddAsset from 'containers/Accounting/AccountingReports/AddAsset';
import ViewBranch from 'containers/Branch/ViewBranch';
import Branch from 'containers/Branch/Branch';
import ViewStockReport from 'containers/Inventory/Stock/ViewStockReport';
import SendProduct from 'containers/Inventory/Transfer/SendProduct';
import ReceiveProduct from 'containers/Inventory/Transfer/ReceiveProduct';
import ClientSalesReport from 'containers/Reports/ClientSalesReport';
import TransactionReport from 'containers/Reports/TransactionReport';
import LowStockReport from 'containers/Reports/LowStockReport';
import ProductTransferRequest from 'containers/Inventory/Transfer/ProductTransferRequest';
import AuditList from 'containers/Inventory/Audit/AuditList';
import InventoryAuditExecute from 'containers/Inventory/Audit/InventoryAuditExecute';
import { DashboardIcon } from "./assets/icons/DashboardIcon";
import { SettingIcon } from "./assets/icons/SettingIcon";
import {
    AiFillAccountBook,
    AiFillCarryOut,
    AiOutlineAreaChart,
    AiOutlineTransaction,
} from "react-icons/ai";
import { FaHandHoldingUsd } from "react-icons/fa";
import {GiExpense, GiProcessor} from "react-icons/gi";
import {FcProcess} from "react-icons/fc";
import {VscServerProcess, VscSymbolMethod} from "react-icons/vsc";
import InventoryIcon from "./assets/icons/Inventory";
// import ClientReport from 'containers/Accounting/AccountingReports/ClientReport';
// import SupplierReport from 'containers/Accounting/AccountingReports/SupplierReport';
import SalesInvoiceAdditionalInfo from 'containers/Sales/SalesInvoiceAdditionalInfo';
import { Auth, UserFeatures } from "./settings/common";
import BankReport from 'containers/Accounting/AccountingReports/BankReport';
import AccountReportDetails from 'containers/Accounting/AccountingReports/AccountReportDetails';
import StockTransform from 'containers/Inventory/Stock/StockTransform';
import AddOrder from 'containers/Sales/AddOrder';
import AddUpcomingStocks from 'containers/Inventory/UpcomingPurchase/AddUpcomingStocks';
import OrderInvoice from 'containers/Inventory/Order/OrderInvoice';
import ViewUpcomingStocks from 'containers/Inventory/UpcomingPurchase/ViewUpcomingStocks';
import ClientFeatureAssgin from 'containers/Settings/ClientFeatureAssign/ClientFeatureAssign';
import Variant from 'containers/Settings/Variantion/Variant';
import ViewDeliveryOrders from 'containers/Inventory/delivery-order/ViewDeliveryOrders';
import ViewInvoiceClosing from 'containers/Inventory/delivery-order/ViewInvoiceClosing';
// import PathaoOrderList from 'containers/Sales/PathaoOrder/PathaoOrderList';
// import AddPathaoOrder from 'containers/Sales/PathaoOrder/AddPathaoOrder';
import CustomOrderView from 'containers/Sales/CustomOrder/CustomOrderView';

const SystemSettings = lazy(() => import('./containers/Settings/System/SystemSetting'));
const DiscountSetting = lazy(() => import('./containers/Settings/VatDiscount/DiscountSetting'));
const VatSetting = lazy(() => import('./containers/Settings/VatDiscount/VatSetting'));
const CategorySetting = lazy(() => import('./containers/Settings/Category/Category'));
const UserSetting = lazy(() => import("./containers/Settings/User/Users"));
const ProductSetting = lazy(() => import('./containers/Settings/Product/Product'));
const SupplierSetting = lazy(() => import('./containers/Settings/Supplier/Supplier'));
const ClientSetting = lazy(() => import('./containers/Settings/Client/Client'));
const BankSetting = lazy(() => import('./containers/Settings/Bank/Bank'));
const ExpenseSetting = lazy(() => import('./containers/Settings/Expense/Expense'));
const Quotation = lazy(() => import('./containers/Sales/Quotation'));
const AddQuotation = lazy(() => import('./containers/Sales/AddQuotation'));
const PathaoOrderList = lazy(() => import('./containers/Sales/PathaoOrder/PathaoOrderList'));
const AddPathaoOrder = lazy(() => import('./containers/Sales/PathaoOrder/AddPathaoOrder'));


const AccountingReports = lazy(() => import('./containers/Accounting/AccountingReports'));
const ClientReport = lazy(() => import('./containers/Accounting/AccountingReports/ClientReport'));
const SupplierReport = lazy(() => import('./containers/Accounting/AccountingReports/SupplierReport'));
const AccountDetails = lazy(() => import('./containers/Accounting/AccountDetails'));

const InventoryStock = lazy(() => import('./containers/Inventory/Stock/ViewStock'));
const PurchaseAdd = lazy(() => import('./containers/Inventory/Purchase/AddPurchase'));
const Purchase = lazy(() => import('./containers/Inventory/Purchase/Purchase'));
const PurchaseReturn = lazy(() => import('./containers/Inventory/Purchase/PurchaseReturn'));
const Inventory = lazy(() => import('./containers/Inventory/Inventory'));
const LabelPrint = lazy(() => import("./containers/Print/LabelPrint"));
const InventoryOpening = lazy(() => import("./containers/Inventory/Inventory Opening/InventoryOpening"));
const ViewCalendar = lazy(() => import('./containers/Inventory/Calendar/ViewCalendar'));

const Sales = lazy(() => import('./containers/Sales/Sales'));
const SalesInvoice = lazy(() => import('./containers/Sales/SalesInvoice'));
const SalesInvoiceAdd = lazy(() => import('./containers/Sales/AddSalesInvoice'));

const CashReceive = lazy(() => import('./containers/Transaction/CashReceive'));
const ViewSupplierPayments = lazy(() => import('./containers/Transaction/ViewSupplierPayments'));
const SupplierPayment = lazy(() => import('./containers/Transaction/SupplierPayment'));
const ViewExpenseEntry = lazy(() => import('./containers/Transaction/ViewExpenseEntry'));
const ExpenseEntry = lazy(() => import('./containers/Transaction/ExpenseEntry'));
const ViewCashReceive = lazy(() => import('./containers/Transaction/ViewCashReceive'));
const GeneralEntry = lazy(() => import('./containers/Transaction/GeneralEntry'));
const Transactions = lazy(() => import('./containers/Transaction/Transactions'));

const Print = lazy(() => import('./containers/Print/Print'));

const InventoryReport = lazy(() => import('./containers/Reports/InventoryReport'));
const BranchSummeryReport = lazy(() => import('./containers/Reports/BranchSummeryReport'));
const Reports = lazy(() => import('./containers/Reports/Reports'));

const AdminLayout = lazy(() => import('./containers/Layout/Layout'));
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Settings = lazy(() => import('./containers/Settings/Settings'));
const Login = lazy(() => import('./containers/Login/Login'));
const NotFound = lazy(() => import('./containers/NotFound/NotFound'));
const NotAuthorized = lazy(() => import('./containers/NotFound/NotAuthorized'));
const UserFeatureUnassign = lazy(() => import('./containers/Settings/UserFeatureAssign/UserFeatureUnassign'));
const UserFeatureAssign = lazy(() => import('./containers/Settings/UserFeatureAssign/UserFeatureAssign'));

const Manufacturing = lazy(() => import('./containers/Manufacturing/Manufacturing'));
const ViewFormulas = lazy(() => import('./containers/Manufacturing/ViewFormulas'));
const ViewProcesses = lazy(() => import('./containers/Manufacturing/ViewProcesses'));
const CreateProcess = lazy(() => import('./containers/Manufacturing/CreateNewProcess'));
const CompleteProcess = lazy(() => import('./containers/Manufacturing/CompleteProcess'));
const CreateFormula = lazy(() => import('./containers/Manufacturing/CreateNewFormula'));

const super_admin_roles = ['super_admin']
const admin_roles = ['super_admin', 'admin']
const executive_roles = ['super_admin', 'admin', 'executive']


/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

// function PrivateRoute({ children, ...rest }) {
//   const { isAuthenticated } = useContext(AuthContext);

//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         isAuthenticated ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }
function PrivateRoute({ children, ...rest }) {
    const { isAuthenticated, checkToken } = useContext(AuthContext);

    const focusHandler = () => {
        checkToken();
    }
    useEffect(() => {
        // subscribe event
        focusHandler();
        window.addEventListener("focus", focusHandler, false);
        return () => {
            // unsubscribe event
            window.removeEventListener("focus", focusHandler, false);
        };
    }, []);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (children) : (<Redirect to={{
                    pathname: '/login', state: { from: location },
                }} />
                )
            }
        />
    );
}

const AdminLayoutWrapper = ({ children }) => {
    return (
        <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
                {children}
            </Suspense>
        </AdminLayout>
    )
}

function ProtectedRoute(props) {
    const { isAuthenticated, checkToken } = useContext(AuthContext);
    //let isAllowed = false
    let alCount = []
    //let isAllowed = props?.roles?.some(b => Auth.get()?.roles.includes(b))
    let targetRoute = mainRoutes.find((a) => a.path === props.path);
    let isAllowed = UserFeatures.get()?.some((a) => a.featureId === targetRoute.id);

    if (props.path === "/") isAllowed = true

    // props?.roles?.forEach(r => {
    //     let isAllowed = Auth.get()?.roles.includes(r)
    //     if(isAllowed){
    //         alCount.push(r)
    //     }
    // })

    //const canAdd = ((!r.roles) || (r.roles.some(b => Auth?.get()?.roles.includes(b))))

    const focusHandler = () => {
        checkToken();
    }
    useEffect(() => {
        // subscribe event
        focusHandler();
        window.addEventListener("focus", focusHandler, false);
        return () => {
            // unsubscribe event
            window.removeEventListener("focus", focusHandler, false);
        };
    }, []);
    return (
        <>
            {isAuthenticated ?
                <>
                    {
                        isAllowed ?
                            <Route
                                {...props}
                            />
                            :
                            <Route component={NotAuthorized} />
                    }
                </>
                :
                <Redirect to={{ pathname: '/login' }} />
            }
        </>

    );
}




export const mainRoutes = [
    {
        name: 'Dashboard',
        id: "59e4db53-9e2d-497a-af35-bf942e4e5061",
        path: DASHBOARD,
        exact: true,
        isMenu: true,
        roles: executive_roles,
        component: () => (<AdminLayoutWrapper><Dashboard /></AdminLayoutWrapper>),
        icon: <DashboardIcon />,
    },
    // Settings routing
    {
        name: 'Settings',
        id: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        parentId: null,
        path: SETTINGS,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><Settings /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <SettingIcon />,
    },
    {
        name: 'CategorySetting',
        id: "108012ac-75e2-4388-9c3a-12bb9ddc6519",
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: CATEGORY_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><CategorySetting /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ProductSetting',
        id: "57f1502b-c640-431f-850c-f33d7b40459a", // TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: PRODUCT_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ProductSetting /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ExpenseSetting',
        id: "62d69c2f-90ea-441e-86c1-b14011d42b4c", // TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: EXPENSE_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ExpenseSetting /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ClientSetting',
        id: "7565c95c-5cb6-434c-90b3-67b01e983482",
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: CLIENT_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ClientSetting /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SupplierSetting',
        id: "9b3ca5d6-34dd-412d-94bd-6172103e638f",
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: SUPPLIER_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SupplierSetting /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'BankSetting',
        id: "a60aa6d5-082c-41ad-9927-3c6b4ec72d63", // TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: BANK_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><BankSetting /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SystemSettings',
        id: "edb6d711-2b7b-4bf6-9475-becbb23f951d", // TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: SYSTEM_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SystemSettings /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ReportSettings',
        id: "577a0b1a-29f1-4cdb-91cd-93dace1459b5", // TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: REPORT_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ReportSettings /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'VatSetting',
        id: "4c73072c-4039-4df1-9be0-667c0627b684", // TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: VAT_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><VatSetting /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'DiscountSetting',
        id: "33f5e505-e03d-4917-8d8d-d93062d5151a", // TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: DISCOUNT_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><DiscountSetting /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'UserSetting',
        id: "d3c4f7f2-ba0c-443e-8d9f-0c3a341c1a20",
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: USER_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><UserSetting /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'VariantSetting',
        id: "242b2eb6-1f84-40fd-a5a1-77d88b161d02",
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: VARIANT_SETTINGS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><Variant /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'UserFeatureAssign',
        id: "04eb3b0e-3667-4f4e-84b4-ebfba774ed49", //TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: USER_FEATURE_ASSIGN,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><UserFeatureAssign /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'UserFeatureUnssign',
        id: "9b8645a6-6669-417b-a25a-883177f18cbe", //TODO: This is new
        parentId: "a78a4cb1-140d-4f94-96a7-3659f6ff1c3a",
        path: USER_FEATURE_UNASSIGN,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><UserFeatureUnassign /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ClientFeatureAssign',
        id: "9b8645a6-6669-417b-a25a-883177f18cbe", //TODO: This is new
        parentId: "3e6a8426-01b8-4d91-9701-2411f9c3452e",
        path: CLIENT_FEATURE_ASSIGN,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ClientFeatureAssgin /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    // Transaction routings
    {
        name: 'Transaction',
        id: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        parentId: null,
        path: TRANSACTIONS,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><Transactions /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <AiOutlineTransaction />
    },
    {
        name: 'AddCashReceive',
        id: "be1b5a36-11a2-4623-9ea2-40f6aa1581f6",
        parentId: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        path: CASH_RECEIVE,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><CashReceive /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewCashReceive',
        id: "6d1cd148-cc22-4c08-9036-a4bd8d19da3e", // TODO: This is new
        parentId: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        path: VIEW_CASH_RECEIVE,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewCashReceive /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AddExpenseEntry',
        id: "0290a747-cd3d-46d6-97cf-73c2d5631c00",
        parentId: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        path: EXPENSE_ENTRY,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ExpenseEntry /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewExpenseEntry',
        id: "54984020-142c-4fca-a6af-19aba7536047", // TODO: This is new
        parentId: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        path: VIEW_EXPENSE_ENTRY,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewExpenseEntry /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AddSupplierPayment',
        id: "7e38cc21-6d07-4292-9b93-d0ab3ee49aa8",
        parentId: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        path: SUPPLIER_PAYMENT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SupplierPayment /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewSupplierPayments',
        id: "4ba01a58-36b8-4602-82e6-6273ca8d4f09",
        parentId: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        path: VIEW_SUPPLIER_PAYMENT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewSupplierPayments /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'GeneralEntry',
        id: "ca6ab6bb-2928-4332-8633-2ef78fd1efc2",
        parentId: "c33a5749-7e4d-4a0e-9621-c17c894a724c",
        path: GENERAL_TRANSACTIONS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><GeneralEntry /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    // Accounting routings
    {
        name: 'Accounting',
        id: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        parentId: null,
        path: ACCOUNTING_REPORTS,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><AccountingReports /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <AiFillAccountBook />
    },
    {
        name: 'AccCash',
        id: "3cde5e73-5458-45d0-b3f2-7b243f58fb1d", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_CASH,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccCash /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccountingBank',
        id: "552dbe34-093c-4318-ae02-9dbb8c59cab0", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_BANK,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccountingBank /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccSuppliers',
        id: "94c38d21-6e86-4394-9e92-0444127c2a8a", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_SUPPLIERS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccSuppliers /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccExpense',
        id: "781e64cc-2cfc-465d-b28a-1ba1901afac5", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_EXPENSE,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccExpense /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccSales',
        id: "484e4c76-4901-4547-8572-1a32ba43ec0d", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_SALES,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccSales /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccountingClients',
        id: "aedb4a74-aa45-43fb-93b6-0515591253e3", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_CLIENTS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccountingClients /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccDiscount',
        id: "b338c989-5dfe-488e-8963-bb0d609a12d0", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_DISCOUNT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccDiscount /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccSalesReturn',
        id: "acde4b65-c4b3-456c-8e08-195829592411", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_SALES_RETURN,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccSalesReturn /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AllAccountDetails',
        id: "862b837c-8c58-475c-821e-46d7f5c5f5ea", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: ACCOUNTING_DETAILS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccountDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccountDetails',
        id: "fa8f5fad-a9c8-4b66-ac63-b3ad48184c4e",
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4",
        path: `${ACCOUNTING_DETAILS}/:name-:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccountDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    //other routings
    {
        name: 'AssetManagement',
        id: "3417b761-7089-4a8a-89a3-7d67b14b0585", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: ASSET_MANAGEMENT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AssetManagement /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AddAsset',
        id: "4c5820c0-0505-4792-8491-81fa5bebd2c4", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: ADD_ASSET,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AddAsset /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccountReportDetails',
        id: "b777db7f-1032-4450-a407-eb4ddef2a289",
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: `${ACCOUNTING_REPORT_DETAILS}/:name-:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccountReportDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccountingBankReport',
        id: "4d5d1a70-c0b3-4b88-8bfc-719463c05370", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: BANK_REPORT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><BankReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'BankDetails',
        id: "4d65aaae-10b3-45f0-b58f-755b0aebf3c4", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: `${BANK_DETAILS}/:name-:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><BankDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ExpenseDetails',
        id: "60c28f3a-94cf-455c-9c43-0292fba0c004", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: `${EXPENSE_DETAILS}/:name-:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ExpenseDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SupplierDetails',
        id: "fa0d1768-bba0-4965-a819-50ccf0e51fbb", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: `${SUPPLIER_DETAILS}/:name-:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SupplierDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ClientDetails',
        id: "ae4523f0-c302-457a-81c1-d3e24f93874d", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: `${CLIENT_DETAILS}/:name-:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ClientDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AccountReportDetails',
        id: "28cca742-4fdf-4e08-a859-ccee2718a761", // TODO: This is new
        parentId: "3b853baa-8b4a-4ad8-a8de-a63719d2ebe4", // accounting
        path: ACCOUNTING_REPORT_DETAILS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AccountReportDetails /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    // Inventory routing
    {
        name: 'Inventory',
        id: "52e56c78-c409-4b94-80be-8351aa741163",
        parentId: null,
        path: INVENTORY,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><Inventory /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <InventoryIcon />,
    },
    {
        name: 'PurchaseAdd',
        id: "1b5a46c2-f76d-49b9-9b4a-f142137e2909",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: PURCHASE_INVOICE_ADD,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><PurchaseAdd /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'PurchaseView',
        id: "86fb04ac-7327-4dae-a8c5-8b75f70f8df7",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: PURCHASE_INVOICE,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><Purchase /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'PurchaseReturn',
        id: "eac58a16-9119-48a2-b010-548530aa46e8", // TODO: This is new
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: PURCHASE_RETURN,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><PurchaseReturn /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'InventoryStock',
        id: "c25f11b2-f01c-41bd-842f-c148d73def8b",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: INVENTORY_STOCK,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><InventoryStock /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewStockReport',
        id: "6203ccfd-3653-48f4-a174-eea3ee79df75",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: INVENTORY_REPORT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewStockReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SendProduct',
        id: "6cb0d681-ebb7-4ce2-a390-5f6d2dffc072", // TODO: This is new
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: SEND_PRODUCT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SendProduct /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ReceiveProduct',
        id: "6cbfd359-0eae-42bf-a167-bec3c51b3048",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: RECEIVE_PRODUCT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ReceiveProduct /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ProductTransferRequest',
        id: "3d9dc549-4cf4-4313-9581-5b0c4690d49e",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: PRODUCT_TRANSFER_REQUEST,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ProductTransferRequest /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'LabelPrint',
        id: "887a2e03-5cf7-4c21-a389-0912bccd3e80", // TODO: This is new
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: INVENTORY_LABEL_PRINT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><LabelPrint /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'InventoryOpening',
        id: "9cde86bf-6219-492d-b989-6baca276e171",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: INVENTORY_INVENTORY_OPENING,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><InventoryOpening /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'InventoryAuditExecute',
        id: "ba819418-c4a0-4d61-8cce-85fd22fd7881", // TODO: This is new
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: `${INVENTORY_AUDIT_EXECUTE}/:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><InventoryAuditExecute /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AuditList',
        id: "16c8717b-e202-4426-9ec0-40f699ad172e", // TODO: This is new
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: INVENTORY_AUDIT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AuditList /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'StockTransform',
        id: "fefc54a5-3d0e-47a0-ba05-998d8b9c9630",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: STOCK_TRANSFORM,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><StockTransform /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AddUpcomingStocks',
        id: "e392ed3f-afb5-43d8-94d4-324720dee954",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: ADD_UPCOMING_STOCKS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AddUpcomingStocks /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'UpcomingStocks',
        id: "427fad49-2c33-4c25-9736-d16d12074489",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: VIEW_UPCOMING_STOCKS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewUpcomingStocks /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewOrderDelivery',
        id: "427fad49-2c33-4c25-9736-d16d12074489",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: VIEW_DELIVERY_ORDERS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewDeliveryOrders /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewInvoiceClosing',
        id: "427fad49-2c33-4c25-9736-d16d12074489",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: VIEW_INVOICE_CLOSING,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewInvoiceClosing /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewCalendar',
        id: "427fad49-2c33-4c25-9736-d16d12074490",
        parentId: "52e56c78-c409-4b94-80be-8351aa741163",
        path: VIEW_CALENDAR,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewCalendar /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    //Menufacturing Route
    {
        name: 'Manufacturing',
        id: "7e96db38-1e2b-4792-a2ec-b81681520cdc",
        parentId: null,
        path: MANUFACTURING,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><Manufacturing /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <FcProcess />,
    },
    {
        name: 'Create Process',
        id: "b23e8a7b-ee10-486c-bbfc-e546eb606ee1",
        parentId: "7e96db38-1e2b-4792-a2ec-b81681520cdc",
        path: CREATE_PROCESS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><CreateProcess /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiProcessor />,
    },
    {
        name: 'Complete Process',
        id: "5a663ecd-7147-4fa7-952f-94c190001e0f",
        parentId: "7e96db38-1e2b-4792-a2ec-b81681520cdc",
        path: COMPLETE_PROCESS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><CompleteProcess /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <AiFillCarryOut />,
    },
    {
        name: 'Create Formula',
        id: "e5193e7c-c095-4ff2-9092-14835066ecd3",
        parentId: "7e96db38-1e2b-4792-a2ec-b81681520cdc",
        path: CREATE_FORMULA,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><CreateFormula /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <VscSymbolMethod />,
    },
    {
        name: 'View Formulas',
        id: "8c371a4a-417e-4e3d-b654-5e8d7431b56e",
        parentId: "7e96db38-1e2b-4792-a2ec-b81681520cdc",
        path: VIEW_FORMULAS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewFormulas /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <VscSymbolMethod />,
    },
    {
        name: 'View Process',
        id: "18513463-1fd3-40c4-993f-61591cbf663e",
        parentId: "7e96db38-1e2b-4792-a2ec-b81681520cdc",
        path: VIEW_PROCESSES,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewProcesses /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <VscServerProcess />,
    },
    // Sales routings
    {
        name: 'Sales',
        id: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        parentId: null,
        path: SALES,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><Sales /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <FaHandHoldingUsd />,
    },
    {
        name: 'SalesInvoiceAdd',
        id: "b31a8321-a53e-4028-bbd0-30665f3f6c4e",
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: SALES_INVOICE_ADD,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SalesInvoiceAdd /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SalesInvoice',
        id: "cd6a878f-42f8-4590-8e9e-75932f27c3e5",
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: SALES_INVOICE,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SalesInvoice /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SalesInvoiceAdditionalInfo',
        id: "501af66e-6de9-4bcb-9a2c-932d08c98d6f",
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: `${SALES_INVOICE_ADDITIONAL_INFO}/:id`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SalesInvoiceAdditionalInfo /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SalesInvoiceReturn',
        id: "eb92de0c-b459-4dba-85ed-4ec3ff149d30", // TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: SALES_INVOICE_RETURN,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SalesInvoiceReturn /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AddQuotation',
        id: "a18211f4-1f26-4e2d-b201-6b52d6592b6c", //TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: QUOTATION_ADD,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AddQuotation /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'Quotation',
        id: "df6c0a58-2845-4136-b123-333a8836afc4", // TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: QUOTATION,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><Quotation /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'Print',
        id: "8db083da-7cbd-47fb-ac91-55b8685fa80b", // TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003", // sales
        path: `${PRINT}/:type/:param`,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><Print /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AddOrder',
        id: "37289748-8c6f-47c2-8912-89ecb3825ba7", // TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: ADD_ORDER,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AddOrder /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },

    {
        name: 'OrderInvoice',
        id: "6d244817-1944-4fcd-b360-770603767aa8", // TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: ORDER_INVOICE,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><OrderInvoice /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'PathaoOrders',
        id: "37289748-8c6f-47c2-8912-89ecb3825ba7", // TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: PARTHAO_ORDER_LIST,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><PathaoOrderList /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'AddPathaoOrder',
        id: "81ab02b2-5b86-4109-98a1-7beea3460532",
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: ADD_PATHAO_ORDER,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><AddPathaoOrder/></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },

    {
        name: 'CustomOrder',
        id: "d3b9a20c-421e-4184-8432-21710c1e4d5c", // TODO: This is new
        parentId: "9f2a99d5-e8a2-470f-a9d7-8ef169be4003",
        path: CUSTOM_ORDER,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><CustomOrderView /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },

    // Branch routings
    {
        name: 'Branch',
        id: "f1fcf7c2-6768-4cfb-8333-6c69d71c5af5",
        parentId: null,
        path: BRANCH,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><Branch /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ViewAndAddBranch',
        id: "f9779bd5-6507-4b4e-9462-9e4b7e91e1db",
        parentId: "f1fcf7c2-6768-4cfb-8333-6c69d71c5af5",
        path: ADD_BRANCH,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ViewBranch /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    // Reports routings
    {
        name: 'Reports',
        id: "4f9bece0-5285-4627-81f5-ec246c5548ac", //TODO: This is new
        parentId: null,
        path: REPORTS,
        exact: true,
        isMenu: true,
        component: () => (<AdminLayoutWrapper><Reports /></AdminLayoutWrapper>),
        roles: super_admin_roles,
        icon: <AiOutlineAreaChart />,
    },
    {
        name: 'InventoryReport',
        id: "0f22496b-0a4e-46b8-a2b9-db9b6acff076", // TODO: This is new
        parentId: "4f9bece0-5285-4627-81f5-ec246c5548ac",
        path: REPORT_INVENTORY,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><InventoryReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ClientSalesReport',
        id: "cdf3f945-083a-48b3-84fc-1e424da5bdc0", // TODO: This is new
        parentId: "4f9bece0-5285-4627-81f5-ec246c5548ac",
        path: REPORT_CLIENT_SALES,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ClientSalesReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    // {
    //     name: 'TransactionReport',
    //     id: "aa4d9118-6f22-4cfd-a5fd-1b82d4626513", // TODO: This is new
    //     parentId: "4f9bece0-5285-4627-81f5-ec246c5548ac",
    //     path: REPORT_TRANSACTION,
    //     exact: true,
    //     isMenu: false,
    //     component: () => (<AdminLayoutWrapper><TransactionReport /></AdminLayoutWrapper>),
    //     roles: executive_roles,
    //     icon: <GiExpense />,
    // },
    {
        name: 'BranchSummeryReport',
        id: "136c0c13-0349-4dee-b7aa-12a2dd88c1fc", // TODO: This is new
        parentId: "4f9bece0-5285-4627-81f5-ec246c5548ac",
        path: BRANCH_SUMMERY_REPORT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><BranchSummeryReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'LowStockReport',
        id: "717c2b12-f8f8-4016-bab8-b62d77d92413", //TODO: This is new
        parentId: "4f9bece0-5285-4627-81f5-ec246c5548ac",
        path: LOW_STOCK_PRODUCTS,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><LowStockReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'ClientReport',
        id: "0cb36ee4-433f-4fec-9e45-07d2d47b1fc7", // TODO: This is new
        parentId: "4f9bece0-5285-4627-81f5-ec246c5548ac",
        path: CLIENT_REPORT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><ClientReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
    {
        name: 'SupplierReport',
        id: "82f77451-75ef-4a97-b6c4-d94df568e586", // TODO: This is new
        parentId: "4f9bece0-5285-4627-81f5-ec246c5548ac",
        path: SUPPLIER_REPORT,
        exact: true,
        isMenu: false,
        component: () => (<AdminLayoutWrapper><SupplierReport /></AdminLayoutWrapper>),
        roles: executive_roles,
        icon: <GiExpense />,
    },
];
//const roles = Auth?.get()?.roles || []
//const roles = ['super_admin']
// export const AllowedRoutes = () => {
//     let result = []
//     Auth?.get()?.roles?.forEach(r => {
//         let currentRoleItems =  mainRoutes.filter(a => a.roles?.includes(r) || a.roles?.length === 0)
//         result = [...result, ...currentRoleItems]
//     })
//     return result;
// }

export const AllowedRoutes = () => {
    return mainRoutes.filter(r => {
        //const route = th is.props.clientModules.find(a => a.module.globalId === r.id);
        //if (route) r.name = route.moduleTitle;
        // const canAdd = ((!r.roles) || (r.roles.some(b => Auth?.get()?.roles.includes(b)))) //&& typeof route === "object";
        // return canAdd;
        const canAdd = UserFeatures.get()?.find((a) => a.featureId === r.id);
        return canAdd;
    });
};

const Routes = () => {
    return (
        <AuthProvider>
            <Suspense fallback={<InLineLoader />}>
                <Switch>
                    <Route path={LOGIN}>
                        <Login />
                    </Route>
                    <ProtectedRoute
                        exact={true}
                        path={DASHBOARD}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Dashboard /></AdminLayoutWrapper>)}
                    />

                    {/*{AllowedRoutes().map((r,ri) =>*/}
                    {/*    {*/}
                    {/*        console.log(r)*/}
                    {/*        return(*/}
                    {/*            <ProtectedRoute exact={true} path={r.path} component={r.component} key={ri}/>*/}
                    {/*        )*/}
                    {/*    }*/}

                    {/*)}*/}

                    <ProtectedRoute
                        exact={true}
                        path={PURCHASE_INVOICE_ADD}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><PurchaseAdd /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={LOW_STOCK_PRODUCTS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><LowStockReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={PURCHASE_INVOICE}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Purchase /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={PURCHASE_RETURN}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><PurchaseReturn /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={INVENTORY_STOCK}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><InventoryStock /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={INVENTORY_REPORT}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewStockReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SEND_PRODUCT}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SendProduct /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={RECEIVE_PRODUCT}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ReceiveProduct /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={PRODUCT_TRANSFER_REQUEST}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ProductTransferRequest /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={INVENTORY_LABEL_PRINT}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><LabelPrint /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={INVENTORY_INVENTORY_OPENING}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><InventoryOpening /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={INVENTORY_AUDIT_EXECUTE + "/:id"}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><InventoryAuditExecute /></AdminLayoutWrapper>)}
                    />

                    <ProtectedRoute
                        exact={true}
                        path={INVENTORY_AUDIT}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AuditList /></AdminLayoutWrapper>)}
                    />

                    <ProtectedRoute
                        exact={true}
                        path={STOCK_TRANSFORM}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><StockTransform /></AdminLayoutWrapper>)}
                    />

                    <ProtectedRoute
                        exact={true}
                        path={ADD_UPCOMING_STOCKS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AddUpcomingStocks /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_UPCOMING_STOCKS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewUpcomingStocks /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_DELIVERY_ORDERS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewDeliveryOrders /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_INVOICE_CLOSING}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewInvoiceClosing /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_CALENDAR}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewCalendar /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={INVENTORY}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Inventory /></AdminLayoutWrapper>)}
                    />
                    {/* MANUFACTURING */}
                    <ProtectedRoute
                        exact={true}
                        path={COMPLETE_PROCESS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><CompleteProcess /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CREATE_PROCESS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><CreateProcess /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_PROCESSES}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ViewProcesses /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CREATE_FORMULA}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><CreateFormula /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_FORMULAS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ViewFormulas /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={MANUFACTURING}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Manufacturing /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SALES_INVOICE_ADD}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SalesInvoiceAdd /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={`${SALES_INVOICE_ADDITIONAL_INFO}/:id`}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SalesInvoiceAdditionalInfo /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={QUOTATION_ADD}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><AddQuotation /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ADD_ORDER}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><AddOrder /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ORDER_INVOICE}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><OrderInvoice /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={PARTHAO_ORDER_LIST}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><PathaoOrderList /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ADD_PATHAO_ORDER}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><AddPathaoOrder /></AdminLayoutWrapper>)}
                    />
                     <ProtectedRoute
                        exact={true}
                        path={CUSTOM_ORDER}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><CustomOrderView /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SALES_INVOICE}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SalesInvoice /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={QUOTATION}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Quotation /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SALES_INVOICE_RETURN}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SalesInvoiceReturn /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SALES}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><Sales /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SYSTEM_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SystemSettings /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={REPORT_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ReportSettings /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VAT_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><VatSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={DISCOUNT_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><DiscountSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CATEGORY_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><CategorySetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={USER_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><UserSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VARIANT_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Variant /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CLIENT_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ClientSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_CASH}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccCash /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_BANK}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccountingBank /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_SUPPLIERS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccSuppliers /></AdminLayoutWrapper>)}
                    />

                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_EXPENSE}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccExpense /></AdminLayoutWrapper>)}
                    />

                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_SALES}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccSales /></AdminLayoutWrapper>)}
                    />

                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_CLIENTS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccountingClients /></AdminLayoutWrapper>)}
                    />

                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_DISCOUNT}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccDiscount /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_SALES_RETURN}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccSalesReturn /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SUPPLIER_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SupplierSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={BANK_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><BankSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={EXPENSE_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ExpenseSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={PRODUCT_SETTINGS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ProductSetting /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SETTINGS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><Settings /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_REPORTS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccountingReports /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ASSET_MANAGEMENT}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AssetManagement /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ADD_ASSET}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AddAsset /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_DETAILS + "/:name-:id"}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccountDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_REPORT_DETAILS + "/:name-:id"}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccountReportDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={BANK_DETAILS + "/:name-:id"}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><BankDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={EXPENSE_DETAILS + "/:name-:id"}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ExpenseDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SUPPLIER_DETAILS + "/:name-:id"}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><SupplierDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CLIENT_DETAILS + "/:name-:id"}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ClientDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_DETAILS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccountDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ACCOUNTING_REPORT_DETAILS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><AccountReportDetails /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CASH_RECEIVE}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><CashReceive /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_EXPENSE_ENTRY}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewExpenseEntry /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={EXPENSE_ENTRY}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ExpenseEntry /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_SUPPLIER_PAYMENT}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewSupplierPayments /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SUPPLIER_PAYMENT}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><SupplierPayment /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={VIEW_CASH_RECEIVE}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><ViewCashReceive /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={GENERAL_TRANSACTIONS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><GeneralEntry /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={TRANSACTIONS}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><Transactions /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={PRINT + "/:type/:param"}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Print /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={REPORT_INVENTORY}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><InventoryReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={REPORT_CLIENT_SALES}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ClientSalesReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={REPORT_TRANSACTION}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><TransactionReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={BRANCH_SUMMERY_REPORT}
                        roles={super_admin_roles}
                        component={() => (<AdminLayoutWrapper><BranchSummeryReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={REPORTS}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Reports /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={ADD_BRANCH}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ViewBranch /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={BRANCH}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><Branch /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={USER_FEATURE_ASSIGN}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><UserFeatureAssign /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={USER_FEATURE_UNASSIGN}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><UserFeatureUnassign /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CLIENT_FEATURE_ASSIGN}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ClientFeatureAssgin /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={CLIENT_REPORT}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><ClientReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={SUPPLIER_REPORT}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><SupplierReport /></AdminLayoutWrapper>)}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={BANK_REPORT}
                        roles={executive_roles}
                        component={() => (<AdminLayoutWrapper><BankReport /></AdminLayoutWrapper>)}
                    />







                    {/*<ProtectedRoute path={SALES_INVOICE}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <SalesInvoice />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={QUOTATION}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <Quotation />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={SALES_INVOICE_RETURN}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <SalesInvoiceReturn />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={SALES}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <Sales />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={SYSTEM_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <SystemSettings />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={REPORT_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ReportSettings />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={VAT_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <VatSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={DISCOUNT_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <DiscountSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={CATEGORY_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <CategorySetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={USER_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <UserSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={CLIENT_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ClientSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_CASH}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccCash />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_BANK}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccountingBank />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_SUPPLIERS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccSuppliers />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_EXPENSE}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccExpense />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_SALES}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccSales />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_CLIENTS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccountingClients />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_DISCOUNT}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccDiscount />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={ACCOUNTING_SALES_RETURN}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccSalesReturn />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={SUPPLIER_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <SupplierSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={BANK_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <BankSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={EXPENSE_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ExpenseSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={PRODUCT_SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ProductSetting />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={SETTINGS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <Settings />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={ACCOUNTING_REPORTS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccountingReports />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={ASSET_MANAGEMENT}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AssetManagement />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={ADD_ASSET}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AddAsset />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={ACCOUNTING_DETAILS + "/:name-:id"}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccountDetails />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={ACCOUNTING_DETAILS + "/:name-:id"}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccountDetails />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={BANK_DETAILS + "/:name-:id"}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <BankDetails />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={EXPENSE_DETAILS + "/:name-:id"}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ExpenseDetails />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={SUPPLIER_DETAILS + "/:name-:id"}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <SupplierDetails />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={CLIENT_DETAILS + "/:name-:id"}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ClientDetails />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={ACCOUNTING_DETAILS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <AccountDetails />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={CASH_RECEIVE}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <CashReceive />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={VIEW_EXPENSE_ENTRY}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ViewExpenseEntry />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={EXPENSE_ENTRY}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ExpenseEntry />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={VIEW_SUPPLIER_PAYMENT}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ViewSupplierPayments />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={SUPPLIER_PAYMENT}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <SupplierPayment />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={VIEW_CASH_RECEIVE}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ViewCashReceive />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={GENERAL_TRANSACTIONS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <GeneralEntry />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={TRANSACTIONS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <Transactions />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={PRINT + "/:type/:param"}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <Print />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={REPORT_INVENTORY}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <InventoryReport />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={REPORT_CLIENT_SALES}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader/>}>*/}
                    {/*            <ClientSalesReport />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={REPORT_TRANSACTION}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader/>}>*/}
                    {/*            <TransactionReport />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}
                    {/*<ProtectedRoute path={REPORTS}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <Reports />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={ADD_BRANCH}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <ViewBranch />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    {/*<ProtectedRoute path={BRANCH}>*/}
                    {/*    <AdminLayout>*/}
                    {/*        <Suspense fallback={<InLineLoader />}>*/}
                    {/*            <Branch />*/}
                    {/*        </Suspense>*/}
                    {/*    </AdminLayout>*/}
                    {/*</ProtectedRoute>*/}

                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </AuthProvider>
    );
};

export default Routes;
