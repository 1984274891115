import React, { useState } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import DrawerBox from 'components/DrawerBox/DrawerBox';
import { Row, Col, Grid } from 'components/FlexBox/FlexBox';
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Select } from 'baseui/select';
import { useHistory } from 'react-router-dom';
import { RECEIVE_PRODUCT } from 'settings/constants';
import useCustomSnackBar from 'hooks/useCustomSnackBar';
import { GetProductVariationId } from 'utils/inventory';
import { Header, Heading, Wrapper } from './Transfer.style';

type Props = any;
const STOCK_QUERY = gql`query($query: QueryParams!){
    stockQuery{
        available(query: $query){
            quantity
            variationId
            product{
                id
                measureUnit
                code
                title
                salesPrice
                priceExtensions{
                    active
                    amount
                    amountType
                    priceType
                }
            }
        }
    }
}`;

const PRODUCT_QUERY_WITH_VARIATION = gql`
query($query: QueryParams!) {
  productQuery {
    pocketXProducts(query: $query) {
      items {
        id
        title
        code
        measureUnit
        productVariations {
          productId
          variationId
          variationValueId
          permutationId
        }
        variations {
          id
          name
          variationValues {
            id
            variationId
            value
          }
        }
      }
    }
  }
}`
export const PRODUCT_TRANSFER_MUTATION = gql`
mutation($request: ProductStockTransferRequestDTO!) {
  stock {
    requestStockTransfer(requestStockTransferArg: $request) {
      success
    }
  }
}`;

const ProductTransferRequest: React.FC<Props> = () => {
    const [productOptions, setProductOptions] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [count, setCount] = useState(1);
    const history = useHistory();
    const enqueue = useCustomSnackBar();

    function itemAmountCalculate(qty, discount, type, sellPrice) {
        let itemVat = 0;
        if (type === "PERCENTAGE") {
            itemVat = ((Number(qty) || 0) * (Number(sellPrice) || 0)) * (discount / 100);
        } else {
            itemVat = ((Number(qty) || 0) * (Number(discount) || 0));
        }
        return itemVat;
    }

    const [loadStocks] = useLazyQuery(STOCK_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.stockQuery && data.stockQuery.available) {

                const stock = data.stockQuery.available[0];
                const discount = (stock.product.priceExtensions?.find(a => a.priceType === "DISCOUNT" && a.active === true) || { amount: 0, amountType: "" })
                const vat = (stock.product.priceExtensions?.find(a => a.priceType === "VAT" && a.active === true) || { amount: 0, amountType: "" })
                // TODO: Later when inclusive/exclusive discount is implemented, the salesPrice would be provided by the server

                let allInvoiceItems: any = [...invoiceItems]
                let invItems: any = allInvoiceItems.filter((a: any) => a.productId == stock.product.id);

                let invItem = null;

                if (stock.variationId > 0) {
                    invItem = invItems.find((a: any) => a.productId === stock.product.id && a.permutationId === stock.variationId)
                } else {
                    invItem = invItems.find((a: any) => a.productId === stock.product.id)
                }

                invItem.rem = stock.quantity;
                invItem.salesPrice = stock.product.salesPrice;
                invItem.vat = itemAmountCalculate(1, vat.amount, vat.amountType, (stock.product.salesPrice -
                    itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice))).toFixed(2);
                invItem.discount = itemAmountCalculate(1, discount.amount, discount.amountType, stock.product.salesPrice);
                invItem.discountType = discount.amountType;

                let itemInd = allInvoiceItems.indexOf(a => a.sl === invItem.sl)
                allInvoiceItems[itemInd] = invItem

                setInvoiceItems(allInvoiceItems);
            }
        }
    });

    const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY_WITH_VARIATION, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (data?.productQuery?.pocketXProducts?.items) {
                const opts = data?.productQuery?.pocketXProducts?.items.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))
                if (opts.length > 0) setProductOptions(opts)
            }
        }
    });

    const [productTransfer] = useMutation(PRODUCT_TRANSFER_MUTATION, {
        onCompleted: (data) => {
            if (data && data.stock && data.stock.requestStockTransfer && data.stock.requestStockTransfer.success) {
                enqueue("success", "Product Transfer Request Successful");
                history.push(RECEIVE_PRODUCT)
            }
        },
        onError: () => {
            enqueue("error", "Couldn't complete Transfer. Please try again later!");
        }
    })

    const searchProducts = (filter) => {
        const val = filter.toLowerCase();
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 100,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val
                }
            }
        });
    }
    /* 
    * GraphQL API End
    */
    function isValid() {
        return invoiceItems?.length > 0 && invoiceItems?.every((a: any) => parseInt(a.quantity) > 0);
    }

    function handleProductAdd(value) {
        const product = value[0];

        const productList = products?.productQuery?.pocketXProducts?.items || [];

        let selectedItem = productList.find(a => a.id === product.value) || null;
        if (!selectedItem) return;

        const currentCount = count + 1
        setCount(currentCount);

        let items = [...invoiceItems];
        let item = {
            sl: currentCount,
            productId: product.value,
            title: product.label,
            rem: 0,
            measureUnit: selectedItem.measureUnit || "",
            productVariations: selectedItem.productVariations,
            variations: selectedItem.variations,
            selectedVariations: [],
            permutationId: 0,
            quantity: '',
            salesPrice: '',
        }

        items.push(item);
        setInvoiceItems(items);

        if (selectedItem.variations.length === 0) {
            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "showZeroStock", value: "true" },
                            { key: "summary", value: "true" },
                            { key: "onlyOwnOrg", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productId", value: product.value + "" },
                            { key: "includeVariationName", value: "true" }
                        ]
                    }
                }
            });
        }
    }

    const handleChangeQty = (e: any, i: number, rem: number) => {
        let items = [...invoiceItems]
        items[i].quantity = parseInt(e.target.value) > rem ? rem : e.target.value;
        setInvoiceItems(items);
    }

    const onSubmit = () => {
        if (!isValid()) return;
        let selectedItems = invoiceItems?.map((item: any) => ({
            productId: item.productId,
            quantity: parseInt(item.quantity),
            variationId: item.permutationId
        }))
        productTransfer({
            variables: {
                request: {
                    id: 0,
                    title: "",
                    items: selectedItems
                }
            }
        })
    }

    const handleChangeVariation = (sl: number, value: any, variationId: number) => {
        let items = [...invoiceItems]
        let item = items.find(a => a.sl == sl);
        if (!item) return;

        //resetting
        item.rem = 0;
        item.salesPrice = 0;
        item.vat = 0;
        item.discount = 0;
        item.discountType = null;
        item.permutationId = 0;

        let selectedVariations = item.selectedVariations || [];
        let isExistVariationValue = selectedVariations.some(a => a.variationId == variationId);

        if (isExistVariationValue) {
            selectedVariations = selectedVariations.filter(a => a.variationId != variationId);
            item.selectedVariations = selectedVariations;
        }

        if (value.length > 0) {
            selectedVariations.push(
                {
                    variationId: variationId,
                    variationValueId: value[0].value,
                    variationValue: value[0].label,
                    permutationId: 0
                })

            if (selectedVariations.length === item.variations.length) {
                let vId = GetProductVariationId(item.productVariations, selectedVariations)

                if (items.find(a => a.permutationId === vId) && items.find(a => a.permutationId === vId).sl !== sl) {
                    let newItems = items.filter(a => a.sl !== sl);
                    setInvoiceItems(newItems);

                    enqueue("error", "This item already added!!");
                    return
                };

                item.permutationId = vId;
                selectedVariations = selectedVariations.map(a => {
                    return {
                        ...a,
                        permutationId: vId
                    }
                })
            }
            item.selectedVariations = selectedVariations;
        }
        let ind = items.findIndex(a => a.sl == sl);
        items[ind] = item;
        setInvoiceItems(items);

        if (selectedVariations.length === item.variations.length) {
            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "showZeroStock", value: "true" },
                            { key: "summary", value: "true" },
                            { key: "onlyOwnOrg", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productId", value: item.productId + "" },
                            { key: "permutationId", value: item.permutationId + "" },
                            { key: "includeVariationName", value: "true" }
                        ]
                    }
                }
            });
        }
    }

    return (
        <div>
            <Row>
                <Col md={10}>
                    <Header
                        style={{
                            marginBottom: 40,
                            boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                        }}
                    >
                        <Col md={4} xs={12}>
                            <Heading>Product Transfer Request</Heading>
                        </Col>
                    </Header>
                    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                        <Grid style={{ paddingTop: 30, paddingLeft: 5 }}>
                            <Row>
                                <Col md={8}>
                                    <DrawerBox>
                                        <FormFields>
                                            <FormLabel>Product Name/Code</FormLabel>
                                            <Select
                                                options={productOptions || []}
                                                labelKey="label"
                                                valueKey="value"
                                                value={[]}
                                                searchable={true}
                                                onChange={({ value }) => handleProductAdd(value)}
                                                onInputChange={(e: any) => {
                                                    const text = e.target.value;
                                                    if (text.length > 1) searchProducts(text)
                                                }}
                                            />
                                        </FormFields>
                                    </DrawerBox>
                                </Col>
                                <Col md={12}>
                                    {invoiceItems?.length > 0 && <>
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col md={3}><b>Product Name</b></Col>
                                            <Col md={4}><b>Variation</b></Col>
                                            <Col md={3}><b>Available</b></Col>
                                            <Col md={2}><b>Quantity</b></Col>
                                        </Row>
                                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />

                                        {invoiceItems?.map((item, i) =>
                                            <Row key={item?.productId}>
                                                <Col md={3}
                                                    style={{ alignSelf: 'flex-start', marginTop: '10px' }}>{item?.title}
                                                </Col>
                                                {item.variations.length > 0 &&
                                                    <> {
                                                        item.variations.length > 0 ?
                                                            item.variations.map((a) => {
                                                                return (
                                                                    <Col md={2} style={{ alignSelf: 'center' }}>
                                                                        <Select
                                                                            options={a.variationValues.map(v => { return { label: v.value, value: v.id } }) || []}
                                                                            placeholder={`${a.name}`}
                                                                            labelKey="label"
                                                                            valueKey="value"
                                                                            value={item.selectedVariations.filter(s => s.variationId === a.id).map(v => { return { label: v.variationValue, value: v.variationValueId } }) || []}
                                                                            searchable={true}
                                                                            onChange={({ value }) => handleChangeVariation(item.sl, value, a.id)}
                                                                            size='compact'
                                                                        />
                                                                    </Col>
                                                                )
                                                            })
                                                            : <Col md={4}><b>No Variation</b></Col>
                                                    }
                                                    </>
                                                }
                                                <Col md={3} style={{ alignSelf: 'center' }}>{item.rem} {`(${item.measureUnit})`}</Col>
                                                <Col md={2}>
                                                    <Input
                                                        type="number"
                                                        value={invoiceItems[i]?.quantity}
                                                        name={`invoiceItems.[${i}].quantity`}
                                                        onChange={(e: any) => handleChangeQty(e, i, item.rem)}
                                                    />
                                                </Col>
                                            </Row>)}
                                        <hr style={{ border: ".75px solid lavender", marginBottom: 16 }} />
                                    </>}
                                    <Row>
                                        <Col md={8}></Col>
                                        <Col md={4}>
                                            <Button
                                                disabled={!isValid()}
                                                onClick={onSubmit}
                                                overrides={{
                                                    BaseButton: {
                                                        style: () => ({
                                                            width: '100%',
                                                            borderTopLeftRadius: '3px',
                                                            borderTopRightRadius: '3px',
                                                            borderBottomLeftRadius: '3px',
                                                            borderBottomRightRadius: '3px',
                                                        }),
                                                    },
                                                }}
                                            >
                                                Transfer
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </Wrapper>
                </Col>
            </Row>
        </div>
    );
};

export default ProductTransferRequest;
