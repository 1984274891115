export const GetValidNumberStr = (v: string) => {
    let result = ""
    let parts = v.split(".")

    if(parts.length > 2) return {value: result, isValid: false}
    
    if(v !== "")
    {
        let decNum = parseFloat(parts[0])
        if(Number.isNaN(decNum)) return;
        result = `${decNum}`;

        if(parts.length === 2){
            let frac = ""
            if(parts[1] === ""){
                frac = "."
            }else{
                if(Number.isNaN(parseFloat(parts[1]))) return {value: result, isValid: false}
                frac = `.${parseFloat(parts[1])}`  
            }
            result = result + frac
        }
    }
    return {value: result, isValid: true};
}