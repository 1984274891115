// dependencies
import React, { useCallback } from 'react';
import { useDrawerDispatch } from 'context/DrawerContext';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { Plus } from 'assets/icons/PlusMinus';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import CustomDataTable from "../../../components/DataTable/CustomDataTable";
import { DateFormat } from "../../../settings/common";
import { Grid, Row, Col } from 'components/FlexBox/FlexBox';
import Checkbox from "../../../components/CheckBox/CheckBox";
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { AiOutlineWeibo } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { PURCHASE_INVOICE_ADD, VIEW_INVOICE_CLOSING } from "../../../settings/constants";
import { Filter } from 'baseui/icon';

const deliveryOrdersList = [
   {
      orderNo: '123',
      pathaoId: '13',
      title: 'Item 1',
      status: 'Delivered',
      productQty: 10,
      returnedQty: 5,
      deliveredSum: 500,
      pathaoCost: 50
   },
   {
      orderNo: '15',
      pathaoId: '17',
      title: 'Item 2',
      status: 'Delivered',
      productQty: 20,
      returnedQty: 6,
      deliveredSum: 590,
      pathaoCost: 60
   },
   {
      orderNo: '676',
      pathaoId: '23',
      title: 'Item 3',
      status: 'Pending',
      productQty: 40,
      returnedQty: 30,
      deliveredSum: 5000,
      pathaoCost: 70
   }
]


const ViewDeliveryOrders = () => {
   const history = useHistory();


   const columns = React.useMemo(
      () => [
         {
            Header: 'Order',
            accessor: 'title',
            Filter: false

         },
         {
            Header: 'Status',
            accessor: 'status',
            Filter: false

         },
         {
            Header: 'Product Qty',
            accessor: 'productQty',
            Filter: false

         },
         {
            Header: 'Returned',
            accessor: 'returnedQty',
            Filter: false

         },
         {
            Header: 'Delivered Sum.',
            accessor: 'deliveredSum',
            Filter: false
         },
         {
            Header: "Pathao Cost",
            accessor: 'pathaoCost',
            // disableFilters: true,
            // disableSortBy: true
            Filter: false
         },
         {
            Header: 'Invoice Closing',
            accessor: 'actions',
            Filter: false,
         }
      ],
      []
   );

   const table = React.useMemo(
      () => {
         let StocksList = []
         if (deliveryOrdersList) {
            StocksList = deliveryOrdersList.map((item) => {
               return {
                  ...item,
                  actions: <div style={{ fontSize: "large" }}>
                     <AiOutlineWeibo
                        title="View"
                        onClick={() => {
                           history.push({
                              pathname: VIEW_INVOICE_CLOSING,
                              state: { detail: 'some_value' }
                           })
                        }}
                     />
                  </div>
               }
            })
         }
         return [<CustomDataTable columns={columns} data={StocksList} loading={false} title={"Delivery Order List"} />]
      },
      [deliveryOrdersList])

   return (
      <Grid fluid={true}>

         <Col md={12}>
            <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', paddingTop: 30 }}>
               {table}
            </Wrapper>
         </Col>
      </Grid>
   );
};

export default ViewDeliveryOrders;
