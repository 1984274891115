import { Checkbox } from 'baseui/checkbox';
import { Col, Row } from 'components/FlexBox/FlexBox';
import { Button } from 'baseui/button';
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { Select } from 'baseui/select';
import { Header, Heading } from 'components/Wrapper.style';
import { mainRoutes } from 'routes';
import useCustomSnackBar from 'hooks/useCustomSnackBar';

export const GET_USER_FEATURES = gql`query{
    userFeatureQuery{
      getUserFeatures{
        active
        userId
        roleId
        featureId
        user{
          fullName
        }
      }
    }
  }`

const GET_CLIENT_MODULES = gql`query($clientId: Int $agentType: UserAgentStatus){
    moduleQuery{
      clientModules(clientId: $clientId, agentType: $agentType){
        id
        name
        moduleId
        code
        globalId
      }
    }
  }`

const ADD_CLIENT_FEATURES = gql`
mutation($clientId: Int! $modules: [ERPModuleRequest]!){
    master{
      addClientModule(clientId: $clientId modules: $modules){
        success
      }
    }
  }`

const GET_CLIENTS = gql`
query{
    moduleQuery{
      clients{
        id
        title
      }
    }
  }
`

const CamelCaseConverter = (stringValue: string) => {
    return stringValue.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
}

function ClientFeatureAssgin() {
    const enqueue = useCustomSnackBar();
    const [client, setClient] = useState([]);
    const [modules, setModules] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedItems, setSeletedItems] = useState([]);

    useEffect(() => {
        // let module = handleGroupRouting(mainRoutes);
        // setModules(module)
    }, [])

    const handleOptionChange = (e: any) => {
        reset()
        let module = handleGroupRouting(mainRoutes) || [];
        setModules(JSON.parse(JSON.stringify(module)))
        setClient([e]);
        requestClientModules({
            variables: {
                clientId: e.value,
                agentType: "POCKET_X"
            }
        })
    };

    useQuery(GET_CLIENTS, {
        onCompleted: (data) => {
            var clients = data?.moduleQuery?.clients?.map((item) => ({
                value: item.id,
                label: item.title
            }))
            setClientOptions(clients)
        }
    });

    const handleGroupRouting = (items: any) => {
        let routes = [];
        items.map((item: any) => {
            if (item.isMenu) {
                let childrens = items.filter((a: any) => a.parentId === item.id);
                item.childrens = childrens;
                routes.push(item);
            }
            return routes;
        })
        return routes;
    }

    const [requestClientModules] = useLazyQuery(GET_CLIENT_MODULES, {
        onCompleted: (d) => {
            let clientModules = d?.moduleQuery?.clientModules || [];
            let allModules = []
            for (let i = 0; i < clientModules.length; i++) {
                let item = mainRoutes?.find(a => a.id === clientModules[i]?.globalId)
                if (item) {
                    let mod = {
                        id: item.id,
                        parentId: item.parentId,
                        component: item.component,
                        exact: item.exact,
                        icon: item.icon,
                        isMenu: item.isMenu,
                        name: CamelCaseConverter(item.name),
                        path: item.path,
                        roles: item.path,
                        isSelected: false,
                        assignedRoles: []
                    }
                    allModules.push(mod)
                }
            }
            //reset();

            if(allModules.length > 0){
                let parentModules = modules.filter(a => a.isMenu === true);
                let childrens = modules.flatMap(a => a.childrens);
                let moduleList = [...parentModules, ...childrens];
                for (let i = 0; i < allModules.length; i++) {
                    let targetModule = moduleList.find(a => a.id === allModules[i].id);
                    targetModule.isSelected = true;
                }
                let module = handleGroupRouting(moduleList);
                setModules(module);
            }
        }
    });

    const handleChangeCheck = (id: string, value: boolean) => {
        let parentModules = modules.filter(a => a.isMenu === true);
        let childrens = modules.flatMap(a => a.childrens);
        let moduleList = [...parentModules, ...childrens];

        let targetModule = moduleList.find(a => a.id === id);
        targetModule.isSelected = value;

        let items = JSON.parse(JSON.stringify(selectedItems))
        let filteredItems = items.filter(a => a.globalId !== id);
        filteredItems.push({
            globalId: id,
            active: value,
        })
        setSeletedItems(filteredItems)
    }

    const [assignClientFeature, { loading }] = useMutation(ADD_CLIENT_FEATURES, {
        onCompleted: (d) => {
            if (d?.master?.addClientModule?.success) {
                enqueue("success", "Assigned feature successfully");
            }
        },
        onError: (e) => {
            enqueue("error", "Feature assigned failed");
        }
    })

    const handleSubmit = () => {
        assignClientFeature({
            variables: {
                clientId: client[0].value,
                modules: selectedItems
            }
        });
    }

    const reset = () => {
        setModules([])
    }

    return (
        <Row>
            <Col md={12}>
                <Header
                    style={{
                        marginBottom: 15,
                        boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                    }}
                >
                    <Col md={4} xs={12}>
                        <Heading>Assign Client Features</Heading>
                    </Col>
                </Header>

                <Header
                    style={{
                        marginBottom: 15,
                        boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                    }}
                >
                    <Col md={12} xs={12}>
                        <Row>
                            <Col md={3}  >
                                <Select
                                    placeholder="Select Client"
                                    clearable={true}
                                    labelKey="label"
                                    valueKey="value"
                                    options={clientOptions}
                                    onChange={(e) => handleOptionChange(e.option)}
                                />
                            </Col>
                            <Col md={2} >
                                <Button onClick={handleSubmit}
                                    disabled={loading}>{loading ? "Assigning" : "Assign"}
                                </Button>
                            </Col>
                        </Row>
                        {
                            client.length > 0 &&
                            <Row style={{ height: "30px", marginTop: "20px" }}>
                                <Col>
                                    {
                                        client?.map((item: any) => {
                                            return (
                                                <span style={{
                                                    color: 'white',
                                                    backgroundColor: "green",
                                                    padding: "8px",
                                                    borderRadius: "3px",
                                                    marginRight: "5px"
                                                }}><b>{item.label}</b></span>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        }
                    </Col>
                </Header>
                <Header
                    style={{
                        marginBottom: 15,
                        boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                    }}
                >
                    <Col md={12} xs={12}>
                        <Row style={{ backgroundColor: "#D3D3D3", borderRadius: "5px" }}>
                            <Col style={{ paddingTop: "12px", paddingBottom: "12px" }}>
                                <Checkbox
                                    checked={selectAll}
                                    onChange={(e: any) => {
                                        setSelectAll(e.target.checked);
                                        let moduleList = [...modules];
                                        moduleList.map((a: any) => a.isSelected = e.target.checked);
                                        moduleList.map((a: any) => a?.childrens?.map((b: any) => b.isSelected = e.target.checked));
                                        setModules(moduleList);
                                    }}
                                >
                                    <span style={{ fontSize: 24, fontWeight: "normal" }}>
                                        {selectAll ? "Unselect All" : "Select All"}
                                    </span>
                                </Checkbox>
                            </Col>
                        </Row>
                        {
                            modules.map((item: any) => (
                                <>
                                    <Row style={{ backgroundColor: item.parentId == null ? "#A9D0D9" : "#F7F7F7", borderRadius: "5px" }}>
                                        <Col style={{ paddingTop: "10px", paddingBottom: "10px" }} md={3}>
                                            <Checkbox
                                                checked={item.isSelected}
                                                onChange={(e: any) => handleChangeCheck(item.id, e.target.checked)}
                                            >
                                                <span style={{ fontSize: 22, fontWeight: "normal" }}>
                                                    {item.name}
                                                </span>
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    {
                                        item?.childrens?.map((c: any) => (
                                            <Row style={{ backgroundColor: "#F7F7F7", borderRadius: "5px" }}>
                                                <Col style={{ paddingTop: "10px", paddingBottom: "10px", marginLeft: '35px' }} md={3}>
                                                    <Checkbox
                                                        checked={c.isSelected}
                                                        onChange={(e: any) => handleChangeCheck(c.id, e.target.checked)}
                                                    >
                                                        <span style={{ fontSize: 22, fontWeight: "normal" }}>
                                                            {c.name}
                                                        </span>
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </>
                            ))
                        }
                    </Col>
                </Header>
            </Col>
        </Row>
    )
}

export default ClientFeatureAssgin;
