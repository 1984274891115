import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import {
   ApolloProvider,
   ApolloClient,
   InMemoryCache,
   createHttpLink,
} from '@apollo/client';
import { theme, GrayTheme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import { setContext } from '@apollo/client/link/context';
import { Auth } from './settings/common';
import { API_URL } from './settings/apiUrls';
import { createUploadLink } from 'apollo-upload-client';
import {
   AUTH_DATA,
   SYSTEM_SETTINGS_DATA,
   USER_FEATURES_DATA,
} from './settings/constants';
import { SystemSettings } from './settings/common';

const httpLink = createUploadLink({
   uri: API_URL,
});

declare global {
   interface Array<T> {
      groupBy(elem: T): any;
   }
   interface Number {
      toMoney(needFraction?: boolean): any;
   }
   interface String {
      toSeoUrl(): any;
   }
}

Object.defineProperty(Array.prototype, 'groupBy', {
   enumerable: false,
   value: function (key) {
      return this.reduce(function (rv, x) {
         (rv[x[key]] = rv[x[key]] || []).push(x);
         return rv;
      }, {});
   },
});

Number.prototype.toMoney = function (needFraction = true) {
   var parts = this.toFixed(2).split('.');
   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   return `${needFraction ? parts.join('.') : parts[0]}`;
};

String.prototype.toSeoUrl = function () {
   var url = this;
   // make the url lowercase
   var encodedUrl = url.toString().toLowerCase();

   // replace & with and
   encodedUrl = encodedUrl.split(/\&+/).join('-and-');

   // remove invalid characters
   encodedUrl = encodedUrl.split(/[^a-z0-9]/).join('-');

   // remove duplicates
   encodedUrl = encodedUrl.split(/-+/).join('-');

   // trim leading & trailing characters
   encodedUrl = encodedUrl.trim('-');

   return encodedUrl;
};

function App() {
   // get theme info
   let settings = SystemSettings.get();

   let baseTheme;

   // set base theme according to settings
   switch (settings?.UITheme) {
      case 'GrayTheme':
         baseTheme = GrayTheme;
         break;

      default:
         baseTheme = theme;
         break;
   }

   const authLink = setContext((_, { headers }) => {
      const authData = Auth.get();
      return {
         headers: {
            ...headers,
            authorization:
               authData && authData.token ? `Bearer ${authData.token}` : '',
         },
      };
   });

   const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
   });

   const engine = new Styletron();

   //to remove token when user closing the browser
   window.onunload = function () {
      if (SystemSettings.get().RemoveTokenOnAppClose) {
         window.localStorage.removeItem(AUTH_DATA);
         window.localStorage.removeItem(SYSTEM_SETTINGS_DATA);
         window.localStorage.removeItem(USER_FEATURES_DATA);
      }
   };

   return (
      <ApolloProvider client={client as any}>
         <StyletronProvider value={engine}>
            <BaseProvider theme={baseTheme}>
               <BrowserRouter>
                  <Routes />
               </BrowserRouter>
            </BaseProvider>
         </StyletronProvider>
      </ApolloProvider>
   );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
