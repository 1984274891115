// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const LOGIN = '/login';

export const SYSTEM_SETTINGS = '/settings/system';
export const REPORT_SETTINGS = '/settings/report';
export const DISCOUNT_SETTINGS = '/settings/discount';
export const VAT_SETTINGS = '/settings/vat'
export const CATEGORY_SETTINGS = '/settings/category';
export const VARIANT_SETTINGS = '/settings/variant-list';
export const CLIENT_SETTINGS = '/settings/client';
export const ACCOUNTING_CASH = '/accounting/cash';
export const ACCOUNTING_BANK = '/accounting/bank';
export const ACCOUNTING_SUPPLIERS = '/accounting/suppliers';
export const ACCOUNTING_SUPPLIER_REPORT = '/accounting/supplier-report';
export const ACCOUNTING_EXPENSE = '/accounting/expense';
export const ACCOUNTING_SALES = '/accounting/sales';
export const ACCOUNTING_CLIENTS = '/accounting/clients';
export const ACCOUNTING_CLIENT_REPORT = '/accounting/client-report';
export const ACCOUNTING_DISCOUNT = '/accounting/discount';
export const ACCOUNTING_SALES_RETURN = '/accounting/sales-return';
export const SUPPLIER_SETTINGS = '/settings/supplier';
export const BANK_SETTINGS = '/settings/bank';
export const EXPENSE_SETTINGS = '/settings/expense';
export const USER_SETTINGS = '/settings/users';
export const PRODUCT_SETTINGS = '/settings/product';
export const SETTINGS = '/settings';
export const QUOTATION = "/sales/quotation";
export const ADD_ORDER = "/sales/add-order";
export const ADD_PATHAO_ORDER = "/sales/add-pathao-order";
export const ORDER_INVOICE = "/sales/order-invoice";
export const CUSTOM_ORDER = "/sales/custom-order";
export const QUOTATION_ADD = "/sales/quotation/add";
export const PARTHAO_ORDER_ADD = "/sales/porders";
export const PARTHAO_ORDER_LIST = "/sales/pathao-orders";

export const ACCOUNTING_REPORTS = '/accounting'
export const ACCOUNTING_DETAILS = '/accounting/details';
export const ACCOUNTING_REPORT_DETAILS = '/accounting/report/details';
export const ASSET_MANAGEMENT = '/accounting/asset-management';
export const ADD_ASSET = '/accounting/add-asset';
export const BANK_DETAILS = '/bank/details';
export const EXPENSE_DETAILS = '/expense/details';
export const CLIENT_DETAILS = '/client/details';
export const SUPPLIER_DETAILS = '/supplier/details';
export const CLIENT_REPORT = '/reports/client-report';
export const SUPPLIER_REPORT = '/reports/supplier-report';
export const BANK_REPORT = '/accounting/bank-report';

export const PURCHASE_INVOICE_ADD = "/inventory/purchase-invoice/add";
export const LOW_STOCK_PRODUCTS = "/report/low-stock-products";
export const PURCHASE_INVOICE = '/inventory/purchase-invoice';
export const PURCHASE_RETURN = '/inventory/purchase-return';
export const INVENTORY_STOCK = '/inventory/stock';
export const SEND_PRODUCT = '/inventory/send-product';
export const RECEIVE_PRODUCT = '/inventory/receive-product';
export const PRODUCT_TRANSFER_REQUEST = '/inventory/new-transfer-request';
export const INVENTORY = '/inventory';
export const INVENTORY_REPORT = '/inventory/inventory-report';
export const INVENTORY_LABEL_PRINT = '/inventory/label-print';
export const INVENTORY_INVENTORY_OPENING = '/inventory/inventory-opening';

export const INVENTORY_AUDIT_EXECUTE = '/inventory/inventory-audit-execute';
export const INVENTORY_AUDIT = '/inventory/inventory-audit-list';
export const STOCK_TRANSFORM = '/inventory/stock-transform';
export const ADD_UPCOMING_STOCKS = '/inventory/add-upcoming-stock';
export const VIEW_UPCOMING_STOCKS = '/inventory/upcoming-stock';
export const VIEW_DELIVERY_ORDERS = '/inventory/delivery-orders';
export const VIEW_INVOICE_CLOSING = '/inventory/delivery-orders/invoice-closing';
export const VIEW_CALENDAR = '/inventory/view-calendar';


export const SALES_INVOICE_ADD = "/sales/invoice/add";
export const SALES_INVOICE_ADDITIONAL_INFO = "/sales/invoice/additital-info";

export const SALES_INVOICE = "/sales/invoice";
export const SALES = "/sales";
export const SALES_INVOICE_RETURN = "/sales/returns";

export const VIEW_SUPPLIER_PAYMENT = '/transactions/supplier-payment/view';
export const SUPPLIER_PAYMENT = '/transactions/supplier-payment';
export const VIEW_EXPENSE_ENTRY = '/transactions/expense-entry/view';
export const EXPENSE_ENTRY = '/transactions/expense-entry';
export const VIEW_CASH_RECEIVE = '/transactions/cash-bank/view';
export const CASH_RECEIVE = '/transactions/cash-receive';
export const GENERAL_TRANSACTIONS = '/transactions/general';
export const TRANSACTIONS = '/transactions';

export const PRINT = "/print";

export const REPORT_INVENTORY = "/reports/inventory";
export const REPORT_CLIENT_SALES = "/reports/clientSales";
export const REPORT_TRANSACTION = "/reports/transaction";
export const BRANCH_SUMMERY_REPORT = "/reports/branch-summery";
export const REPORTS = "/reports";
export const CREATE_PROCESS = "/manufacturing/create-process";
export const COMPLETE_PROCESS = "/manufacturing/complete-process/:id";
export const CREATE_FORMULA = "/manufacturing/create-formula/:id";
export const VIEW_FORMULA = "/manufacturing/view-formula/:id";
export const VIEW_PROCESSES = "/manufacturing/processes";
export const VIEW_FORMULAS = "/manufacturing/formulas";
export const MANUFACTURING = "/manufacturing";
export const CHALLAN_INVOICE_REPORT = "report/challan-invoice";
export const SALES_INVOICE_REPORT = "report/sales-invoice";
export const STOCK_TRANSFER_REPORT = "report/stock-transfer";
export const REPORT_CASH_RECEPIT = "report/cash-invoice";
export const ACCOUNTING_REPORT = "report/accounting";

export const BRANCH = '/branch';
export const ADD_BRANCH = "/branch/add-branch";
export const USER_FEATURE_ASSIGN = '/settings/user-feature-assign';
export const USER_FEATURE_UNASSIGN = '/settings/user-feature-unassign';
export const CLIENT_FEATURE_ASSIGN = '/settings/client-feature-assign';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '৳';
export const PERCENTAGE = '%'
export const AUTH_DATA = "7c542d0b-e857-4c52-a54f-59585b43a52e";
export const SYSTEM_SETTINGS_DATA = "3737e4ec-6025-11ed-9b6a-0242ac120002";
export const USER_FEATURES_DATA = "2f538e3f-a642-4be8-bdc7-7ff6b06b4a07";

