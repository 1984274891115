import React, { useEffect, useState, useMemo } from 'react';
import { Grid } from 'components/FlexBox/FlexBox';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Wrapper, Heading } from 'components/Wrapper.style';
import { Col, Row } from "./CustomOrder.style"
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { MdDelete } from 'react-icons/md';
import DrawerBox from "../../../components/DrawerBox/DrawerBox";
import Input from "../../../components/Input/Input";
import { Button } from "baseui/button";
import { Card } from 'baseui/card';
import { GET_CLIENTS } from './gql/queries';
import { Clients } from 'settings/accountType';
import { FormFields } from 'components/FormFields/FormFields';
import { Select, SIZE as SelectSize } from 'baseui/select';
import { Search } from 'baseui/icon';
import { PRODUCT_QUERY, STOCK_QUERY } from '../AddSalesInvoice';
import PostLoader from 'components/Placeholder/Placeholder';

const POCKETX_ADD_ORDER = gql`
mutation($inv: SalesInvoiceDTORequestType!){
    sales{
      addOrder(order:$inv){
        success
      }
    }
  }`;

export default function CustomOrderView() {
    // const dispatch = useDrawerDispatch();
    const enqueue = useCustomSnackBar();
    const [phone, setPhone] = useState([]);
    const [isNewClient, setIsNewClient] = useState(false)
    const [client, setClient] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [products, setProducts] = useState([]);
    const [isSalesAllowed, setIsSalesAllowed] = useState("false"); //can sale if quantity is insufficient
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [currentQuantity, setCurrentQuantity] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totalVat, setTotalVat] = useState(0);


    // const [clientList, setClientList] = useState([]);
    // const [selectedClient, setSelectedClient] = useState(null);

    function handleChange(value, setter) {
        let client;
        if (value.length > 0) {
            client = value && clients?.accountQuery?.accounts?.[0]?.childs?.find((a) => a.id === value?.[0].value);
        }

        if (value.length > 0) {
            setter(value);
            if (client) setSelectedClient(client)
            if (value[0].isCreatable) {
                setIsNewClient(true);
            }
            else {
                setIsNewClient(value[0].isCreatable)
            }
            setClient(value[0].value > 0 ? value[0].name : "");
        } else {
            setClient("")
            setPhone([])
            setSelectedClient(null)
            setIsNewClient(false)
        }
    }

    // gql queries
    const { data: clients } = useQuery(GET_CLIENTS, {
        fetchPolicy: "network-only",
        variables: {
            query: {
                params: [
                    {
                        "key": "parentId",
                        "value": Clients + ""
                    },
                    {
                        "key": "onlyLeaf",
                        "value": "true"
                    },
                    {
                        "key": "forContact",
                        "value": "true"
                    },
                    {
                        "key": "filter",
                        "value": "clients"
                    }
                ]
            }
        },
        // onCompleted: (res) => {
        //     const clients = res?.accountQuery?.accounts?.[0]?.childs
        //     setClientList(clients)
        // },
        // onError: (err) => {
        //     console.log(err);
        // }
    })

    // const handleSelectedClient = (client) => {
    //     setSelectedClient(client);
    //     setClientList([])
    // }

    // get products
    const [loadProducts, { loading, error }] = useLazyQuery(
        PRODUCT_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (res) => {
            setProducts(res?.productQuery?.products?.items);

        },
    }
    )

    const [loadStocks, { loading: loadingStocks, error: stockError }] = useLazyQuery(STOCK_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (res) => {
            const baseResponse = res?.stockQuery?.available[0];

            // get discount obj
            let discountObj = baseResponse?.product.priceExtensions?.find(a => a.priceType === "DISCOUNT" && a.active === true) || '';

            // get VAT obj
            let vatObj = baseResponse?.product.priceExtensions?.find(a => a.priceType === "VAT" && a.active === true) || '';

            // calc VAT percentage or value
            let vat;
            let VATamount;
            if (vatObj) {
                if (vatObj.amountType === "PERCENTAGE") {
                    vat = vatObj.amount;
                    VATamount = (baseResponse.product.salesPrice * currentQuantity) * vatObj.amount / 100;
                } else {
                    VATamount = vatObj.amount;
                }
            } else {
                VATamount = ''
                vat = ''
            }

            // calc discount percentage or value
            let discount;
            let discountValue;
            if (discountObj) {
                if (discountObj.amountType === "PERCENTAGE") {
                    discount = discountObj.amount;
                    discountValue = (((baseResponse.product.salesPrice * discount)) / 100) * currentQuantity
                } else {
                    discount = ''
                    discountValue = discountObj.amount
                }
            } else {
                discount = ''
                discountValue = ''
            }

            // get price
            let price = ((baseResponse.product.salesPrice * currentQuantity) - discountValue) + VATamount;

            const data = { ...baseResponse, orderQuantity: currentQuantity, discount, discountValue, price, isExpanded: false, vat, VATamount }

            setSelectedProducts((prev) => ([...prev, data]));
        }
    })

    const productSelectionHandler = (productId, quantity) => {

        // if there is no quantity, remove the product from selectedProducts arr
        if (quantity === '') {
            setSelectedProducts((prev) => prev.filter((a) => a.product.id !== productId));
            return
        }

        setCurrentQuantity(parseInt(quantity));

        const checkIsAlreadyExist = selectedProducts.some((a) => a.product.id === productId * 1);

        if (!checkIsAlreadyExist) {
            loadStocks({
                variables: {
                    query: {
                        params: [
                            { key: "showZeroStock", value: isSalesAllowed },
                            { key: "summary", value: "true" },
                            { key: "onlyOwnOrg", value: "true" },
                            { key: "directSalesPrice", value: "true" },
                            { key: "productIds", value: JSON.stringify([productId]) }
                        ]
                    }
                }
            })
        }
        else if (!loadingStocks) {
            const copyArr = structuredClone(selectedProducts);

            copyArr.forEach((item) => {
                if (item.product.id === productId) {
                    item.orderQuantity = quantity * 1;
                    item.discountValue = ((item.product.salesPrice * item.orderQuantity) * item.discount) / 100
                    item.VATamount = (item.product.salesPrice * item.orderQuantity) * item.vat / 100
                    item.price = ((item.product.salesPrice * item.orderQuantity) - item.discountValue) + item.VATamount
                }
            })

            setSelectedProducts(copyArr);
        }

    }

    const removeProductHandler = (id) => {
        setSelectedProducts((prev) => prev.filter((a) => a.product.id !== id));
    }

    const calculateQuantityPriceDiscount = (name, value, productId) => {
        // deep copy the current state
        const copySelectedProducts = structuredClone(selectedProducts);

        switch (name) {
            case 'salesPrice':
                // check if the item has discount and the price is grater than discountValue
                const item = copySelectedProducts.find((a) => a.product.id === productId);
                if (item.discountValue && item.discountValue > parseInt(value)) {
                    copySelectedProducts.forEach((item) => {
                        if (item.product.id === productId) {
                            item.product.salesPrice = item.discountValue + 1;
                        }
                        setSelectedProducts(copySelectedProducts)
                    })
                    return
                }

                if (value === '') {
                    copySelectedProducts.forEach((item) => {
                        if (item.product.id === productId) {
                            item.product.salesPrice = ''
                            item.price = 0
                        }
                    })
                    setSelectedProducts(copySelectedProducts)
                    return
                }
                // update salesPrice
                copySelectedProducts.forEach((item) => {
                    if (item.product.id === productId) {
                        // update the salesPrice
                        item.product.salesPrice = parseInt(value);

                        // check if item has discount
                        if (item.discountValue) {
                            item.price = (item.product.salesPrice * item.orderQuantity) - item.discountValue
                        } else {
                            item.price = (parseInt(value) * item.orderQuantity)
                        }

                        // check if item has VAT
                        if (item.VATamount) {
                            item.VATamount = (item.product.salesPrice * item.orderQuantity) * item.vat / 100;
                            item.price = item.price + item.VATamount
                        }
                    }
                })
                setSelectedProducts(copySelectedProducts)
                break

            case 'discount':
                // check if the item has discount and the price is grater than discountValue
                const product = copySelectedProducts.find((a) => a.product.id === productId);
                if (product.product.salesPrice && product.product.salesPrice < parseInt(value)) {
                    copySelectedProducts.forEach((item) => {
                        if (item.product.id === productId && item.product.salesPrice < parseInt(value)) {
                            item.discountValue = item.product.salesPrice - 1;
                        }
                        setSelectedProducts(copySelectedProducts)

                    })
                    return
                }

                if (value === '') {
                    copySelectedProducts.forEach((item) => {
                        if (item.product.id === productId) {
                            const VATamount = item.VATamount ? item.VATamount : 0

                            item.discountValue = ''
                            item.price = (item.product.salesPrice * item.orderQuantity) + VATamount;
                        }
                    })
                    setSelectedProducts(copySelectedProducts)
                    return
                }
                else {
                    copySelectedProducts.forEach((item) => {
                        if (item.product.id === productId) {
                            const VATamount = item.VATamount ? item.VATamount : 0

                            item.discountValue = parseInt(value);
                            item.price = ((item.product.salesPrice * item.orderQuantity) - parseInt(value)) + VATamount
                        }
                    })
                    setSelectedProducts(copySelectedProducts)
                }
                break

            case 'orderQuantity':
                if (value === '') {
                    const updatedItems = copySelectedProducts.filter((item) => item.product.id !== productId)
                    setSelectedProducts(updatedItems)
                    return
                }

                const newQuantity = value * 1;

                // update the state
                copySelectedProducts.forEach((item) => {
                    if (item.product.id === productId) {
                        item.orderQuantity = newQuantity;
                        item.discountValue = ((item.product.salesPrice * item.orderQuantity) * item.discount) / 100
                        item.VATamount = (item.product.salesPrice * item.orderQuantity) * item.vat / 100
                        item.price = ((item.product.salesPrice * item.orderQuantity) - item.discountValue) + item.VATamount
                    }
                })
                setSelectedProducts(copySelectedProducts)
                break;

            default:
                break
        }
    }


    function handleAutoComplete(e) {
        const val = e.target.value.toLowerCase();
        if (val === "") setProducts([]);
        if (val.length < 2) return;
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 20,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val,
                    params: [
                        {
                            key: "checkStock",
                            value: isSalesAllowed === "true" ? "false" : "true"
                        }
                    ]
                }
            }
        });
    }

    const toggleExpansion = (productId) => {
        setSelectedProducts((prev) => prev.map((product) => product.product.id === productId ? { ...product, isExpanded: !product.isExpanded } : product))
    }

    const generateClientLabel = (account) => {
        return (account.contact && account.contact.number) ? `${account.contact.number} - ${account.name}` : account.name;
    }

    const numberOptions = useMemo(() => {
        return clients && clients.accountQuery && clients.accountQuery.accounts && clients.accountQuery.accounts[0].childs ?
            clients.accountQuery.accounts[0].childs.map(a => ({ value: a.id, name: a.name, label: generateClientLabel(a) })) :
            [];
    }, [clients]);

    // const openDrawer = useCallback(
    //     () =>
    //         dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'ADD_CLIENT' }),
    //     [dispatch]
    // );

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const hoverStyles = {
        backgroundColor: isHovered ? '#EEEEEE' : 'transparent',
        padding: '15px 10px',
        margin: 0,
        marginTop: '5px',
        borderBottom: '1px solid #EEEEEE',
    };

    const calcSubTotal = (products) => {
        const subTotal = products.reduce((acc, curr) =>
            acc + (curr.product.salesPrice * curr.orderQuantity)
            , 0)

        setSubTotal(parseInt(subTotal))
    }

    const calcDiscount = (products) => {

        let totalDiscountPrice = 0;

        for (const product of products) {
            totalDiscountPrice += product.discountValue;
        }
        setDiscount(parseInt(totalDiscountPrice as any))
    }

    const calcVAT = (products) => {
        let sum = 0;
        for (let item of products) {
            sum += item.VATamount
        }
        setTotalVat(parseInt(sum as any))
    }

    // place order mutation
    const [placeOrder, { loading: loadingPlacingOrder }] = useMutation(POCKETX_ADD_ORDER, {
        onCompleted: (res) => {
            setProducts([]);
            setSelectedProducts([]);
            setSelectedClient(null);
            enqueue('success', 'Order placed successfully!')
        }
    });

    const handlePlaceOrder = () => {

        if (selectedClient) {
            const productList = selectedProducts.map((item) => ({
                // permutationId: 6,
                discount: parseInt(item?.discountValue),
                price: parseInt(item?.price),
                quantity: parseInt(item?.orderQuantity),
                productId: item?.product?.id,
                vat: parseInt(item?.VATamount)
            }))

            placeOrder({
                variables: {
                    inv: {
                        clientId: selectedClient?.id,
                        status: "PENDING_ORDER",
                        total: (subTotal - discount) + totalVat,
                        vat: totalVat,
                        //salesInvoiceItems: productList,
                        invoiceItems: productList
                    }
                }
            })
        } else {
            enqueue('error', 'Please select a client!')
        }
    }

    useEffect(() => {
        calcSubTotal(selectedProducts)
        calcDiscount(selectedProducts)
        calcVAT(selectedProducts)
    }, [selectedProducts]);

    return (
        <Grid fluid={true}>
            <DrawerBox>
                <Row>
                    <Col md={12}>
                        {/* <Header
                            style={{
                                marginBottom: 40,
                                boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                            }}
                        >
                            <Col md={4} xs={12}>
                                <Heading>Custom Order</Heading>
                            </Col>
                        </Header> */}
                        <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                            <Row style={{ alignItems: 'start' }}>
                                <Col md={2} xs={12}>
                                    <div style={{ padding: '10px' }} className="categories">
                                        <Heading>Categories</Heading>
                                        <div className="category-list">
                                            <p onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                style={hoverStyles}>Category - 1
                                            </p>
                                            <p onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                style={hoverStyles}>Category - 2
                                            </p>
                                            <p onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                style={hoverStyles}>Category - 3
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={12}>
                                    <div style={{ padding: '10px' }} className='custom-order-left'>
                                        {/* search for product */}
                                        {/* <Heading style={{ marginBottom: 10 }}>Search for product</Heading> */}
                                        <Input onChange={handleAutoComplete} endEnhancer={<Search size="18px" />} placeholder='Product name...' />
                                        <Row className="products">
                                            {loading ? <PostLoader /> : <>
                                                {
                                                    products.length > 0 ? products.map((product) => <Col style={{ marginTop: 10 }} md={3} xs={12} sm={6}>
                                                        <Card
                                                            overrides={{ Root: { style: { width: '100%' } } }}
                                                            headerImage={
                                                                'https://source.unsplash.com/user/erondu/700x400'
                                                            }
                                                        >
                                                            <p>{product.title}</p>
                                                            <Input onChange={(e) => productSelectionHandler(product.id, e.target.value)} placeholder='Quantity...' />
                                                        </Card>
                                                    </Col>) : <div style={{ textAlign: 'center', height: '100%', margin: '20px auto' }}>
                                                        <Heading>No Product Found</Heading>
                                                    </div>
                                                }
                                            </>}
                                        </Row>
                                    </div>
                                </Col>

                                <Col md={4} xs={12}>
                                    <div className='custom-order-right' style={{ padding: '10px', background: '#EEEEEE' }} >
                                        <div style={{ background: '#fff', padding: '10px', marginTop: '10px' }} className="product-cart">
                                            <div style={{ marginBottom: '10px' }} className="add-user">
                                                {/* previous search bar option */}
                                                {/* <div>
                                                    <Heading style={{ marginBottom: 5 }}>Search for Client</Heading>
                                                    <Input placeholder='Client name...' />
                                                </div>
                                                <div style={{ marginTop: '10px', boxShadow: "rgba(0, 0, 0, 0.1) 0px 9px 16px 4px", padding: '10px' }} className="client-search-result">
                                                    {
                                                        clientList?.map((client) => <div onClick={() => handleSelectedClient(client)} key={client.id} style={{ display: 'flex', cursor: 'pointer' }} className="suggested-client-name">
                                                            <div style={{ marginRight: 10 }} className="client-img">
                                                                <img src="https://source.unsplash.com/user/erondu/50x50" alt='client img' />
                                                            </div>
                                                            <div className="client-details">
                                                                <p style={{ margin: 0, fontWeight: 'bold' }}>{client?.name}</p>
                                                                <p style={{ margin: 0 }}>{client?.contact?.number}</p>
                                                            </div>
                                                        </div>)
                                                    }

                                                    {
                                                        selectedClient && <>
                                                            <Heading style={{ marginBottom: 5 }}>Selected Client</Heading>
                                                            <div style={{ marginTop: '10px', boxShadow: "inset 0px 0px 27px 5px rgba(0,0,0,0.1)", padding: '10px' }} className="client-search-result">
                                                                <div style={{ display: 'flex', cursor: 'pointer' }} className="suggested-client-name">
                                                                    <div style={{ marginRight: 10 }} className="client-img">
                                                                        <img src="https://source.unsplash.com/user/erondu/50x50" alt='client img' />
                                                                    </div>
                                                                    <div className="client-details">
                                                                        <p style={{ margin: 0, fontWeight: 'bold' }}>{selectedClient?.name}</p>
                                                                        <p style={{ margin: 0 }}>{selectedClient?.contact?.number}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div> */}

                                                <FormFields>
                                                    <Select
                                                        options={numberOptions || []}
                                                        labelKey="label"
                                                        valueKey="value"
                                                        value={phone}
                                                        searchable={true}
                                                        onChange={({ value }) => handleChange(value, setPhone)}
                                                        creatable={true}
                                                        size={SelectSize.compact}
                                                        placeholder={'Select Client...'}
                                                    />
                                                    {
                                                        selectedClient && <>
                                                            {/* <Heading style={{ marginBottom: 5 }}>Selected Client</Heading> */}
                                                            <div style={{ marginTop: '10px', boxShadow: "inset 0px 0px 15px 5px rgba(0,0,0,0.1)", padding: '7px 0px 3px 7px' }} className="client-search-result">
                                                                <div style={{ display: 'flex', cursor: 'pointer' }} className="suggested-client-name">
                                                                    <div style={{ marginRight: 10 }} className="client-img">
                                                                        <img src="https://source.unsplash.com/user/erondu/50x50" alt='client img' />
                                                                    </div>
                                                                    <div className="client-details">
                                                                        <p style={{ margin: 0, fontWeight: 'bold' }}>{selectedClient?.name}</p>
                                                                        <p style={{ margin: 0 }}>{selectedClient?.contact?.number}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </FormFields>
                                            </div>
                                        </div>

                                        {/* products */}
                                        <div style={{ background: '#fff', padding: '10px', marginTop: '10px', height: '300px', overflowX: 'hidden', overflowY: 'scroll' }} className="product-cart">
                                            {/* cart items */}
                                            <Heading style={{ marginTop: 5, marginBottom: 5 }}>Products</Heading>
                                            {
                                                selectedProducts?.map((product) => <div style={{ marginTop: '10px', boxShadow: 'rgba(0, 0, 0, 0.34) 0px 0px 20px -8px', padding: '10px', borderRadius: '5px' }}>
                                                    <div style={{ display: 'flex' }} className="single-cart-item">
                                                        <div onClick={() => toggleExpansion(product?.product.id)} style={{ flex: 0.3 }} className="toggle-icon"> <img style={{ height: "13px", width: "13px", cursor: 'pointer' }} src={product?.isExpanded ? '/arrows/arrow-down.png' : '/arrows/arrow-right.png'} alt="arrow" /> </div>

                                                        <div style={{
                                                            flex: 1, fontWeight: 'bold'
                                                        }} className="quantity"> {product?.quantity} </div>

                                                        < div style={{ flex: 5 }} className="product-title">
                                                            <p style={{ fontWeight: 'bold', margin: 0 }}>{product?.product?.title}</p>
                                                            <span>variation</span>
                                                        </div>

                                                        <div style={{ flex: 1 }} className="price">
                                                            <p style={{ margin: 0, color: 'rgb(22, 31, 106)', fontWeight: 500 }}>Total:</p>
                                                            <p style={{ fontWeight: 'bold', margin: 0 }}> {product?.price} </p>
                                                            {/* <del>500</del> */}
                                                        </div>

                                                        <div style={{ flex: 1, alignSelf: 'start', textAlign: 'right' }} className="remove">
                                                            <MdDelete onClick={() => removeProductHandler(product.product.id)} color='red' size={20} title="Delete" style={{ marginLeft: '10px' }} />
                                                        </div>
                                                    </div>

                                                    {/* quantity, price, and discount */}
                                                    {
                                                        product?.isExpanded && <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '5px' }} className="update-info">
                                                            <div style={{ flex: 1 }} className="update-quantity">
                                                                <p style={{ marginBottom: 10, fontWeight: 500 }}>Quantity</p>
                                                                <Input onChange={(e) => calculateQuantityPriceDiscount('orderQuantity', e.target.value, product?.product.id)} value={product?.orderQuantity} />
                                                            </div>

                                                            <div style={{ flex: 1 }} className="update-price">
                                                                <p style={{ marginBottom: 10, fontWeight: 500 }}>Price</p>
                                                                <Input onChange={(e) => calculateQuantityPriceDiscount('salesPrice', e.target.value, product?.product.id)} value={product?.product?.salesPrice} />
                                                            </div>

                                                            <div style={{ flex: 1 }} className="update-discount">
                                                                <p style={{ marginBottom: 10, fontWeight: 500 }}>Discount(৳)</p>
                                                                <Input onChange={(e) => calculateQuantityPriceDiscount('discount', e.target.value, product?.product.id)} value={product?.discountValue} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>)
                                            }
                                        </div>

                                        {/* summary */}
                                        <div style={{ background: '#fff', padding: '10px', marginTop: '10px' }} className="summary">
                                            <Heading style={{ marginTop: 5, marginBottom: 5 }}>Summary</Heading>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }} className="subtotal">
                                                <p style={{ margin: 0, paddingBottom: 5 }}>Subtotal: </p>
                                                <p style={{ margin: 0, paddingBottom: 5 }}>{subTotal}৳</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }} className="discount">
                                                <p style={{ margin: 0, paddingBottom: 5 }}>Discount: </p>
                                                <p style={{ margin: 0, paddingBottom: 5 }}>{discount}৳</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }} className="vat">
                                                <p style={{ margin: 0, paddingBottom: 5 }}>VAT: </p>
                                                <p style={{ margin: 0, paddingBottom: 5 }}>{totalVat}৳</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 700 }} className="total">
                                                <p style={{ margin: 0, paddingBottom: 5 }}>Total: </p>
                                                <p style={{ margin: 0, paddingBottom: 5 }}>{((subTotal * 1) - (discount * 1)) + (totalVat * 1)}৳</p>
                                            </div>
                                        </div>

                                        <div style={{ marginTop: '10px', textAlign: 'right' }} className="submit-btn">
                                            <Button isLoading={loadingPlacingOrder} disabled={loadingPlacingOrder} onClick={handlePlaceOrder}>Place Order</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Wrapper>
                    </Col>
                </Row>
            </DrawerBox>
        </Grid >
    );
}
