import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import { Button, KIND as ButtonKind, SHAPE, SIZE as ButtonSize, KIND } from "baseui/button";
import { Row, Col } from 'components/FlexBox/FlexBox';
import { FormFields, FormLabel } from "../../../components/FormFields/FormFields";
import { Select } from "baseui/select";
import Input from "../../../components/Input/Input";
import { CgClose } from "react-icons/cg";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import { Scrollbars } from 'react-custom-scrollbars';
import DrawerBox from "components/DrawerBox/DrawerBox";
import { ButtonGroup } from "containers/DrawerItems/DrawerItems.style";

const MultipleProductSelectModal = (props) => {
    const { purchaseItems, handleAutoComplete, productOptions = [] } = props;
    const enqueue = useCustomSnackBar();
    const [selectedItems, setSelectedItems] = useState([]);
    const [product, setProduct] = useState({ label: "", value: 0 });

    useEffect(() => { setSelectedItems([]) }, [])

    const addItem = (e) => {
        let item = e[0]
        if (!item) {
            setProduct({ label: "", value: 0 })
            return;
        }
        else {
            setProduct(item);
        }
        if (!purchaseItems.find(a => a.productId === item.value) && !selectedItems.find(a => a.value === item.value)) {
            let items = [...selectedItems]
            items.unshift(item)
            setSelectedItems(items)
        }
        else {
            enqueue("error", "Product already added!");
        }
    }

    const handleRemoveItem = (e) => {
        let items = JSON.parse(JSON.stringify(selectedItems))
        let filteredItems = items.filter(a => a.value !== e.value)
        setSelectedItems(filteredItems)
    }

    const handleAddProducts = () => {
        props.handleMultipleProductAdd(selectedItems);
        handleResetClose();
    }

    const handleResetClose = () => {
        props.onClose();
        setSelectedItems([]);
    }

    return (
        <React.Fragment>
            <Modal
                {...props}
                size={SIZE.full}
                role={ROLE.dialog}
            >
                <ModalHeader>Multiple Product Name/Code</ModalHeader>
                <Scrollbars
                    autoHide
                    renderView={(props) => (
                        <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
                    )}
                    renderTrackHorizontal={(props) => (
                        <div
                            {...props}
                            style={{ display: 'none' }}
                            className="track-horizontal"
                        />
                    )}
                >
                    <DrawerBox>
                        <ModalBody>
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <FormFields>
                                            <FormLabel>Product Name/Code</FormLabel>
                                            <Select
                                                options={productOptions || []}
                                                labelKey="label"
                                                valueKey="value"
                                                value={[product]}
                                                searchable={true}
                                                onChange={({ value }) => addItem(value)}
                                                onInputChange={handleAutoComplete}
                                                size={SIZE.default}
                                                closeOnSelect={false}
                                            />

                                            {/*<Input*/}
                                            {/*    //inputRef={register()}*/}
                                            {/*    onChange={({value}) => props.handleProductAdd(value)}*/}
                                            {/*    name="netPrice"*/}
                                            {/*/>*/}

                                        </FormFields>
                                    </Col>
                                    <Col md={6}>
                                        {selectedItems.map((a, i) => {
                                            return (
                                                <div key={a.label}>
                                                    <Row>
                                                        <Col md={10}>
                                                            <FormFields>
                                                                <Input
                                                                    value={a.label}
                                                                />
                                                            </FormFields>
                                                        </Col>
                                                        <Col md={2} style={{ alignSelf: 'center' }}><Button
                                                            size={ButtonSize.mini}
                                                            shape={SHAPE.circle}
                                                            type="button"
                                                            onClick={() => handleRemoveItem(a)}

                                                        >
                                                            <CgClose style={{ fontSize: 'larger' }} />
                                                        </Button></Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </Col>
                                </Row>

                            </div>
                        </ModalBody>
                    </DrawerBox>
                    <ButtonGroup>
                        <Button
                            kind={KIND.minimal}
                            type="button"
                            onClick={handleResetClose}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        width: '50%',
                                        borderTopLeftRadius: '3px',
                                        borderTopRightRadius: '3px',
                                        borderBottomRightRadius: '3px',
                                        borderBottomLeftRadius: '3px',
                                        marginRight: '15px',
                                        color: $theme.colors.red400,
                                    }),
                                },
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            type="button"
                            onClick={() => handleAddProducts()}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        width: '50%',
                                        borderTopLeftRadius: '3px',
                                        borderTopRightRadius: '3px',
                                        borderBottomRightRadius: '3px',
                                        borderBottomLeftRadius: '3px',
                                    }),
                                },
                            }}
                        >
                            Add Selected
                        </Button>
                    </ButtonGroup>
                </Scrollbars>
            </Modal>
        </React.Fragment>
    )
}
export default MultipleProductSelectModal
