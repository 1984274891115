export const GetProductVariationId = (productVariations, selectedVariations) =>{
    let ids = productVariations.filter(a => a.permutationId !== null).map(p => p.permutationId)

    let perIds = ids.reduce(function (acc, curr) {
        if (!acc.includes(curr))
            acc.push(curr);
        return acc;
    }, []);
    //return unique;
    let selectedPermutationId = 0;

    let spvs = [];
    for(let pv = 0; pv < selectedVariations.length; pv++){

        let variationId = selectedVariations[pv].variationId;
        let variationValueId = selectedVariations[pv].variationValueId;

        let filteredPv = productVariations.filter(a => a.variationId === variationId && a.variationValueId === variationValueId) || [];
        let result = spvs.concat(filteredPv);
        spvs = result;
    }

    for(let i = 0; i < perIds.length; i++){
        let spv = spvs.filter(a => a.permutationId === perIds[i]);
        if(spv.length === selectedVariations.length) {
            selectedPermutationId = spv[0].permutationId;
        }
    }

    console.log({
        productId: productVariations[0]?.productId,
        variationId: selectedPermutationId
    })
    return selectedPermutationId;
}