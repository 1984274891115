import { CURRENCY } from "../../settings/constants";

export const GetSalesPaymentStatus = (inv) => {
    
    let status = "";
    if (!inv) {
        return status = "Invalid invoice"
    }
    
    let invItems = inv.invoiceItems || [];

    let soldItems = invItems.filter(a => a.status === "SOLD") 
    let returnedItems = invItems.filter(a => a.status === "RETURNED")

    let salesTotal = soldItems.reduce((a,c) => a+ (c.price * c.quantity), 0)
    let salesDisTotal = soldItems.reduce((a,c) => a+ (c.discount * c.quantity), 0)
    let salesVatTotal = soldItems.reduce((a,c) => a+ (c.vat * c.quantity), 0)

    let a = (salesTotal - salesDisTotal) + salesVatTotal

    let totalPaid = parseFloat(inv.totalPaid) ? parseFloat(inv.totalPaid) : 0;
    let discount = parseFloat(inv.discount) ? parseFloat(inv.discount) : 0;
    let invTotal = parseFloat(inv.total)
    let vat = parseFloat(inv.vat)



    let netPayble = ((invTotal + vat) - discount)
    let amount = netPayble - totalPaid;

    if (inv.status === "RETURNED") {
        if (netPayble < totalPaid) {
            status = `${CURRENCY}${Math.abs(Number(amount).toFixed(2))} (Returned)`;
        }
        else if (netPayble === totalPaid) {
            status = "Paid"
        }
        else {
            status = `${CURRENCY}${Number(amount).toFixed(2)} (Due)`;
        }
    }
    else {
        if (netPayble === totalPaid) {
            status = "Paid"
        }
        else {
            status = `${CURRENCY}${Number(amount).toFixed(2)} (Due) `;
        }
    }
    return status
}
