import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button } from 'baseui/button';
import DrawerBox from 'components/DrawerBox/DrawerBox';
import { Grid, Row, Col } from 'components/FlexBox/FlexBox';
import { Form } from '../../DrawerItems/DrawerItems.style';
import { FormFields, FormLabel } from 'components/FormFields/FormFields';
import useCustomSnackBar from "../../../hooks/useCustomSnackBar";
import { Select, SIZE } from "baseui/select";
import { Header, Heading, Wrapper } from 'components/Wrapper.style';
import { BASE_API_URL } from "../../../settings/apiUrls";
import CustomDataTable from 'components/DataTable/CustomDataTable';

type Props = any;

const PRODUCT_QUERY = gql`query($query: QueryParams!){
    productQuery{
        products(query: $query){
            items{
                id
                title
                code
                measureUnit
            }
        }
    }
}`;

const CAT_QUERY = gql`query($query: QueryParams!){
    categoryQuery{
      categories(query: $query){
        id
        name
      }
    }
  }`;

const BRANCH_QUERY = gql`
query{
    branchQuery{
      transferBranches{
        fromBranches{
          id
          name
        }
        toBranches{
          id
          name
        }
      }
    }
  }`

const INVENTORY_STOCK_REPORT = gql`
  query($query: QueryParams!){
    reportQuery{
      inventoryStockReport(query:$query){
        productCode
        productName
        categoryName
        unitPrice
        qty
        stocks{
          storeName
          qty
        }
      }
    }
  }
  `;

const ViewStockReport: React.FC<Props> = () => {
    const [productOptions, setProductOptions] = useState([]);
    const [product, setProduct] = useState([]);

    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [productId, setProductId] = useState("");
    const [store, setStore] = useState([]);
    const [storeId, setStoreId] = useState("");
    const [branchOptions, setBranchOptions] = useState([]);


    const [loadProducts, { data: products }] = useLazyQuery(PRODUCT_QUERY, {
        onCompleted: (data) => {
            if (data && data.productQuery && data.productQuery.products && data.productQuery.products.items) {
                const opts = data.productQuery.products && data.productQuery.products.items.map(a => ({
                    label: a.title + (a.code ? ` (${a.code})` : ""),
                    value: a.id
                }))
                if (opts.length > 0) setProductOptions(opts)
            }
        }
    });

    const { data: branches } = useQuery(BRANCH_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.branchQuery && data.branchQuery.transferBranches && data.branchQuery.transferBranches.toBranches) {
                const branches = data.branchQuery.transferBranches.toBranches.map((item) => ({ label: item.name, value: item.id }))
                setBranchOptions(branches)
            }
        },
        onError: (err) => {
            console.log(err)
        }
    });

    const { data, loading } = useQuery(CAT_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            query: {
                assending: true,
                orderKey: "name"
            }
        }
    });

    const { data: reports } = useQuery(INVENTORY_STOCK_REPORT, {
        fetchPolicy: 'network-only',
        variables: {
            "query": {
                "params": [
                    { "key": "prodId", "value": productId.toString() },
                    { "key": "isStore", "value": "true" },
                    { "key": "showTotal", "value": "true" }
                ]
            }
        }
    });
    const columns = React.useMemo(
        () => [
            {
                Header: 'Product Name',
                accessor: 'name'
            },
            {
                Header: 'Branch Name',
                accessor: 'branch'
            },
            {
                Header: 'Category',
                accessor: 'category'
            },
            {
                Header: "Total Quantity",
                accessor: 'qty'
            },
            {
                Header: 'Avg. unit Price',
                accessor: 'price'
            }
        ],
        []
    );

    const table = React.useMemo(
        () => {
            let data = []
            if (reports && reports.reportQuery && reports.reportQuery.inventoryStockReport) {
                const rpt = reports && reports.reportQuery && reports.reportQuery.inventoryStockReport
                data = rpt.map((r, i) => ({
                    name: r.productName,
                    branch: r.stocks[i]?.storeName,
                    category: r.categoryName,
                    qty: r.qty,
                    price: r.unitPrice.toMoney()
                }));
            }
            return <CustomDataTable columns={columns} data={data} loading={loading} canPrint={true} title={""} />
        },
        [reports]
    );


    const categorySelectOptions = useMemo(() => {
        return data && data.categoryQuery.categories ?
            data.categoryQuery.categories.map(a => ({ value: a.id, label: a.name })) :
            [];
    }, [loading]);


    function handleAutoComplete(e) {
        const val = e.target.value.toLowerCase();
        if (val.length < 2) return;
        loadProducts({
            variables: {
                query: {
                    "page": 1,
                    "pageSize": 5,
                    "assending": true,
                    "orderKey": "Title",
                    "filter": val,
                    params: [
                        {
                            key: "checkStock",
                            value: "true"
                        }
                    ]
                }
            }
        });
    }

    function handleProductChange(value) {
        if (value.length === 0) {
            setProduct([])
            setProductId("")
        } else {
            const product = value[0];
            setProduct(product)
            setProductId(product.value)
        }
    }

    const handleCategoryChange = (value) => {
        if (value.length === 0) {
            setCategory([])
            setCategoryId("")
        } else {
            const cat = value[0]
            setCategory(cat)
            setCategoryId(cat.value)
        }

    }

    const handleBranchChange = (value) => {
        if (value.length === 0) {
            setStore([])
            setStoreId("")
        } else {
            const st = value[0]
            setStore(st)
            setStoreId(st.value)
        }

    }

    const handleReset = (e) => {
        setCategory([])
        setProduct([])
        setProductId("");
        setCategoryId("");
        setStoreId("");
    }

    function handleShowInventoryReport(e) {
        const isStore = true;
        window.open(`${BASE_API_URL}report/inventory/pocketx?catId=${categoryId}&prodId=${productId}&isStore=${isStore}&storeId=${storeId}&showTotal=${true}`, "_blank");
    }

    return (
        <Scrollbars
            autoHide
            renderView={(props) => (
                <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
            )}
            renderTrackHorizontal={(props) => (
                <div
                    {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal"
                />
            )}
        >
            <DrawerBox>
                <Header style={{ marginBottom: 0, boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)', }}>
                    <Col>
                        <Heading>Inventory Report</Heading>
                    </Col>

                </Header>
            </DrawerBox>

            <Form style={{ marginTop: 20 }}>
                <DrawerBox>
                    <Row>
                        <Col md={3}>
                            <FormFields>
                                <FormLabel>Product Name/Code</FormLabel>
                                <Select
                                    options={productOptions || []}
                                    labelKey="label"
                                    valueKey="value"
                                    value={[product]}
                                    searchable={true}
                                    onChange={({ value }) => handleProductChange(value)}
                                    onInputChange={handleAutoComplete}
                                    size={SIZE.compact}
                                />
                            </FormFields>
                        </Col>

                        <Col md={3}>
                            <FormFields>
                                <FormLabel>Category</FormLabel>
                                <Select
                                    options={categorySelectOptions}
                                    labelKey="label"
                                    valueKey="value"
                                    value={[category]}
                                    searchable={true}
                                    onChange={({ value }) => handleCategoryChange(value)}
                                />
                            </FormFields>
                        </Col>
                        <Col md={2}>
                            <FormFields>
                                <FormLabel>Branch</FormLabel>
                                <Select
                                    options={branchOptions || []}
                                    labelKey="label"
                                    valueKey="value"
                                    value={[store]}
                                    searchable={true}
                                    onChange={({ value }) => handleBranchChange(value)}
                                />
                            </FormFields>
                        </Col>

                        <Col md={2}>
                            <div style={{ marginTop: 25 }}>
                                <Button
                                    type="button"
                                    onClick={handleShowInventoryReport}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }),
                                        },
                                    }}
                                >
                                    Generate Report
                                </Button>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div style={{ marginTop: 25, width: 70 }}>
                                <Button
                                    type="button"
                                    onClick={handleReset}
                                    overrides={{
                                        BaseButton: {
                                            style: ({ $theme }) => ({
                                                width: '100%',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }),
                                        },
                                    }}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {productId && <Row>
                        <Col md={12} style={{marginTop:5}}>
                            <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
                                {table}
                            </Wrapper>
                        </Col>
                    </Row>}
                </DrawerBox>
            </Form>
        </Scrollbars>
    );
};

export default ViewStockReport;

