import React from "react";
import {useTable, useFilters, usePagination, useSortBy} from "react-table";
import {Pagination} from "baseui/pagination";
import {
    TableWrapper,
    SmallCell,
    SmallHeadCell,
    StyledBody,
    StyledCell,
    StyledHead,
    StyledHeadCell
} from "./Datatable.style";
import {StyledTable, StyledRow} from "baseui/table";
import {ChevronDown, ChevronUp} from "baseui/icon"
import Input, {SIZE} from "../Input/Input";
import {StyledSpinnerNext as Spinner} from "baseui/spinner";

// function DefaultColumnFilter({
//                                  column: {filterValue, preFilteredRows, setFilter}
//                              }) {
//     const count = preFilteredRows.length;

//     return (
//         <Input
//             size={SIZE.mini}
//             clearable
//             value={filterValue || ""}
//             onChange={(e) => {
//                 setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//             }}
//             placeholder={`Search...`}
//             overrides={{
//                 Input: {
//                     style: ({$theme}) => {
//                         return {
//                             height: 25,
//                         };
//                     },
//                 },
//             }}
//         />
//     );
// }

// const defaultColumn = {
//     // Let's set up our default Filter UI
//     Filter: DefaultColumnFilter
// }

// const filterTypes = {
//     // Add a new fuzzyTextFilterFn filter type.
//     //fuzzyText: fuzzyTextFilterFn,
//     // Or, override the default text filter to use
//     // "startWith"
//     text: (rows, id, filterValue) => {
//         return rows.filter((row) => {
//             const rowValue = row.values[id];
//             return rowValue !== undefined
//                 ? String(rowValue)
//                     .toLowerCase()
//                     .startsWith(String(filterValue).toLowerCase())
//                 : true;
//         });
//     }
// };

export default function TransactionTable({columns, data, loading, accountID=0}) {
    return (
        <TableWrapper>
            <StyledTable>
                {// Loop over the header rows
                    // Apply the header row props
                    <StyledHead style={{background: "#eeeeee"}} $width="100%">
                        {// Loop over the headers in each row
                            columns.map((column, i) => (
                                // Apply the header cell props
                                <SmallHeadCell key={i}>
                                    {(column)}
                                </SmallHeadCell>
                            ))}
                    </StyledHead>
                }
                <StyledBody $width="100%">
                    {
                        loading &&
                        <div style={{width: "fit-content", margin: "auto"}}>
                            <Spinner/>
                        </div>
                    }
                    {
                        !loading && data.length === 0 &&
                        <h3 style={{textAlign: "center"}}>No Data Found</h3>
                    }
                    {
                        data.map(row => {
                            // Prepare the row for display
                            if (row.style){
                                return <StyledRow style={row.style}>
                                    {// Loop over the rows cells

                                    accountID !==4 && accountID !==6 && accountID!==10 && accountID!==13 && accountID!==20 && accountID !==5 && accountID !==77 &&
                                        row.data.map(cell => {
                                            // Apply the cell props
                                            if (cell.data) {
                                                return <SmallCell style={cell.style}>
                                                    {cell.data}
                                                </SmallCell>
                                            }
                                            return (
                                                <SmallCell>
                                                    {cell}
                                                </SmallCell>
                                            )
                                        })}
                                </StyledRow>
                            }
                            return (
                                
                                
                                // Apply the row props
                                <StyledRow>
                                    {// Loop over the rows cells
                                        row.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <SmallCell>
                                                    <span>{cell}</span>
                                                </SmallCell>
                                            )
                                        })}
                                </StyledRow>
                            )
                        })
                    }
                </StyledBody>
            </StyledTable>
        </TableWrapper>
    )

}
