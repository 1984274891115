import React, { useEffect } from 'react';
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { Pagination } from "baseui/pagination";
import {
    TableWrapper,
    SmallCell,
    SmallHeadCell,
    StyledBody,
    StyledHead
} from "./Datatable.style";
import { StyledTable, StyledRow } from "baseui/table";
import { ChevronDown, ChevronUp } from "baseui/icon"
import Input, { SIZE } from "../Input/Input";
import { StyledSpinnerNext as Spinner } from "baseui/spinner";


function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    const count = preFilteredRows.length;

    return (
        <Input
            size={SIZE.mini}
            clearable
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search...`}
            overrides={{
                Input: {
                    style: ({ $theme }) => {
                        return {
                            height: 25,
                        };
                    },
                },
            }}
        />
    );
}

function DateColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {

    return (
        <Input
            size={SIZE.mini}
            clearable
            type="date"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Date...`}
            overrides={{
                Input: {
                    style: ({ $theme }) => {
                        return {
                            height: 25,
                        };
                    },
                },
            }}
        />
    );
}

const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: DefaultColumnFilter,
    FilterDate: DateColumnFilter
}


export default function ManualDataTable({ columns, data, loading, fetchData, totalCount, pageSize, page: curPage }) {
    const tableInstance = useTable(
        {
            columns,
            data,
            defaultColumn,
            manualSortBy: true,
            manualPagination: true,
            manualFilters: true,
            autoResetFilters: false,
            autoResetPage: false,
            initialState: { pageIndex: 0 },
            pageCount: Math.ceil(totalCount / pageSize)
        },
        useFilters, useSortBy, usePagination)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        pageCount,
        gotoPage,
        state: { pageIndex, sortBy: [sortElem], filters }
    } = tableInstance;

    useEffect(() => {
        const a = tableInstance.state;
        const params = { pageSize, page: pageIndex + 1, sortKey: "id", ascending: true, filterKey: null, filterValue: null }
        if (sortElem) {
            params.sortKey = sortElem.id;
            params.ascending = !sortElem.desc
        }
        if (filters.length > 0) {
            const keys = [], values = [];
            filters.forEach(f => {
                keys.push(f.id);
                values.push(f.value)
            });
            params.filterKey = keys.join('\r')
            params.filterValue = values.join('\r')
        }
        fetchData(params.pageSize, params.page, params.sortKey, params.ascending, params.filterKey, params.filterValue)
    }, [pageIndex, sortElem, JSON.stringify(filters)])

    return (
        <TableWrapper>
            <StyledTable {...getTableProps()}>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <StyledHead $width="100%" {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map((column, i) => (
                                    // Apply the header cell props
                                    <SmallHeadCell key={i}>
                                        <div>
                                            <div
                                                style={{ marginBottom: 10 }}>{column.canFilter ? column.filter === "date" ? column.render("FilterDate") : column.render("Filter") :
                                                    <b>&nbsp;</b>}</div>
                                            <div {...column.getHeaderProps(column.getSortByToggleProps())}>{// Render the header
                                                column.render('Header')}
                                                <span>
                                                    {column.isSorted ? column.isSortedDesc ? <ChevronDown /> :
                                                        <ChevronUp /> : ''}
                                                </span></div>
                                        </div>
                                    </SmallHeadCell>
                                ))}
                        </StyledHead>

                    ))
                }

                <StyledBody $width="100%" {...getTableBodyProps()}>
                    {
                        loading &&
                        <div style={{ width: "fit-content", margin: "auto" }}>
                            <Spinner />
                        </div>
                    }
                    {
                        !loading && page.length === 0 &&
                        <h3 style={{ textAlign: "center" }}>No Data Found</h3>
                    }
                    {
                        page.map((row, i) => {
                            // Prepare the row for display
                            prepareRow(row)
                            return (
                                // Apply the row props
                                <StyledRow {...row.getRowProps()} style={{ backgroundColor: i % 2 === 0 ? "" : "#F6F1F1" }}>
                                    {// Loop over the rows cells
                                        row.cells.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <SmallCell {...cell.getCellProps()}>
                                                    {// Render the cell contents
                                                        cell.render('Cell')}
                                                </SmallCell>
                                            )
                                        })}
                                </StyledRow>
                            )
                        })
                    }
                </StyledBody>
            </StyledTable>
            <Pagination
                numPages={pageCount}
                currentPage={Math.min(pageIndex + 1)}
                onPageChange={({ nextPage }) => {
                    gotoPage(
                        Math.min(Math.max(nextPage, 1), pageCount) - 1
                    );
                }}
            />
        </TableWrapper>
    )

}
