import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "components/Button/Button";
import DrawerBox from "components/DrawerBox/DrawerBox";
import { Col, Grid, Row } from "components/FlexBox/FlexBox";
import { FormFields, FormLabel } from "components/FormFields/FormFields";
import { Heading } from "components/Wrapper.style";
import { Form } from "containers/DrawerItems/DrawerItems.style";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import CustomSelect from "components/Select/Select";

const initialPositionItemsState = {
    fixedAsset: null,
    assetsOne: null,
    assetsTwo: null,
    assetsThree: null,
    assetsFour: null,
    assetsFive: null
}

const positionOptions = [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' }
];

export default function AddAsset() {
    const [positionItems, setPositionItems] = useState(initialPositionItemsState);
    const enqueue = useCustomSnackBar();
    // enqueue("error", "Couldn't complete transaction. Please try again later");
    // enqueue("success", "Transaction Successful");

    const onSubmit = () => {
        console.log(positionItems);
    }

    return (
        <Grid fluid={true}>
            <Row>
                <Col md={6}>
                    <Heading style={{ paddingBottom: 20 }}>Add Asset</Heading>
                    <Form>
                        <DrawerBox>
                            <Row style={{ marginBottom: '30px' }}>
                                <Col md={12} style={{ marginBottom: '15px' }}>
                                    <FormFields>
                                        <FormLabel>Fixed Assets</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.fixedAsset}
                                            onChange={(e: any) => setPositionItems({ ...positionItems, fixedAsset: e.value })}
                                        />
                                    </FormFields>
                                </Col>

                                <Col md={12} style={{ marginBottom: '15px' }}>
                                    <FormFields>
                                        <FormLabel>Assets One</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.assetsOne}
                                            onChange={(e: any) => setPositionItems({ ...positionItems, assetsOne: e.value })}
                                        />
                                    </FormFields>
                                </Col>

                                <Col md={12} style={{ marginBottom: '15px' }}>
                                    <FormFields>
                                        <FormLabel>Assets Two</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.assetsTwo}
                                            onChange={(e: any) => setPositionItems({ ...positionItems, assetsTwo: e.value })}
                                        />
                                    </FormFields>
                                </Col>

                                <Col md={12} style={{ marginBottom: '15px' }}>
                                    <FormFields>
                                        <FormLabel>Assets Three</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.assetsThree}
                                            onChange={(e: any) => setPositionItems({ ...positionItems, assetsThree: e.value })}
                                        />
                                    </FormFields>
                                </Col>

                                <Col md={12} style={{ marginBottom: '15px' }}>
                                    <FormFields>
                                        <FormLabel>Assets Four</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.assetsFour}
                                            onChange={(e: any) => setPositionItems({ ...positionItems, assetsFour: e.value })}
                                        />
                                    </FormFields>
                                </Col>

                                <Col md={12} style={{ marginBottom: '15px' }}>
                                    <FormFields>
                                        <FormLabel>Assets Five</FormLabel>
                                        <CustomSelect
                                            options={positionOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            searchable={true}
                                            value={positionItems.assetsFive}
                                            onChange={(e: any) => setPositionItems({ ...positionItems, assetsFive: e.value })}
                                        />
                                    </FormFields>
                                </Col>
                            </Row>
                            <Button
                                type="button"
                                onClick={onSubmit}
                                // isLoading={loading}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            width: '10%',
                                            borderTopLeftRadius: '3px',
                                            borderTopRightRadius: '3px',
                                            borderBottomRightRadius: '3px',
                                            borderBottomLeftRadius: '3px',
                                        }),
                                    },
                                }}
                            >
                                Save
                            </Button>
                        </DrawerBox>
                    </Form>
                </Col>
            </Row>
        </Grid>
    )
}