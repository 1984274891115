import React from 'react';
import { Input as BaseInput, SIZE  } from 'baseui/input';

const getInputFontStyle = ({ $theme }) => {
  return {
    color: $theme.colors.textDark,
    ...$theme.typography.fontResponsive,
  };
};

const Input = ({ ...props }) => {
  return (
    <BaseInput
    
      overrides={{
        Input: {
          style: ({ $theme }) => {
            return {
              ...getInputFontStyle({ $theme }),
              padding: "4px"
            };
          },
          props: {
            defaultValue: props.defaultValue
          }
        },
      }}
      {...props}
    />
  );
};

export { SIZE };
export default Input;
